import React, { useEffect, useState } from "react"

//redux
import { useSelector, useDispatch } from "react-redux";

import { Container, Row, Col, Card, CardBody, CardTitle, CardSubtitle } from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

import { Link } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

// actions
import { getPayments } from "../../../store/actions";

import axios from "axios";

import { API_URL } from "helpers/api_helper";


const Payments = props => {

    //meta title
    document.title = "Payments | Algolingo ";

    const dispatch = useDispatch();

    const { apiSuccess, apiError } = useSelector(state => ({
        apiSuccess: state.AdminPayments.apiSuccess,
        apiError: state.AdminPayments.apiError
    }
    ));

    const [page, setpage] = useState(0);

    const [searchData, setsearchData] = useState("");

    const [paymentsData, setpaymentsData] = useState(null);

    useEffect(() => {
        setTimeout(() => { LoadData(searchData, page); }, 500);
    }, []);

    function SearchData() {
        //alert("searchData=" + searchData);
        LoadData(searchData, page);
    }

    function LoadData(search, no) {

        var obj = {
            "search": search,
            "page": no
        };

        //console.log("page=" + no);

        const headers = {
            'Content-Type': 'application/json'
        };

        axios.post(API_URL + '/admin/getPayments', JSON.stringify(obj), { headers }).then(response => {

            //console.log(response) 

            var output = response.data;

            if (output.result == "success") {
                setpaymentsData(output.data);
            }
            else {
                setpaymentsData(null);
            }
        });
    }

    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumb */}

                    <Breadcrumbs title="Dashboards" breadcrumbItem="Payments" />

                    <Row>
                        <Col>
                            <Card>
                                <CardBody>

                                    <Row className="mt-3 mb-3">
                                        <Col md={6} className="mb-2">
                                            <div className="d-flex flex-wrap gap-2">

                                                <div className="btn-group">
                                                    <Link to="#" onClick={() => {
                                                        if (page > 0) {
                                                            LoadData(searchData, page - 1);
                                                            setpage(page - 1);
                                                        }
                                                    }} className="btn btn-outline-primary">Prev</Link>
                                                    <Link to="#" className="btn btn-outline-primary">{page + 1}</Link>
                                                    <Link to="#" onClick={() => {
                                                        LoadData(searchData, page + 1);
                                                        setpage(page + 1);
                                                    }} className="btn btn-outline-primary">Next</Link>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col md={6} className="mb-2" style={isMobile == false ? { textAlign: 'right' } : {}}>
                                            <div style={{ display: "inline-flex" }}>
                                                <input  value={searchData} onChange={(val) => {
                                                    if (val != "") {
                                                        setsearchData(val.target.value)
                                                    }
                                                }}
                                                    onKeyPress={event => {
                                                        if (event.key === 'Enter') {
                                                            SearchData();
                                                        }
                                                    }}
                                                    className="form-control " type="text" placeholder="Search"></input>
                                            </div>
                                        </Col>
                                    </Row>

                                    <div className="table-rep-plugin">
                                        <div
                                            className="table-responsive mb-0"
                                            data-pattern="priority-columns"
                                        >
                                            <Table
                                                id="tech-companies-1"
                                                className="table table-striped table-bordered"
                                            >
                                                <Thead>
                                                    <Tr>
                                                        <Th data-priority="3">Email</Th>
                                                        <Th data-priority="4">Service</Th>
                                                        <Th data-priority="1">Amount</Th>
                                                        <Th data-priority="7">Payment ID</Th>
                                                        <Th data-priority="7">Payment Date</Th>
                                                    </Tr>
                                                </Thead>
                                                <Tbody>
                                                    {paymentsData &&

                                                        paymentsData.map((data, key) => (
                                                            <Tr key={key}>
                                                                <Td>{data.subadmin_email || data.email}</Td>
                                                                <Td>{data.service.toUpperCase()}</Td>
                                                                <Td>{data.amount + " INR"}</Td>
                                                                <Td>{data.paymentID}</Td>
                                                                <Td>{new Date(data.created_at).toLocaleString()}</Td>
                                                            </Tr>
                                                        ))}
                                                </Tbody>
                                            </Table>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </Container>
            </div>
        </React.Fragment>
    )
}

export default Payments
