import React, { useEffect, useState, useRef } from "react";

import { useMemo } from "react";

//redux
import { useSelector, useDispatch } from "react-redux";

import {
    Container, Row, Col, Card, CardBody, CardTitle, CardSubtitle, Tooltip,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    NavItem, NavLink
} from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

import classnames from "classnames";

import { Link } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

// actions
import { getAuthenticationData } from "../../../store/actions";

import axios from "axios";

import { API_URL } from "helpers/api_helper";

import { socket_io, AddToMarketDataSocketInstruments } from "../../../common/sockets"

import TableContainer from "../../../components/Common/TableContainer";


const Idno = (cell) => {
    return cell.value ? cell.value : '';
};

const Pdate = (cell) => {
    return cell.value ? cell.value : '';
};

const Type = (cell) => {
    return cell.value ? cell.value : '';
};

const Value = (cell) => {
    return cell.value ? cell.value : '';
};

const ValueInUsd = (cell) => {
    return cell.value ? cell.value : '';
};


const Wallet = props => {

    //meta title
    document.title = "Wallet | Algolingo ";

    const dispatch = useDispatch();

    const { authenticationData } = useSelector(state => ({
        authenticationData: state.Authentication.authenticationData,
    }
    ));

    const [apiSuccess, SetapiSuccess] = useState(null)

    const [apiError, SetapiError] = useState(null)

    useEffect(() => {
        dispatch(getAuthenticationData());
    }, []);

    const wallet = [
        {
            email:"nowsathnowsath93@gmail.com",
            current_day:"25-04-2023",
            strategyName:"TIME_RANGE_BREAKOUT",
            amount:"799",
            created_at:"25-04-2023 15:09",
        }
    ]


    // Table Data
    // const productData = [
    //     {
    //         id: 1,
    //         idno: "#SK3226",
    //         pdate: "03 Mar, 2020",
    //         type: "Buy",
    //         coin: "Litecoin",
    //         amount: "0.00413 LTC",
    //         valueInUsd: "$ 1773.01",
    //     },
    //     {
    //         id: 2,
    //         idno: "#SK3336",
    //         pdate: "03 Mar, 2020",
    //         type: "Sell",
    //         coin: "Ethereum",
    //         amount: "0.00413 ETH",
    //         valueInUsd: "$ 1773.01",
    //     },
    //     {
    //         id: 3,
    //         idno: "#SK3226",
    //         pdate: "13 Jun, 2020",
    //         type: "Sell",
    //         coin: "Litecoin",
    //         amount: "0.00413 LTC",
    //         valueInUsd: "$ 1773.01",
    //     },
    //     {
    //         id: 4,
    //         idno: "#SK3226",
    //         pdate: "03 Mar, 2020",
    //         type: "Buy",
    //         coin: "Ethereum",
    //         amount: "0.00413 ETH",
    //         valueInUsd: "$ 1773.01",
    //     },
    //     {
    //         id: 5,
    //         idno: "#SK3226",
    //         pdate: "23 Mar, 2020",
    //         type: "Buy",
    //         coin: "Litecoin",
    //         amount: "0.00413 ETH",
    //         valueInUsd: "$ 1773.01",
    //     },
    //     {
    //         id: 6,
    //         idno: "#SK3116",
    //         pdate: "03 Mar, 2020",
    //         type: "Sell",
    //         coin: "Ethereum",
    //         amount: "0.00413 LTC",
    //         valueInUsd: "$ 1773.01",
    //     },
    //     {
    //         id: 7,
    //         idno: "#SK3336",
    //         pdate: "13 Mar, 2020",
    //         type: "Buy",
    //         coin: "Ethereum",
    //         amount: "0.00413 ETH",
    //         valueInUsd: "$ 1773.01",
    //     },
    //     {
    //         id: 8,
    //         idno: "#SK3226",
    //         pdate: "03 Mar, 2020",
    //         type: "Buy",
    //         coin: "Litecoin",
    //         amount: "0.00413 ETH",
    //         valueInUsd: "$ 1773.01",
    //     },
    //     {
    //         id: 9,
    //         idno: "#SK3226",
    //         pdate: "23 Mar, 2020",
    //         type: "Buy",
    //         coin: "Litecoin",
    //         amount: "0.00413 ETH",
    //         valueInUsd: "$ 1773.01",
    //     },
    //     {
    //         id: 10,
    //         idno: "#SK3226",
    //         pdate: "03 Mar, 2020",
    //         type: "Sell",
    //         coin: "Litecoin",
    //         amount: "0.00413 ETH",
    //         valueInUsd: "$ 1773.01",
    //     },
    //     {
    //         id: 11,
    //         idno: "#SK3226",
    //         pdate: "08 Mar, 2020",
    //         type: "Sell",
    //         coin: "Litecoin",
    //         amount: "0.00413 ETH",
    //         valueInUsd: "$ 1773.01",
    //     },
    //     {
    //         id: 12,
    //         idno: "#SK3226",
    //         pdate: "03 Mar, 2020",
    //         type: "Buy",
    //         coin: "Litecoin",
    //         amount: "0.00413 ETH",
    //         valueInUsd: "$ 1773.01",
    //     },
    //     {
    //         id: 13,
    //         idno: "#SK3336",
    //         pdate: "03 Mar, 2020",
    //         type: "Buy",
    //         coin: "Ethereum",
    //         amount: "0.00413 LTC",
    //         valueInUsd: "$ 1773.01",
    //     },
    //     {
    //         id: 14,
    //         idno: "#SK3336",
    //         pdate: "07 Mar, 2020",
    //         type: "Sell",
    //         coin: "Litecoin",
    //         amount: "0.00413 ETH",
    //         valueInUsd: "$ 1773.01",
    //     },
    //     {
    //         id: 15,
    //         idno: "#SK3226",
    //         pdate: "13 Mar, 2020",
    //         type: "Buy",
    //         coin: "Litecoin",
    //         amount: "0.00413 ETH",
    //         valueInUsd: "$ 1773.01",
    //     },
    //     {
    //         id: 16,
    //         idno: "#SK3226",
    //         pdate: "03 Mar, 2020",
    //         type: "Buy",
    //         coin: "Ethereum",
    //         amount: "0.00413 ETH",
    //         valueInUsd: "$ 1773.01",
    //     },
    //     {
    //         id: 17,
    //         idno: "#SK3336",
    //         pdate: "03 Mar, 2020",
    //         type: "Buy",
    //         coin: "Litecoin",
    //         amount: "0.00413 LTC",
    //         valueInUsd: "$ 1773.01",
    //     },
    // ];

    const columns = useMemo(
        () => [
            {
                Header: "Email",
                accessor: "email",
                filterable: false,
                Cell: cellProps => {
                    return <Idno {...cellProps} />;
                },
            },
            {
                Header: "Date",
                accessor: "current_day",
                filterable: false,
                Cell: cellProps => {
                    return <Pdate {...cellProps} />;
                },
            },
            {
                Header: "Strategy Name",
                accessor: "strategyName",
                filterable: false,
                Cell: cellProps => {
                    return <Type {...cellProps} />;
                },
            },
            {
                Header: "Amount",
                accessor: "amount",
                filterable: false,
                Cell: cellProps => {
                    return <Value {...cellProps} />;
                },
            },
            {
                Header: "Created At",
                accessor: "created_at",
                filterable: false,
                Cell: cellProps => {
                    return <ValueInUsd {...cellProps} />;
                },
            },
        ],
        []
    );

    const [walletData, SetwalletData] = useState(null);

    const [isMenu, setIsMenu] = useState(false);
    const [activeTab, setActiveTab] = useState("1");

    const [totalSpent, settotalSpent] = useState(0);

    const [totaldeposit, settotaldeposit] = useState(0);

    useEffect(() => {
        if (apiSuccess != null) {
            setTimeout(() => SetapiSuccess(null), 1000)
        }
    }, [apiSuccess]);

    useEffect(() => {
        if (apiError != null) {
            setTimeout(() => SetapiError(null), 1000)
        }
    }, [apiError]);

    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    useEffect(() => {
        LoadWalletTopupData();
        LoadWalletUsageData();
    }, []);

    function LoadWalletUsageData() {

        var authUser = localStorage.getItem("authUser");
        if (authUser) { authUser = atob(authUser); }
        var email = JSON.parse(authUser).email;

        var obj = {
            "email": email
        };

        const headers = {
            'Content-Type': 'application/json'
        };

        axios.post(API_URL + '/user/getWalletTransactions ', JSON.stringify(obj), { headers }).then(response => {

            //console.log(response) 

            var output = response.data;

            if (output.result == "success") {
                SetwalletData(output.data);
                if (output.data.length > 0) {
                    var temp = output.data.map(item => item.amount).reduce((prev, next) => prev + next);
                    settotalSpent(temp);
                }
            }
            else {
                SetwalletData(null);
            }
        });
    }

    function LoadWalletTopupData() {

        var authUser = localStorage.getItem("authUser");
        if (authUser) { authUser = atob(authUser); }
        var email = JSON.parse(authUser).email;

        var obj = {
            "email": email
        };

        const headers = {
            'Content-Type': 'application/json'
        };

        axios.post(API_URL + '/user/getUserWalletPayments', JSON.stringify(obj), { headers }).then(response => {

            //console.log(response) 

            var output = response.data;

            if (output.result == "success") {
                if (output.data.length > 0) {
                    var temp = output.data.map(item => item.amount).reduce((prev, next) => prev + next);
                    settotaldeposit(temp / 100);
                }
            }
        });
    }

    const toggleTab = tab => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    const toggleMenu = () => {
        setIsMenu(!isMenu);
    };

    function CreatePayment() {

       
    }

    function redirectPost(url, payload) {
        var form = document.createElement('form');
        document.body.appendChild(form);
        form.method = 'post';
        form.action = url;

        var input = document.createElement('input');
        input.type = 'hidden';
        input.name = "request";
        input.value = payload;
        form.appendChild(input);

        // for (var name in data) {
        //     var input = document.createElement('input');
        //     input.type = 'hidden';
        //     input.name = name;
        //     input.value = data[name];
        //     form.appendChild(input);
        // }
        form.submit();
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>

                    <Breadcrumbs title="Dashboards" breadcrumbItem="Wallet" />

                    <Row>
                        <Col lg="12">
                            <Card>

                                <CardBody className="border-top">
                                    <div className="text-center">
                                        <Row>
                                            <Col sm="4">
                                                <div>
                                                    <div className="font-size-24 text-primary mb-2">
                                                        <i className="bx bx-sort-up" />
                                                    </div>

                                                    <p className="text-muted mb-2">Spent</p>
                                                    <h5><i className="bx bx-rupee"></i>{totalSpent}</h5>

                                                    <div className="mt-3">

                                                    </div>
                                                </div>
                                            </Col>
                                            <Col sm="4">
                                                <div className="mt-4 mt-sm-0">
                                                    <div className="font-size-24 text-primary mb-2">
                                                        <i className="bx bx-sort-down" />
                                                    </div>

                                                    <p className="text-muted mb-2">Deposited</p>
                                                    <h5><i className="bx bx-rupee"></i>{totaldeposit}</h5>

                                                    <div className="mt-3">

                                                    </div>
                                                </div>
                                            </Col>
                                            <Col sm="4">
                                                <div className="mt-4 mt-sm-0">
                                                    <div className="font-size-24 text-primary mb-2">
                                                        <i className="bx bx-wallet" />
                                                    </div>

                                                    <p className="text-muted mb-2">Wallet Balance</p>
                                                    <h5><i className="bx bx-rupee"></i>{authenticationData ? (authenticationData.walletBalance) : (0)}</h5>

                                                    <div className="mt-3">
                                                        <Link to="#" onClick={() => { CreatePayment() }} className="btn btn-primary btn-sm w-md">
                                                            Add Money
                                                        </Link>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody>
                                    <h4 className="card-title mb-4">Activities</h4>

                                    <div className="mt-4">
                                        {walletData && walletData.length > 0 && (
                                            <TableContainer
                                                columns={columns}
                                                data={walletData}
                                                customPageSize={10}
                                            />)}
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </Container>
            </div>
        </React.Fragment>
    )
}

export default Wallet
