import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Col } from "reactstrap";

const CarouselPage = () => {
  return (
    <React.Fragment>
      <Col xl={9}>
        <div className="auth-full-bg pt-lg-5 p-4">
          <div className="w-100">
            <div className="bg-overlay"></div>
            <div className="d-flex h-100 flex-column">
              <div className="p-4 mt-auto">
                <div className="row justify-content-center">
                  <div className="col-lg-7">
                    <div className="text-center">
                      <h4 className="mb-3">
                        <i className="bx bxs-quote-alt-left text-primary h1 align-middle me-3"></i>
                        <span className="text-primary">5k</span>+ Satisfied
                        clients
                      </h4>
                      <div dir="ltr">
                        <Carousel showThumbs={false} className="slider_css">
                          <div>
                            <div className="item">
                              <h4 className="mb-3">
                                <i className="bx bxs-quote-alt-left text-primary h1 align-middle me-3"></i>
                                What is Algolingo?
                              </h4>
                              <div className="py-3">
                                <p className="font-size-16 mb-4">
                                  "Algolingo is an Algo trading platform for the Indian Stock Market. It comes with multiple account handling feature. Its the best Algo cum trade copier tool for Indian Stock Market“
                                </p>

                                <div>
                                  <h4 className="font-size-16 text-primary">
                                    
                                  </h4>
                                  <p className="font-size-14 mb-0">
                                    - Founder, CEO
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div className="item">
                              <h4 className="mb-3">
                                <i className="bx bxs-quote-alt-left text-primary h1 align-middle me-3"></i>
                                How Algolingo Works?
                              </h4>
                              <div className="py-3">
                                <p className="font-size-16 mb-4">
                                  "It's Very Simple! Just register and Login to the platform, Authenticate with broker's Login details. Now you are ready to trade using Algolingo. If you want to send orders to multiple accounts, you need to buy Master subscription“
                                </p>

                                <div>
                                  <h4 className="font-size-16 text-primary">
                                    
                                  </h4>
                                  <p className="font-size-14 mb-0">
                                    - Technical Head, Algolingo
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Carousel>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Col>
    </React.Fragment>
  )
}
export default CarouselPage
