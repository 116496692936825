import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

import {
  GET_USERS,
  USERS_EVENTS,
  USERS_ROLE_CHANGE,
} from "./actionTypes";
import {
  getUsersSuccessful,
  getUsersFailed,
  usersEventsSuccessful,
  usersEventsFailed,
  userRoleChangeSuccessful,
  userRoleChangeFailed,
} from "./actions";

import {
  getUsersCall, changeUserRoleCall, usersEventsCall
} from "../../../helpers/backend_helper";

function* getUsers({ payload: { user, history } }) {
  try {
    var input = user;

    //console.log("input=" + JSON.stringify(input));

    const output = yield call(getUsersCall, input);

    //console.log("output=" + JSON.stringify(output));

    if (output.result == "success") {
      yield put(getUsersSuccessful(output.data))
    }
    else {
      yield put(getUsersFailed(output.message))
    }
  } catch (error) {
    console.log(error.message);
    yield put(getUsersFailed(error.message));
  }
}

function* changeUserRole({ payload: { user, history } }) {
  try {
    var input = user;

    //console.log("input=" + JSON.stringify(input));

    if (input.service) {
      input.days = "30";
    }

    var authAdmin = localStorage.getItem("authAdmin");
    if (authAdmin) {
      authAdmin = atob(authAdmin);
    }
    const obj = JSON.parse(authAdmin);

    input.subadmin = obj.email;

    const output = yield call(changeUserRoleCall, input);

    //console.log("output=" + JSON.stringify(output));

    if (output.result == "success") {
      yield put(userRoleChangeSuccessful(output.message))
    }
    else {
      yield put(userRoleChangeFailed(output.message))
    }
  } catch (error) {
    console.log(error.message);
    yield put(userRoleChangeFailed(error.message));
  }
}

function* usersEvents({ payload: { user, history } }) {
  try {
    var input = user;

    //console.log("input=" + JSON.stringify(input));

    const output = yield call(usersEventsCall, input);

    //console.log("output=" + JSON.stringify(output));

    if (output.result == "success") {
      yield put(usersEventsSuccessful(output.message))
    }
    else {
      yield put(usersEventsFailed(output.message))
    }
  } catch (error) {
    console.log(error.message);
    yield put(usersEventsFailed(error.message));
  }
}


function* adminUsersSaga() {
  yield takeEvery(GET_USERS, getUsers);
  yield takeEvery(USERS_EVENTS, usersEvents);
  yield takeEvery(USERS_ROLE_CHANGE, changeUserRole);
}

export default adminUsersSaga;
