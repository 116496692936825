import React from "react";

import {
    Col,
    Row,
    Card,
    CardBody,
    CardTitle,
    CardSubtitle,
    CardImg,
    CardText,
    CardHeader,
    CardImgOverlay,
    CardFooter,
    CardDeck,
    CardColumns,
    Container,
} from "reactstrap";

// import images
import img1 from "../../../assets/images/small/1.png";
import img2 from "../../../assets/images/small/2.png";
import img3 from "../../../assets/images/small/3.png";
import img4 from "../../../assets/images/small/4.png";
import img5 from "../../../assets/images/small/5.png";
import img6 from "../../../assets/images/small/6.png";
import img7 from "../../../assets/images/small/7.png";
import img8 from "../../../assets/images/small/8.png";
import img9 from "../../../assets/images/small/9.png";
import img10 from "../../../assets/images/small/10.png";

import { Link } from "react-router-dom";

import { API_URL } from "helpers/api_helper";

const Freebies = () => {

    //meta title
    document.title = "Freebies | Algolingo";

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>

                    <Row>
                        <Col lg={6}>
                            <Card>
                                <Row className="no-gutters align-items-center">
                                    <Col md={4} style={{ textAlign: "center" }}>
                                        <CardImg className="img-fluid" style={{ width: "80%" }} src={img1} alt="Algolingo Freebies" />
                                    </Col>
                                    <Col md={8}>
                                        <CardBody>
                                            <CardTitle>SUPER TREND</CardTitle>
                                            <CardText>
                                                Download the free MT4 Indicator by Clicking the Button below.
                                                You can change the parameters according to your need.
                                                It's very simple to use.
                                                Just copy the file and Paste to the MT4's Indicator folder.
                                            </CardText>
                                            <CardText>
                                                <Link
                                                    to={{ pathname: API_URL + '/freebies/SUPERTREND-NORMAL.ex4' }}
                                                    target="_blank"
                                                    className="btn btn-primary w-sm"
                                                >
                                                    <i className="mdi mdi-download d-block font-size-16"></i>{" "}
                                                    Download
                                                </Link>
                                            </CardText>
                                        </CardBody>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col lg={6}>
                            <Card>
                                <Row className="no-gutters align-items-center">
                                    <Col md={8}>
                                        <CardBody>
                                            <CardTitle>HALF TREND</CardTitle>
                                            <CardText>
                                                Download the free MT4 Indicator by Clicking the Button below.
                                                You can change the parameters according to your need.
                                                It's very simple to use.
                                                Just copy the file and Paste to the MT4's Indicator folder.
                                            </CardText>
                                            <CardText>
                                                <Link
                                                    to={{ pathname: API_URL + '/freebies/HALF-TREND.ex4' }}
                                                    target="_blank"
                                                    className="btn btn-primary w-sm"
                                                >
                                                    <i className="mdi mdi-download d-block font-size-16"></i>{" "}
                                                    Download
                                                </Link>
                                            </CardText>
                                        </CardBody>
                                    </Col>
                                    <Col md={4} style={{ textAlign: "center" }}>
                                        <CardImg className="img-fluid" style={{ width: "80%" }} src={img2} alt="Algolingo Freebies" />
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col lg={6}>
                            <Card>
                                <Row className="no-gutters align-items-center">
                                    <Col md={4} style={{ textAlign: "center" }}>
                                        <CardImg className="img-fluid" style={{ width: "80%" }} src={img3} alt="Algolingo Freebies" />
                                    </Col>
                                    <Col md={8}>
                                        <CardBody>
                                            <CardTitle>MULTI INDICATOR</CardTitle>
                                            <CardText>
                                                Download the free MT4 Indicator by Clicking the Button below.
                                                You can change the parameters according to your need.
                                                It's very simple to use.
                                                Just copy the file and Paste to the MT4's Indicator folder.
                                            </CardText>
                                            <CardText>
                                                <Link
                                                    to={{ pathname: API_URL + '/freebies/MULTI-INDICATOR.ex4' }}
                                                    target="_blank"
                                                    className="btn btn-primary w-sm"
                                                >
                                                    <i className="mdi mdi-download d-block font-size-16"></i>{" "}
                                                    Download
                                                </Link>
                                            </CardText>
                                        </CardBody>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col lg={6}>
                            <Card>
                                <Row className="no-gutters align-items-center">
                                    <Col md={8}>
                                        <CardBody>
                                            <CardTitle>HEIKENASHI ST</CardTitle>
                                            <CardText>
                                                Download the free MT4 Indicator by Clicking the Button below.
                                                You can change the parameters according to your need.
                                                It's very simple to use.
                                                Just copy the file and Paste to the MT4's Indicator folder.
                                            </CardText>
                                            <CardText>
                                                <Link
                                                    to={{ pathname: API_URL + '/freebies/SUPERTREND-HEIKENASHI.ex4' }}
                                                    target="_blank"
                                                    className="btn btn-primary w-sm"
                                                >
                                                    <i className="mdi mdi-download d-block font-size-16"></i>{" "}
                                                    Download
                                                </Link>
                                            </CardText>
                                        </CardBody>
                                    </Col>
                                    <Col md={4} style={{ textAlign: "center" }}>
                                        <CardImg className="img-fluid" style={{ width: "80%" }} src={img4} alt="Algolingo Freebies" />
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col lg={6}>
                            <Card>
                                <Row className="no-gutters align-items-center">
                                    <Col md={4} style={{ textAlign: "center" }}>
                                        <CardImg className="img-fluid" style={{ width: "80%" }} src={img5} alt="Algolingo Freebies" />
                                    </Col>
                                    <Col md={8}>
                                        <CardBody>
                                            <CardTitle>SEMI ALGO</CardTitle>
                                            <CardText>
                                                Download the free MT4 Indicator by Clicking the Button below.
                                                You can change the parameters according to your need.
                                                It's very simple to use.
                                                Just copy the file and Paste to the MT4's Indicator folder.
                                            </CardText>
                                            <CardText>
                                                <Link
                                                    to={{ pathname: API_URL + '/freebies/SEMI-ALGO-TRADING.ex4' }}
                                                    target="_blank"
                                                    className="btn btn-primary w-sm"
                                                >
                                                    <i className="mdi mdi-download d-block font-size-16"></i>{" "}
                                                    Download
                                                </Link>
                                            </CardText>
                                        </CardBody>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col lg={6}>
                            <Card>
                                <Row className="no-gutters align-items-center">
                                    <Col md={8}>
                                        <CardBody>
                                            <CardTitle>RANGE BREAKOUT</CardTitle>
                                            <CardText>
                                                Download the free MT4 Indicator by Clicking the Button below.
                                                You can change the parameters according to your need.
                                                It's very simple to use.
                                                Just copy the file and Paste to the MT4's Indicator folder.
                                            </CardText>
                                            <CardText>
                                                <Link
                                                    to={{ pathname: API_URL + '/freebies/TIME-RANGE-BREAKOUT.ex4' }}
                                                    target="_blank"
                                                    className="btn btn-primary w-sm"
                                                >
                                                    <i className="mdi mdi-download d-block font-size-16"></i>{" "}
                                                    Download
                                                </Link>
                                            </CardText>
                                        </CardBody>
                                    </Col>
                                    <Col md={4} style={{ textAlign: "center" }}>
                                        <CardImg className="img-fluid" style={{ width: "80%" }} src={img6} alt="Algolingo Freebies" />
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>

                </Container>
            </div>
        </React.Fragment>
    );
};
export default Freebies;
