import React, { useEffect, useState, useRef } from "react";

import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    CardSubtitle,
    CardText,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Modal,
    InputGroup,
    Button
} from "reactstrap";

import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

import classnames from "classnames";

import axios from "axios";

import { API_URL } from "helpers/api_helper";

import { socket_io, AddToMarketDataSocketInstruments, AddToOptionChainDataSocketInstruments } from "../../../common/sockets"

const OptionChain = () => {
    //meta title
    document.title = "Option Chain | Algolingo";

    const table_style = {
        border: "none",
        textAlign: "center"
    }

    const call_heading_style = {
        backgroundColor: "Lime",
        color: "White",
        border: "none",
        textAlign: "center",
        padding: "10px"
    }

    const put_heading_style = {
        backgroundColor: "Red",
        color: "White",
        border: "none",
        textAlign: "center",
        padding: "10px"
    }

    var nse_symbol_data = [
        {
            "trading_symbol": "NIFTY",
            "code": 26000,
            "lot_size": 50,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "BANKNIFTY",
            "code": 26009,
            "lot_size": 25,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "AARTIIND",
            "code": 7,
            "lot_size": 850,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "ABB",
            "code": 13,
            "lot_size": 250,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "ABBOTINDIA",
            "code": 17903,
            "lot_size": 40,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "ABCAPITAL",
            "code": 21614,
            "lot_size": 5400,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "ABFRL",
            "code": 30108,
            "lot_size": 2600,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "ACC",
            "code": 22,
            "lot_size": 250,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "ADANIENT",
            "code": 25,
            "lot_size": 250,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "ADANIPORTS",
            "code": 15083,
            "lot_size": 625,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "ALKEM",
            "code": 11703,
            "lot_size": 200,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "AMARAJABAT",
            "code": 100,
            "lot_size": 1000,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "AMBUJACEM",
            "code": 1270,
            "lot_size": 1800,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "APOLLOHOSP",
            "code": 157,
            "lot_size": 125,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "APOLLOTYRE",
            "code": 163,
            "lot_size": 3500,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "ASHOKLEY",
            "code": 212,
            "lot_size": 5000,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "ASIANPAINT",
            "code": 236,
            "lot_size": 200,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "ASTRAL",
            "code": 14418,
            "lot_size": 275,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "ATUL",
            "code": 263,
            "lot_size": 75,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "AUBANK",
            "code": 21238,
            "lot_size": 1000,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "AUROPHARMA",
            "code": 275,
            "lot_size": 1000,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "AXISBANK",
            "code": 5900,
            "lot_size": 1200,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "BAJAJ-AUTO",
            "code": 16669,
            "lot_size": 250,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "BAJAJFINSV",
            "code": 16675,
            "lot_size": 500,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "BAJFINANCE",
            "code": 317,
            "lot_size": 125,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "BALKRISIND",
            "code": 335,
            "lot_size": 300,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "BALRAMCHIN",
            "code": 341,
            "lot_size": 1600,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "BANDHANBNK",
            "code": 2263,
            "lot_size": 1800,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "BANKBARODA",
            "code": 4668,
            "lot_size": 5850,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "BATAINDIA",
            "code": 371,
            "lot_size": 275,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "BEL",
            "code": 383,
            "lot_size": 5700,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "BERGEPAINT",
            "code": 404,
            "lot_size": 1100,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "BHARATFORG",
            "code": 422,
            "lot_size": 1000,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "BHARTIARTL",
            "code": 10604,
            "lot_size": 950,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "BHEL",
            "code": 438,
            "lot_size": 10500,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "BIOCON",
            "code": 11373,
            "lot_size": 2300,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "BOSCHLTD",
            "code": 2181,
            "lot_size": 50,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "BPCL",
            "code": 526,
            "lot_size": 1800,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "BRITANNIA",
            "code": 547,
            "lot_size": 200,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "BSOFT",
            "code": 6994,
            "lot_size": 2000,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "CANBK",
            "code": 10794,
            "lot_size": 2700,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "CANFINHOME",
            "code": 583,
            "lot_size": 975,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "CHAMBLFERT",
            "code": 637,
            "lot_size": 1500,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "CHOLAFIN",
            "code": 685,
            "lot_size": 1250,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "CIPLA",
            "code": 694,
            "lot_size": 650,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "COALINDIA",
            "code": 20374,
            "lot_size": 4200,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "COFORGE",
            "code": 11543,
            "lot_size": 150,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "COLPAL",
            "code": 15141,
            "lot_size": 350,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "CONCOR",
            "code": 4749,
            "lot_size": 1000,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "COROMANDEL",
            "code": 739,
            "lot_size": 700,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "CROMPTON",
            "code": 17094,
            "lot_size": 1500,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "CUB",
            "code": 5701,
            "lot_size": 5000,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "CUMMINSIND",
            "code": 1901,
            "lot_size": 600,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "DABUR",
            "code": 772,
            "lot_size": 1250,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "DALBHARAT",
            "code": 8075,
            "lot_size": 500,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "DEEPAKNTR",
            "code": 15044,
            "lot_size": 250,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "DELTACORP",
            "code": 15044,
            "lot_size": 2300,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "DIVISLAB",
            "code": 10940,
            "lot_size": 150,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "DIXON",
            "code": 21690,
            "lot_size": 125,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "DLF",
            "code": 14732,
            "lot_size": 1650,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "DRREDDY",
            "code": 881,
            "lot_size": 125,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "EICHERMOT",
            "code": 910,
            "lot_size": 175,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "ESCORTS",
            "code": 958,
            "lot_size": 275,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "EXIDEIND",
            "code": 676,
            "lot_size": 3600,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "FEDERALBNK",
            "code": 1023,
            "lot_size": 5000,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "FSL",
            "code": 14304,
            "lot_size": 5200,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "GAIL",
            "code": 4717,
            "lot_size": 9150,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "GLENMARK",
            "code": 7406,
            "lot_size": 1450,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "GMRINFRA",
            "code": 13528,
            "lot_size": 22500,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "GNFC",
            "code": 1174,
            "lot_size": 1300,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "GODREJCP",
            "code": 10099,
            "lot_size": 1000,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "GODREJPROP",
            "code": 17875,
            "lot_size": 425,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "GRANULES",
            "code": 11872,
            "lot_size": 2000,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "GRASIM",
            "code": 1232,
            "lot_size": 475,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "GUJGASLTD",
            "code": 10599,
            "lot_size": 1250,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "HAL",
            "code": 2303,
            "lot_size": 300,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "HAVELLS",
            "code": 9819,
            "lot_size": 500,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "HCLTECH",
            "code": 7229,
            "lot_size": 700,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "HDFC",
            "code": 1330,
            "lot_size": 300,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "HDFCAMC",
            "code": 4244,
            "lot_size": 300,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "HDFCBANK",
            "code": 1333,
            "lot_size": 550,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "HDFCLIFE",
            "code": 467,
            "lot_size": 1100,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "HEROMOTOCO",
            "code": 1348,
            "lot_size": 300,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "HINDALCO",
            "code": 1363,
            "lot_size": 1400,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "HINDCOPPER",
            "code": 17939,
            "lot_size": 4300,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "HINDPETRO",
            "code": 1406,
            "lot_size": 2700,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "HINDUNILVR",
            "code": 1394,
            "lot_size": 300,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "HONAUT",
            "code": 3417,
            "lot_size": 15,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "IBULHSGFIN",
            "code": 30125,
            "lot_size": 4000,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "ICICIBANK",
            "code": 4963,
            "lot_size": 700,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "ICICIGI",
            "code": 21770,
            "lot_size": 425,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "ICICIPRULI",
            "code": 18652,
            "lot_size": 1500,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "IDEA",
            "code": 14366,
            "lot_size": 70000,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "IDFC",
            "code": 11957,
            "lot_size": 10000,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "IDFCFIRSTB",
            "code": 11184,
            "lot_size": 15000,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "IEX",
            "code": 220,
            "lot_size": 3750,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "IGL",
            "code": 11262,
            "lot_size": 1375,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "INDHOTEL",
            "code": 1512,
            "lot_size": 2000,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "INDIACEM",
            "code": 1515,
            "lot_size": 2900,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "INDIAMART",
            "code": 10726,
            "lot_size": 150,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "INDIGO",
            "code": 11195,
            "lot_size": 300,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "INDUSINDBK",
            "code": 5258,
            "lot_size": 450,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "INDUSTOWER",
            "code": 29135,
            "lot_size": 2800,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "INFY",
            "code": 1594,
            "lot_size": 400,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "INTELLECT",
            "code": 5926,
            "lot_size": 750,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "IOC",
            "code": 1624,
            "lot_size": 9750,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "IPCALAB",
            "code": 1633,
            "lot_size": 650,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "IRCTC",
            "code": 13611,
            "lot_size": 875,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "ITC",
            "code": 1660,
            "lot_size": 1600,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "JINDALSTEL",
            "code": 6733,
            "lot_size": 1250,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "JKCEMENT",
            "code": 11723,
            "lot_size": 250,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "JSWSTEEL",
            "code": 11723,
            "lot_size": 1350,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "JUBLFOOD",
            "code": 18096,
            "lot_size": 1250,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "KOTAKBANK",
            "code": 1922,
            "lot_size": 400,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "LALPATHLAB",
            "code": 11654,
            "lot_size": 250,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "LAURUSLABS",
            "code": 19234,
            "lot_size": 1100,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "LICHSGFIN",
            "code": 1997,
            "lot_size": 2000,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "LT",
            "code": 11483,
            "lot_size": 300,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "L&TFH",
            "code": 24948,
            "lot_size": 8924,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "LTIM",
            "code": 17818,
            "lot_size": 150,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "LTTS",
            "code": 18564,
            "lot_size": 200,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "LUPIN",
            "code": 10440,
            "lot_size": 850,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "MANAPPURAM",
            "code": 19061,
            "lot_size": 6000,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "MARICO",
            "code": 4067,
            "lot_size": 1200,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "MARUTI",
            "code": 10999,
            "lot_size": 100,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "MCDOWELL-N",
            "code": 10447,
            "lot_size": 625,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "MCX",
            "code": 31181,
            "lot_size": 400,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "METROPOLIS",
            "code": 9581,
            "lot_size": 400,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "MFSL",
            "code": 2142,
            "lot_size": 650,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "MGL",
            "code": 17534,
            "lot_size": 800,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "M&M",
            "code": 2031,
            "lot_size": 700,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "M&MFIN",
            "code": 13285,
            "lot_size": 4000,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "MOTHERSON",
            "code": 4204,
            "lot_size": 6750,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "MPHASIS",
            "code": 4503,
            "lot_size": 175,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "MRF",
            "code": 2277,
            "lot_size": 10,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "MUTHOOTFIN",
            "code": 23650,
            "lot_size": 375,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "NATIONALUM",
            "code": 6364,
            "lot_size": 7500,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "NAUKRI",
            "code": 13751,
            "lot_size": 125,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "NAVINFLUOR",
            "code": 14672,
            "lot_size": 150,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "NESTLEIND",
            "code": 17963,
            "lot_size": 40,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "NMDC",
            "code": 15332,
            "lot_size": 4500,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "NTPC",
            "code": 11630,
            "lot_size": 5700,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "OBEROIRLTY",
            "code": 20242,
            "lot_size": 700,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "OFSS",
            "code": 10738,
            "lot_size": 200,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "ONGC",
            "code": 2475,
            "lot_size": 3850,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "PAGEIND",
            "code": 14413,
            "lot_size": 15,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "PEL",
            "code": 2412,
            "lot_size": 550,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "PERSISTENT",
            "code": 18365,
            "lot_size": 150,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "PETRONET",
            "code": 11351,
            "lot_size": 3000,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "PFC",
            "code": 14299,
            "lot_size": 6200,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "PIDILITIND",
            "code": 2664,
            "lot_size": 250,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "PIIND",
            "code": 24184,
            "lot_size": 250,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "PNB",
            "code": 10666,
            "lot_size": 16000,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "POLYCAB",
            "code": 9590,
            "lot_size": 300,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "POWERGRID",
            "code": 14977,
            "lot_size": 2700,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "PVR",
            "code": 13147,
            "lot_size": 407,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "RAIN",
            "code": 15337,
            "lot_size": 3500,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "RAMCOCEM",
            "code": 2043,
            "lot_size": 850,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "RBLBANK",
            "code": 18391,
            "lot_size": 5000,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "RECLTD",
            "code": 15355,
            "lot_size": 8000,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "RELIANCE",
            "code": 2885,
            "lot_size": 250,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "SAIL",
            "code": 2963,
            "lot_size": 8000,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "SBICARD",
            "code": 17971,
            "lot_size": 800,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "SBILIFE",
            "code": 21808,
            "lot_size": 750,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "SBIN",
            "code": 3045,
            "lot_size": 1500,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "SHREECEM",
            "code": 3103,
            "lot_size": 25,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "SIEMENS",
            "code": 3150,
            "lot_size": 275,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "SRF",
            "code": 3273,
            "lot_size": 375,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "SRTRANSFIN",
            "code": 4306,
            "lot_size": 600,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "SUNPHARMA",
            "code": 3351,
            "lot_size": 700,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "SUNTV",
            "code": 13404,
            "lot_size": 1500,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "SYNGENE",
            "code": 10243,
            "lot_size": 1000,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "TATACHEM",
            "code": 3405,
            "lot_size": 500,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "TATACOMM",
            "code": 3721,
            "lot_size": 500,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "TATACONSUM",
            "code": 3432,
            "lot_size": 900,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "TATAMOTORS",
            "code": 3456,
            "lot_size": 1425,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "TATAPOWER",
            "code": 3426,
            "lot_size": 3375,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "TATASTEEL",
            "code": 3499,
            "lot_size": 5500,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "TCS",
            "code": 11536,
            "lot_size": 175,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "TECHM",
            "code": 13538,
            "lot_size": 600,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "TITAN",
            "code": 3506,
            "lot_size": 375,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "TORNTPHARM",
            "code": 3518,
            "lot_size": 500,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "TORNTPOWER",
            "code": 13786,
            "lot_size": 1500,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "TRENT",
            "code": 1964,
            "lot_size": 400,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "TVSMOTOR",
            "code": 8479,
            "lot_size": 700,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "UBL",
            "code": 16713,
            "lot_size": 400,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "ULTRACEMCO",
            "code": 11532,
            "lot_size": 100,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "UPL",
            "code": 11287,
            "lot_size": 1300,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "VEDL",
            "code": 3063,
            "lot_size": 1550,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "VOLTAS",
            "code": 3718,
            "lot_size": 600,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "WHIRLPOOL",
            "code": 18011,
            "lot_size": 350,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "WIPRO",
            "code": 3787,
            "lot_size": 1500,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "ZEEL",
            "code": 3812,
            "lot_size": 3000,
            "exchange": "NSE"
        },
        {
            "trading_symbol": "ZYDUSLIFE",
            "code": 7929,
            "lot_size": 1800,
            "exchange": "NSE"
        }
    ]


    const [originalChainData, SetoriginalChainData] = useState(null)

    const [chainData, SetchainData] = useState(null)

    const [greekData, SetgreekData] = useState(null)

    const [expiryData, SetexpiryData] = useState(null)

    const [apiSuccess, SetapiSuccess] = useState(null)

    const [apiError, SetapiError] = useState(null)

    const [symbol, Setsymbol] = useState({
        exchange: "NSE",
        symbol: "NIFTY",
        token: "26000",
    })

    const [count, Setcount] = useState(18)

    const [symbolExpiry, SetsymbolExpiry] = useState("")

    const [optionchainData, SetoptionchainData] = useState(null)

    const [cartData, SetcartData] = useState([])

    const [modal_center, setmodal_center] = useState(false);

    function tog_center() {
        setmodal_center(!modal_center);
    }

    useEffect(() => {
        if (apiSuccess != null) {
            setTimeout(() => SetapiSuccess(null), 2000)
        }
    }, [apiSuccess]);

    useEffect(() => {
        if (apiError != null) {
            setTimeout(() => SetapiError(null), 2000)
        }
    }, [apiError]);

    useEffect(() => {
        LoadChainData();
    }, []);

    useEffect(() => {
        socket_io.on("socket-marketdata", (result) => {
            //console.log("data=" + JSON.stringify(result));

            var optionchain_record = localStorage.getItem("optionchain_record");
            if (optionchain_record) {

                optionchain_record = JSON.parse(optionchain_record);

                var index = optionchain_record.findIndex(function (e) { return e.instrumentToken == result.instrumentToken });

                //console.log("index=" + index);

                if (index == -1) {
                    optionchain_record.push(result);
                    optionchain_record = JSON.stringify(optionchain_record);
                    localStorage.setItem("optionchain_record", optionchain_record);
                }
                else {
                    optionchain_record[index] = result;
                    optionchain_record = JSON.stringify(optionchain_record);
                    localStorage.setItem("optionchain_record", optionchain_record);
                }

            }
            else {
                var input = [];
                input.push(result)
                input = JSON.stringify(input);
                localStorage.setItem("optionchain_record", input);
            }
            SetoptionchainData(result);

        });

        socket_io.on("socket-optionchain", (result) => {
            //console.log("data=" + JSON.stringify(result));

            var greek_record = localStorage.getItem("greek_record");
            if (greek_record) {

                greek_record = JSON.parse(greek_record);

                var index = greek_record.findIndex(function (e) { return e.instrumentToken == result.instrumentToken });

                //console.log("index=" + index);

                if (index == -1) {
                    greek_record.push(result);
                    greek_record = JSON.stringify(greek_record);
                    localStorage.setItem("greek_record", greek_record);
                }
                else {
                    greek_record[index] = result;
                    greek_record = JSON.stringify(greek_record);
                    localStorage.setItem("greek_record", greek_record);
                }

            }
            else {
                var input = [];
                input.push(result)
                input = JSON.stringify(input);
                localStorage.setItem("greek_record", input);
            }
            SetgreekData(result);

        });

    });

    function collectInstrumentTokensFromUI() {

        //console.log("1");

        var instruments_array = getInstrumentsTokesFromCards();
        if (instruments_array.length > 0) {
            //console.log("instruments_array=" + JSON.stringify(instruments_array));

            AddToMarketDataSocketInstruments(instruments_array);

            AddToOptionChainDataSocketInstruments(instruments_array);

        }

    }

    function getInstrumentsTokesFromCards() {

        if (chainData == null)
            return null;

        var instruments = [];

        //console.log(JSON.stringify(data));

        for (var i = 0; i < chainData.length; i++) {

            if (chainData[i].strikes.length > 0) {

                var exchange_code = parseInt(getExchangeCode(chainData[i].strikes[0].call_option.exchange).code);

                var strikesData = chainData[i].strikes;

                for (var j = 0; j < strikesData.length; j++) {

                    var call_instrument_token = parseInt(strikesData[j].call_option.token);

                    var put_instrument_token = parseInt(strikesData[j].put_option.token);

                    instruments.push([exchange_code, call_instrument_token]);

                    instruments.push([exchange_code, put_instrument_token]);
                }
            }

        }
        return instruments;
    }

    function getExchangeCode(exchange) {
        var result = {}
        result.exchange = exchange;
        if (exchange == "NSE") {
            result.code = 1;
            result.multiplier = 100;
        }
        else if (exchange == "NFO") {
            result.code = 2;
            result.multiplier = 100;
        }
        else if (exchange == "CDS") {
            result.code = 3;
            result.multiplier = 10000000;
        }
        else if (exchange == "MCX") {
            result.code = 4;
            result.multiplier = 100;
        }
        else if (exchange == "BSE") {
            result.code = 6;
            result.multiplier = 100;
        }
        else if (exchange == "BFO") {
            result.code = 7;
            result.multiplier = 100;
        }
        return result;
    }

    function fillOptionChainData(data) {

        //console.log("data.instrumentToken=" + data.instrumentToken);

        // if (marketwatchData)
        //   console.log("marketwatchData empty");

        // if (symbolsData)
        //   console.log("symbolsData empty");

        data.lastTradedPrice = data.lastTradedPrice / 100;
        data.openPrice = data.openPrice / 100;
        data.highPrice = data.highPrice / 100;
        data.lowPrice = data.lowPrice / 100;
        data.closePrice = data.closePrice / 100;

        data.change = data.lastTradedPrice - data.closePrice;

        data.changePercentage = (data.change * 100 / data.closePrice).toFixed(2);

        //console.log("symbolsData2=" + JSON.stringify(symbolsData));

        //console.log("marketwatchData2=" + JSON.stringify(marketwatchData));

        if (chainData == null)
            return null;

        for (var i = 0; i < chainData.length; i++) {

            //console.log("data.instrumentToken=" + data.instrumentToken);

            var strikesData = chainData[i].strikes;

            for (var j = 0; j < strikesData.length; j++) {

                if (chainData[i].strikes[j].call_option.token == data.instrumentToken) {
                    chainData[i].strikes[j].call_option.past_ltp = chainData[i].strikes[j].call_option.ltp;
                    chainData[i].strikes[j].call_option.ltp = data.lastTradedPrice;
                    if (chainData[i].strikes[j].call_option.ltp > chainData[i].strikes[j].call_option.past_ltp) {
                        chainData[i].strikes[j].call_option.ltp_color = "green";
                    }
                    else if (chainData[i].strikes[j].call_option.ltp < chainData[i].strikes[j].call_option.past_ltp) {
                        chainData[i].strikes[j].call_option.ltp_color = "red";
                    }
                    chainData[i].strikes[j].call_option.volume = data.volume;
                    chainData[i].strikes[j].call_option.changePercentage = data.changePercentage;
                    if (data.changePercentage > 0) {
                        chainData[i].strikes[j].call_option.changePercentage_color = "green";
                    }
                    else {
                        chainData[i].strikes[j].call_option.changePercentage_color = "red";
                    }

                    //SetchainData(chainData);
                }

                if (chainData[i].strikes[j].put_option.token == data.instrumentToken) {
                    chainData[i].strikes[j].put_option.past_ltp = chainData[i].strikes[j].put_option.ltp;
                    chainData[i].strikes[j].put_option.ltp = data.lastTradedPrice;
                    if (chainData[i].strikes[j].put_option.ltp > chainData[i].strikes[j].put_option.past_ltp) {
                        chainData[i].strikes[j].put_option.ltp_color = "green";
                    }
                    else if (chainData[i].strikes[j].put_option.ltp < chainData[i].strikes[j].put_option.past_ltp) {
                        chainData[i].strikes[j].put_option.ltp_color = "red";
                    }
                    chainData[i].strikes[j].put_option.volume = data.volume;
                    chainData[i].strikes[j].put_option.changePercentage = data.changePercentage;
                    if (data.changePercentage > 0) {
                        chainData[i].strikes[j].put_option.changePercentage_color = "green";
                    }
                    else {
                        chainData[i].strikes[j].put_option.changePercentage_color = "red";
                    }


                    //SetchainData(chainData);
                }

            }
        }
    }

    function filleGreekData(data) {

        if (chainData == null)
            return null;

        for (var i = 0; i < chainData.length; i++) {

            //console.log("data.instrumentToken=" + data.instrumentToken);

            var strikesData = chainData[i].strikes;

            for (var j = 0; j < strikesData.length; j++) {

                if (chainData[i].strikes[j].call_option.token == data.instrumentToken) {
                    chainData[i].strikes[j].call_option.gamma = data.gamma;
                    chainData[i].strikes[j].call_option.vega = data.vega;
                    chainData[i].strikes[j].call_option.theta = data.theta;
                    chainData[i].strikes[j].call_option.initialOpenInterest = data.initialOpenInterest;
                    chainData[i].strikes[j].call_option.currentOpenInterest = data.currentOpenInterest;

                    //SetchainData(chainData);
                }

                if (chainData[i].strikes[j].put_option.token == data.instrumentToken) {
                    chainData[i].strikes[j].put_option.gamma = data.gamma;
                    chainData[i].strikes[j].put_option.vega = data.vega;
                    chainData[i].strikes[j].put_option.theta = data.theta;
                    chainData[i].strikes[j].put_option.initialOpenInterest = data.initialOpenInterest;
                    chainData[i].strikes[j].put_option.currentOpenInterest = data.currentOpenInterest;

                    //SetchainData(chainData);
                }

            }
        }
    }

    function LoadChainData() {

        const headers = {
            'Content-Type': 'application/json'
        };

        var url = API_URL + "/trading/getOptionChainData/" + symbol.exchange + "/" + symbol.token + "/" + count;

        axios.get(url, { headers }).then(response => {
            //console.log(response)
            var response = response.data;

            if (response.result == "success") {
                //var temp = response.data;
                //console.log(temp.length);

                localStorage.removeItem("optionchain_record");
                localStorage.removeItem("greek_record");

                SetoriginalChainData(response.data);
            }
            else {
                SetapiError(response.message);

                SetoriginalChainData(null);
            }

        });

    }

    function LoadOIChangeData(exchange, token) {
        const headers = {
            'Content-Type': 'application/json'
        };

        var url = API_URL + "/trading/getOIChangeData/" + exchange + "/" + token;

        axios.get(url, { headers }).then(response => {
            //console.log(response)
            var response = response.data;
            //console.log(response)
            if (response.result == "success") {
                var change_in_oi = response.data.change_in_oi || 0;
                console.log("change_in_oi=" + change_in_oi);
                return change_in_oi;
            }
            else {
                //SetapiError(response.message);
                return 0;
            }

        });
    }

    function handleSymbolChange(exchange, symbol, token) {
        //console.log("exchange=" + exchange + ",symbol=" + symbol + ",token=" + token);
        Setsymbol({
            exchange: exchange,
            symbol: symbol,
            token: token
        });

    }

    function changeOptions(data, event) {
        //console.log("data=" + data + ",event=" + event);
        if (event == "change-expiry") {
            SetsymbolExpiry(data);
        }
        else if (event == "change-count") {
            Setcount(data);
        }
    }

    async function FilterChainData() {

        //console.log("symbolExpiry=" + symbolExpiry);

        var filterData = await originalChainData.filter(function (el) {
            return el.expiry_date == symbolExpiry;
        });
        //console.log(JSON.stringify(filterData));
        // if (chainData == null) {
        //     SetchainData(filterData);
        // }
        // else {
        //     SetchainData(null);
        // }
        //console.log(filterData.length);
        if (filterData.length > 0) {
            SetchainData(filterData);
        }
        else {
            SetchainData(null);
            localStorage.removeItem("optionchain_record");
            localStorage.removeItem("greek_record");
        }
    }

    useEffect(() => {
        if (symbol.symbol != "" && count > 0)
            LoadChainData();
    }, [symbol, count]);

    useEffect(() => {
        if (symbolExpiry != "") {
            FilterChainData();
        }
    }, [symbolExpiry]);

    useEffect(() => {
        if (originalChainData != null) {
            var expiry_date = [];
            for (var i = 0; i < originalChainData.length; i++) {
                expiry_date.push(originalChainData[i].expiry_date);
                if (i == 5) break;
            }
            SetexpiryData(expiry_date);
            if (originalChainData.length > 0) {
                SetsymbolExpiry(originalChainData[0].expiry_date);
                FilterChainData();
            }
        }
    }, [originalChainData]);

    useEffect(() => {
        if (chainData != null) {
            collectInstrumentTokensFromUI();
        }
    }, [chainData]);

    useEffect(() => {
        if (optionchainData != null) {
            var optionchain_record = localStorage.getItem("optionchain_record");
            if (optionchain_record) {
                optionchain_record = JSON.parse(optionchain_record);
                for (var i = 0; i < optionchain_record.length; i++) {
                    fillOptionChainData(optionchain_record[i]);
                }
            }
        }
    }, [optionchainData]);

    useEffect(() => {
        if (greekData != null) {
            var greek_record = localStorage.getItem("greek_record");
            if (greek_record) {
                greek_record = JSON.parse(greek_record);
                for (var i = 0; i < greek_record.length; i++) {
                    filleGreekData(greek_record[i]);
                }
            }
        }
    }, [greekData]);

    function CallMouseOver(event) {
        $(event.target).closest("tr").find(".div-call-trade").show();
    }
    function CallMouseOut(event) {
        $(event.target).closest("tr").find(".div-call-trade").hide();
    }

    function PutMouseOver(event) {
        $(event.target).closest("tr").find(".div-put-trade").show();
    }
    function PutMouseOut(event) {
        $(event.target).closest("tr").find(".div-put-trade").hide();
    }

    const [customActiveTab, setcustomActiveTab] = useState("1");

    const toggleCustom = tab => {
        if (customActiveTab !== tab) {
            setcustomActiveTab(tab);
        }
    };

    function addToCart(strike_price, token, transactionType, strikeType, price, exchange) {

        var symbolName = symbol.symbol + " " + symbolExpiry + " " + strike_price + " " + strikeType;

        // var data = {
        //     id: cartData.length + 1,
        //     strike_price: strike_price,
        //     token: token,
        //     transactionType: transactionType,
        //     strikeType: strikeType,
        //     symbolName: symbolName,
        //     ltp: ltp,
        //     qty: 50,
        //     tradeType: "MIS",
        //     orderType: "MARKET"
        // }

        var tradeObject = {
            id: cartData.length + 1,
            strike_price: strike_price,
            strikeType: strikeType,
            symbolName: symbolName,
            symbol: symbol.symbol,
            transactionType: transactionType,
            qty: 50,
            sessionType: "DAY",
            tradeType: "MIS",
            productType: "MARKET",
            orderType: "REGULAR",
            price: price,
            triggerPrice: "0",
            targetPrice: "0",
            stopPrice: "0",
            token: token,
            exchange: exchange
        }

        cartData.push(tradeObject);

        //console.log(JSON.stringify(cartData));

        SetcartData(cartData);

    }

    function removeFromCart(id) {

        //debugger;

        //var temp = cartData;

        var index = cartData.findIndex(function (item, i) {
            return item.id === id
        });

        cartData.splice(index, 1);

        //SetcartData(cartData);

        //console.log(JSON.stringify(cartData));

    }

    function PlaceBasketOrder() {

        if (cartData.length > 0) {

            //console.log(JSON.stringify(cartData));

            var authUser = localStorage.getItem("authUser");
            if (authUser) { authUser = atob(authUser); }
            var email = JSON.parse(authUser).email;

            var obj = {
                "email": email,
                "basketOrders": cartData
            };

            const headers = {
                'Content-Type': 'application/json'
            };

            axios.post(API_URL + '/trading/placeBasketOrders', JSON.stringify(obj), { headers }).then(response => {

                //console.log(response) 

                var output = response.data;

                if (output.result == "success") {

                    SetapiSuccess(output.message);

                    SetcartData([]);
                    setmodal_center(false);

                }
                else {
                    SetapiError(output.message);
                }

            });
        }

    }

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">

                    {apiSuccess && (
                        <div
                            className="alert alert-success text-center"
                            role="alert">
                            {apiSuccess}
                        </div>
                    )}

                    {apiError && (
                        <div
                            className="alert alert-danger text-center"
                            role="alert">
                            {apiError}
                        </div>
                    )}

                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>

                                    <Nav tabs className="nav-tabs-custom nav-justified">
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={classnames({
                                                    active: customActiveTab === "1",
                                                })}
                                                onClick={() => {
                                                    toggleCustom("1");
                                                }}
                                            >
                                                <span className="d-block d-sm-none">
                                                    <i className="fas fa-home"></i>
                                                </span>
                                                <span className="d-none d-sm-block">Option Chain</span>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={classnames({
                                                    active: customActiveTab === "2",
                                                })}
                                                onClick={() => {
                                                    toggleCustom("2");
                                                }}
                                            >
                                                <span className="d-block d-sm-none">
                                                    <i className="far fa-user"></i>
                                                </span>
                                                <span className="d-none d-sm-block">Option Greeks</span>
                                            </NavLink>
                                        </NavItem>
                                    </Nav>

                                    <Row style={{ width: "100%", marginTop: "27px", marginLeft: "9px", paddingRight: "9px" }}>
                                        <Col md={2}>
                                            <div className="form-floating mb-3">
                                                <select name="symbol" className="form-select" onChange={(e) => handleSymbolChange(e.target.selectedOptions[0].getAttribute('data-exchange'), e.target.selectedOptions[0].getAttribute('data-symbol'), e.target.selectedOptions[0].getAttribute('data-token'))}>
                                                    {nse_symbol_data && nse_symbol_data.map((data, key) => (
                                                        <option key={key} data-exchange={data.exchange} data-symbol={data.trading_symbol} data-token={data.code} value={data.code}>{data.trading_symbol}</option>
                                                    ))}
                                                </select>
                                                <label htmlFor="floatingSelectGrid">SELECT SYMBOL</label></div>
                                        </Col>
                                        <Col md={2}>
                                            <div className="form-floating mb-3">
                                                <select name="expiry" className="form-select" onChange={(e) => { changeOptions(e.target.value, "change-expiry") }}>
                                                    {expiryData && expiryData.map((data, key) => (
                                                        <option key={key} value={data}>{data}</option>
                                                    ))}
                                                </select>
                                                <label htmlFor="floatingSelectGrid">SELECT EXPIRY</label></div>
                                        </Col>
                                        <Col md={2}>
                                            <div className="form-floating mb-3">
                                                <select name="expiry" defaultValue={count} className="form-select" onChange={(e) => { changeOptions(e.target.value, "change-count") }}>
                                                    <option value="6">6</option>
                                                    <option value="12">12</option>
                                                    <option value="18">18</option>
                                                </select>
                                                <label htmlFor="floatingSelectGrid">NO.OF STRIKES</label></div>
                                        </Col>
                                        {cartData && cartData.length > 0 && (
                                            <Col md={6} style={{ textAlign: "right" }}>
                                                <button onClick={() => { tog_center(); }} className="btn btn-info"><i className="fas fa-shopping-basket"></i><span className="badge" style={{
                                                    position: "absolute",
                                                    top: "-10px",
                                                    right: "5px",
                                                    padding: "5px 7px",
                                                    borderRadius: "50%",
                                                    background: "red",
                                                    color: "white",
                                                }}>{cartData.length}</span></button>
                                            </Col>
                                        )}
                                    </Row>

                                    <TabContent
                                        activeTab={customActiveTab}
                                        className="p-3 text-muted"
                                    >
                                        <TabPane tabId="1" className="border shadow-none">
                                            <Row>
                                                <Col>
                                                    <Card className="mb-0">
                                                        <CardBody>
                                                            <Row>
                                                                <Col md={12} style={{ display: "inline-flex" }}>
                                                                    <Col md={6} style={call_heading_style}>
                                                                        CALL
                                                                    </Col>
                                                                    <Col md={6} style={put_heading_style}>
                                                                        PUT
                                                                    </Col>
                                                                </Col>

                                                                <div className="table-rep-plugin">
                                                                    <div
                                                                        className="table-responsive mb-0"
                                                                        data-pattern="priority-columns"
                                                                    >
                                                                        <Table
                                                                            id="tech-companies-1"
                                                                            className="table table-striped table-bordered" style={table_style}
                                                                        >
                                                                            <Thead style={table_style}>
                                                                                <Tr style={table_style}>
                                                                                    <Th style={table_style} data-priority="1">OI</Th>
                                                                                    <Th data-priority="2">CHANGE %</Th>
                                                                                    <Th data-priority="4">VOLUME</Th>
                                                                                    <Th data-priority="5">LTP</Th>
                                                                                    <Th data-priority="6">STRIKE</Th>
                                                                                    <Th data-priority="7">LTP</Th>
                                                                                    <Th data-priority="8">VOLUME</Th>
                                                                                    <Th data-priority="10">CHANGE OI%</Th>
                                                                                    <Th data-priority="11">OI</Th>
                                                                                </Tr>
                                                                            </Thead>
                                                                            <Tbody>
                                                                                {
                                                                                    chainData && chainData.sort((a, b) => a.expiry_date > b.expiry_date ? 1 : -1).map((strikeData) => {

                                                                                        return (
                                                                                            strikeData.strikes.map((data, key) => (

                                                                                                <Tr key={key} style={table_style}>

                                                                                                    <Td onMouseOver={CallMouseOver} onMouseOut={CallMouseOut}>{data.call_option.currentOpenInterest || 0}</Td>
                                                                                                    <Td onMouseOver={CallMouseOver} onMouseOut={CallMouseOut} style={{ color: data.call_option.changePercentage_color }}>{data.call_option.changePercentage || 0}</Td>
                                                                                                    <Td onMouseOver={CallMouseOver} onMouseOut={CallMouseOut} style={{ position: "relative" }} >{data.call_option.volume || 0}
                                                                                                        <div className="div-call-trade" style={{
                                                                                                            textAlign: "right",
                                                                                                            position: "absolute",
                                                                                                            right: 0,
                                                                                                            top: 0,
                                                                                                            backgroundColor: "white",
                                                                                                            width: "100%",
                                                                                                            height: "100%",
                                                                                                            paddingTop: "7px",
                                                                                                            display: "none"
                                                                                                        }}
                                                                                                        >
                                                                                                            <button type="button" onClick={() => { addToCart(data.strike_price, data.call_option.token, "BUY", "CE", data.call_option.ltp, data.call_option.exchange) }} className="btn btn-sm btn-outline-success waves-effect waves-light me-1">
                                                                                                                B
                                                                                                            </button>
                                                                                                            <button type="button" onClick={() => { addToCart(data.strike_price, data.call_option.token, "SELL", "CE", data.call_option.ltp, data.call_option.exchange) }} className="btn btn-sm btn-outline-danger waves-effect waves-light me-1">
                                                                                                                S
                                                                                                            </button>

                                                                                                        </div>
                                                                                                    </Td>
                                                                                                    <Td onMouseOver={CallMouseOver} onMouseOut={CallMouseOut} style={{ fontWeight: "bold", color: data.call_option.ltp_color }}>{data.call_option.ltp || 0}</Td>

                                                                                                    <Td>{data.strike_price || 0}</Td>

                                                                                                    <Td onMouseOver={PutMouseOver} onMouseOut={PutMouseOut} style={{ fontWeight: "bold", color: data.put_option.ltp_color }}>{data.put_option.ltp || 0}</Td>
                                                                                                    <Td onMouseOver={PutMouseOver} onMouseOut={PutMouseOut} style={{ position: "relative" }}>{data.put_option.volume || 0}
                                                                                                        <div className="div-put-trade" style={{
                                                                                                            textAlign: "left",
                                                                                                            position: "absolute",
                                                                                                            left: 0,
                                                                                                            top: 0,
                                                                                                            backgroundColor: "white",
                                                                                                            width: "100%",
                                                                                                            height: "100%",
                                                                                                            paddingTop: "7px",
                                                                                                            display: "none"
                                                                                                        }}
                                                                                                        >
                                                                                                            <button type="button" onClick={() => { addToCart(data.strike_price, data.put_option.token, "BUY", "PE", data.put_option.ltp, data.put_option.exchange) }} className="btn btn-sm btn-outline-success waves-effect waves-light me-1">
                                                                                                                B
                                                                                                            </button>
                                                                                                            <button type="button" onClick={() => { addToCart(data.strike_price, data.put_option.token, "SELL", "PE", data.put_option.ltp, data.put_option.exchange) }} className="btn btn-sm btn-outline-danger waves-effect waves-light me-1">
                                                                                                                S
                                                                                                            </button>

                                                                                                        </div>
                                                                                                    </Td>
                                                                                                    <Td onMouseOver={PutMouseOver} onMouseOut={PutMouseOut} style={{ color: data.put_option.changePercentage_color }}>{data.put_option.changePercentage || 0}</Td>
                                                                                                    <Td onMouseOver={PutMouseOver} onMouseOut={PutMouseOut}>{data.put_option.currentOpenInterest || 0}</Td>

                                                                                                </Tr>

                                                                                            ))
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </Tbody>
                                                                        </Table>
                                                                    </div>
                                                                </div>
                                                            </Row>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </TabPane>
                                        <TabPane tabId="2" className="border shadow-none">
                                            <Row>
                                                <Col>
                                                    <Card className="mb-0">
                                                        <CardBody>
                                                            <Row>
                                                                <Col md={12} style={{ display: "inline-flex" }}>
                                                                    <Col md={6} style={call_heading_style}>
                                                                        CALL
                                                                    </Col>
                                                                    <Col md={6} style={put_heading_style}>
                                                                        PUT
                                                                    </Col>
                                                                </Col>

                                                                <div className="table-rep-plugin">
                                                                    <div
                                                                        className="table-responsive mb-0"
                                                                        data-pattern="priority-columns"
                                                                    >
                                                                        <Table
                                                                            id="tech-companies-1"
                                                                            className="table table-striped table-bordered" style={table_style}
                                                                        >
                                                                            <Thead style={table_style}>
                                                                                <Tr style={table_style}>
                                                                                    <Th style={table_style} data-priority="1">GAMMA</Th>
                                                                                    <Th data-priority="2">VEGA</Th>
                                                                                    <Th data-priority="4">THETA</Th>
                                                                                    <Th data-priority="5">LTP</Th>
                                                                                    <Th data-priority="6">STRIKE</Th>
                                                                                    <Th data-priority="7">LTP</Th>
                                                                                    <Th data-priority="8">THETA</Th>
                                                                                    <Th data-priority="10">VEGA</Th>
                                                                                    <Th data-priority="11">GAMMA</Th>
                                                                                </Tr>
                                                                            </Thead>
                                                                            <Tbody>
                                                                                {
                                                                                    chainData && chainData.sort((a, b) => a.expiry_date > b.expiry_date ? 1 : -1).map((strikeData) => {

                                                                                        return (
                                                                                            strikeData.strikes.map((data, key) => (

                                                                                                <Tr key={key} style={table_style}>

                                                                                                    <Td onMouseOver={CallMouseOver} onMouseOut={CallMouseOut}>{data.call_option.gamma || 0}</Td>
                                                                                                    <Td onMouseOver={CallMouseOver} onMouseOut={CallMouseOut}>{data.call_option.vega || 0}</Td>
                                                                                                    <Td onMouseOver={CallMouseOver} onMouseOut={CallMouseOut} style={{ position: "relative" }} >{data.call_option.theta || 0}
                                                                                                        <div className="div-call-trade" style={{
                                                                                                            textAlign: "right",
                                                                                                            position: "absolute",
                                                                                                            right: 0,
                                                                                                            top: 0,
                                                                                                            backgroundColor: "white",
                                                                                                            width: "100%",
                                                                                                            height: "100%",
                                                                                                            paddingTop: "7px",
                                                                                                            display: "none"
                                                                                                        }}
                                                                                                        >
                                                                                                            <button type="button" onClick={() => { addToCart(data.strike_price, data.call_option.token, "BUY", "CE", data.call_option.ltp, data.call_option.exchange) }} className="btn btn-sm btn-outline-success waves-effect waves-light me-1">
                                                                                                                B
                                                                                                            </button>
                                                                                                            <button type="button" onClick={() => { addToCart(data.strike_price, data.call_option.token, "SELL", "CE", data.call_option.ltp, data.call_option.exchange) }} className="btn btn-sm btn-outline-danger waves-effect waves-light me-1">
                                                                                                                S
                                                                                                            </button>

                                                                                                        </div>
                                                                                                    </Td>
                                                                                                    <Td onMouseOver={CallMouseOver} onMouseOut={CallMouseOut}>{data.call_option.ltp || 0}</Td>

                                                                                                    <Td>{data.strike_price || 0}</Td>

                                                                                                    <Td onMouseOver={PutMouseOver} onMouseOut={PutMouseOut}>{data.put_option.ltp || 0}</Td>
                                                                                                    <Td onMouseOver={PutMouseOver} onMouseOut={PutMouseOut} style={{ position: "relative" }}>{data.put_option.theta || 0}
                                                                                                        <div className="div-put-trade" style={{
                                                                                                            textAlign: "left",
                                                                                                            position: "absolute",
                                                                                                            left: 0,
                                                                                                            top: 0,
                                                                                                            backgroundColor: "white",
                                                                                                            width: "100%",
                                                                                                            height: "100%",
                                                                                                            paddingTop: "7px",
                                                                                                            display: "none"
                                                                                                        }}
                                                                                                        >
                                                                                                            <button type="button" onClick={() => { addToCart(data.strike_price, data.put_option.token, "BUY", "PE", data.put_option.ltp, data.put_option.exchange) }} className="btn btn-sm btn-outline-success waves-effect waves-light me-1">
                                                                                                                B
                                                                                                            </button>
                                                                                                            <button type="button" onClick={() => { addToCart(data.strike_price, data.put_option.token, "SELL", "PE", data.put_option.ltp, data.put_option.exchange) }} className="btn btn-sm btn-outline-danger waves-effect waves-light me-1">
                                                                                                                S
                                                                                                            </button>

                                                                                                        </div>
                                                                                                    </Td>
                                                                                                    <Td onMouseOver={PutMouseOver} onMouseOut={PutMouseOut}>{data.put_option.vega || 0}</Td>
                                                                                                    <Td onMouseOver={PutMouseOver} onMouseOut={PutMouseOut}>{data.put_option.gamma || 0}</Td>

                                                                                                </Tr>

                                                                                            ))
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </Tbody>
                                                                        </Table>
                                                                    </div>
                                                                </div>
                                                            </Row>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </TabPane>
                                    </TabContent>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>


                </div>
            </div>

            <Modal
                size="lg"
                isOpen={modal_center}
                toggle={() => {
                    tog_center();
                }}
                centered
            >
                <div className="bg-info text-white modal-header">
                    <h5 className="modal-title mt-0" style={{ "color": "#ffff" }}>Place Order</h5>
                    <div>

                    </div>
                </div>
                <div className="modal-body">

                    <div className="table-responsive mb-0" style={{ maxHeight: "300px" }}>
                        <table className="table">
                            <tbody>
                                {cartData && cartData.map((data, key) => (

                                    <tr key={key}>
                                        <td>
                                            {data.symbolName}
                                        </td>
                                        <td>
                                            <button className={(data.transactionType == "BUY") ? "btn btn-sm btn-success" : "btn btn-sm btn-danger"}>
                                                {data.transactionType}
                                            </button>
                                        </td>
                                        <td>
                                            <InputGroup>
                                                <div
                                                    className="input-group-append"
                                                    onClick={() => {
                                                        if (data.qty > 50)
                                                            data.qty -= 50;
                                                    }}
                                                >
                                                    <Button type="button" className="btn-sm" color="primary">
                                                        <i className="mdi mdi-minus" />
                                                    </Button>
                                                </div>
                                                <input
                                                    type="number"
                                                    className="form-control form-control-sm"
                                                    value={data.qty}
                                                    placeholder="number"
                                                    style={{ width: "70px", height: "29px", textAlign: "center" }}
                                                    readOnly
                                                />
                                                <div
                                                    className="input-group-append"
                                                    onClick={() => {
                                                        data.qty += 50;
                                                    }}
                                                >
                                                    <Button type="button" className="btn-sm" color="primary">
                                                        <i className="mdi mdi-plus" />
                                                    </Button>
                                                </div>
                                            </InputGroup>
                                        </td>

                                        <td>
                                            <div className="col-md-12">
                                                <select value={data.tradeType} onChange={(e) => { data.tradeType = e.target.value }} className="form-control form-select form-select-sm">
                                                    <option value="MIS">MIS</option>
                                                    <option value="NRML">NRML</option>
                                                </select>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="col-md-12">
                                                <select value={data.productType} onChange={(e) => { data.productType = e.target.value }} className="form-control form-select form-select-sm">
                                                    <option value="MARKET">MARKET</option>
                                                    <option value="LIMIT">LIMIT</option>
                                                </select>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="col-md-12">
                                                <input type="text" style={{ width: "80px" }} onChange={(e) => { data.price = e.target.value }} className="form-control form-control-sm" readOnly={data.productType == "MARKET"} value={data.price}></input>
                                            </div>
                                        </td>
                                        <td>
                                            <button onClick={() => { removeFromCart(data.id); }} className="btn btn-sm btn-danger"><i className="fa fa-trash"></i></button>
                                        </td>
                                    </tr>

                                ))}

                            </tbody>
                        </table>
                    </div>

                </div>

                <div className="model-footer mb-3">
                    <div className="col-md-12" style={{ textAlign: "right" }}>

                        <button type="button" className="btn btn-sm btn-danger w-md me-2" data-dismiss="modal" onClick={() => {
                            SetcartData([]);
                            setmodal_center(false);
                        }}>Clear</button>

                        <button type="button" className="btn btn-sm btn-info text-white w-md me-2" style={{ "color": "#ffff" }} onClick={() => { PlaceBasketOrder(); }}>Place</button>

                    </div>
                </div>

                {apiSuccess && (
                    <div
                        className="alert alert-success text-center mb-0"
                        role="alert">
                        {apiSuccess}
                    </div>
                )}

                {apiError && (
                    <div
                        className="alert alert-danger text-center mb-0"
                        role="alert">
                        {apiError}
                    </div>
                )}

            </Modal>
        </React.Fragment>
    );
};

export default OptionChain;