import React, { useEffect, useState, useRef } from "react"

//redux
import { useSelector, useDispatch } from "react-redux";

import { Container, Row, Col, Card, CardBody, CardTitle, CardSubtitle, Tooltip, Badge } from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

import { API_URL } from "helpers/api_helper";

import axios from "axios";

const CreatedAccounts = props => {

  //meta title
  document.title = "Created Accounts | Algolingo ";

  const dispatch = useDispatch();

  const [ttop, setttop] = useState({ id: "TooltipTop_0", open: false, message: "" });

  const [apiSuccess, SetapiSuccess] = useState(null)

  const [apiError, SetapiError] = useState(null)

  const [AccountsData, SetAccountsData] = useState(null)

  const today = new Date(Date.now());

  today.setHours(0, 0, 0, 0);

  useEffect(() => {
    LoadAccountsData();
  }, []);

  function LoadAccountsData() {

    var authUser = localStorage.getItem("authUser");
    if (authUser) { authUser = atob(authUser); }
    var email = JSON.parse(authUser).email;

    var obj = {
      "email": email
    };

    const headers = {
      'Content-Type': 'application/json'
    };

    axios.post(API_URL + '/user/getCreatedAccounts', JSON.stringify(obj), { headers }).then(response => {

      //console.log(response)

      var output = response.data;

      if (output.result == "success") {

        SetAccountsData(output.data);

      }
      else {
        SetapiError(output.message);
      }

    });

  }

  function CheckAuthenticationTokenExists(defaultBroker, authentication) {
    var filterAuthentication = authentication.filter(auth => {
      return auth.brokerId.toLowerCase().match(defaultBroker.toLowerCase()) && new Date(auth.tokenUpdated_at) > today
    });
    if (filterAuthentication.length > 0) return true;
    return false;
  }

  function GetDefaultBrokerBalance(defaultBroker, authentication) {
    var filterAuthentication = authentication.filter(auth => { return auth.brokerId == defaultBroker });
    if (filterAuthentication.length > 0) {
      return filterAuthentication[0].availableBalance || 0;
    }
    else {
      return 0;
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Dashboards" breadcrumbItem="Created Accounts" />

          <Row>
            <Col>
              <Card>
                <CardBody>

                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                    >
                      <Table
                        id="tech-companies-1"
                        className="table table-striped table-bordered"
                      >
                        <Thead>
                          <Tr>
                            <Th>#</Th>
                            <Th>Name</Th>
                            <Th>Email</Th>
                            <Th>Mobile</Th>
                            <Th>Broker</Th>
                            <Th>Available Balance</Th>
                            <Th>Token Status</Th>
                            <Th>Days Left</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {AccountsData &&

                            AccountsData.map((data, key) => (
                              <Tr key={key}>
                                <Td>
                                  {key + 1}
                                </Td>
                                <Td>
                                  {data.name}
                                </Td>
                                <Td>
                                  {data.email}
                                </Td>
                                <Td>
                                  {data.mobile}
                                </Td>
                                <Td>
                                  {data.users.length > 0 && data.users[0].defaultBroker ? data.users[0].defaultBroker.toUpperCase() : "No Broker"}
                                </Td>
                                <Td>
                                  {
                                    (data.users && data.users.length > 0 && data.users[0].authentication && data.users[0].authentication.length > 0) ?
                                      (
                                        GetDefaultBrokerBalance(data.users[0].defaultBroker, data.users[0].authentication)
                                      )
                                      :
                                      0
                                  }
                                </Td>
                                <Td>
                                  {
                                    (data.users && data.users.length > 0 && data.users[0].authentication && data.users[0].authentication.length > 0) ?
                                      (
                                        CheckAuthenticationTokenExists(data.users[0].defaultBroker, data.users[0].authentication) == true ?
                                          (<Badge color="success" style={{ "padding": "8px 12px 8px 12px", "textAlign": "left" }}>Valid</Badge>)
                                          :
                                          (<Badge color="danger" style={{ "padding": "8px 12px 8px 12px", "textAlign": "left" }}>Expired</Badge>)
                                      )
                                      :
                                      (<Badge color="dark" style={{ "padding": "8px 12px 8px 12px", "textAlign": "left" }}>No Token</Badge>)
                                  }
                                </Td>
                                <Td>
                                  {
                                    parseInt((new Date(data.expired_at) - new Date(Date.now())) / (1000 * 60 * 60 * 24)) > 0 ?
                                      (<span className="text-success">{parseInt((new Date(data.expired_at) - new Date(Date.now())) / (1000 * 60 * 60 * 24))}</span>)
                                      :
                                      (<span className="text-danger">{parseInt((new Date(data.expired_at) - new Date(Date.now())) / (1000 * 60 * 60 * 24))}</span>)
                                  }
                                </Td>
                              </Tr>
                            ))

                          }
                        </Tbody>
                      </Table>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

        </Container>
      </div>
    </React.Fragment>
  )
}

export default CreatedAccounts
