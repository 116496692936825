import React, { useEffect, useState } from "react"

//redux
import { useSelector, useDispatch } from "react-redux";

import {
    Card,
    Col,
    Container,
    Row,
    CardBody,
    CardTitle,
    Label,
    Button,
    Form,
    Input,
    InputGroup,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    FormGroup,
    Badge
} from "reactstrap";

import { Link } from "react-router-dom"

import Swal from 'sweetalert2'

import { API_URL } from "helpers/api_helper";

import axios from "axios";




const PaymentStatus = props => {

    const [paymentSuccess, setpaymentSuccess] = useState(false);

    const [paymentFailed, setpaymentFailed] = useState(false);

    useEffect(() => {

        async function checkPaymentStatus() {

           
        }

        checkPaymentStatus();

    }, []);




    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>


                </Container>
            </div>
        </React.Fragment>
    );
}

export default PaymentStatus;