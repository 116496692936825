import React, { useEffect, useState, useRef } from "react";

//redux
import { useSelector, useDispatch } from "react-redux";

import { Container, Row, Col, Card, CardBody, CardTitle, CardSubtitle, Tooltip } from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

import { Link } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

// actions

import axios from "axios";

import { API_URL } from "helpers/api_helper";

import { socket_io, AddToMarketDataSocketInstruments } from "../../../common/sockets"

const TradeHistory = props => {

  //meta title
  document.title = "TradeHistory | Algolingo ";


  const [ttop, setttop] = useState({ id: "TooltipTop_0", open: false, message: "" });

  const [page, setpage] = useState(0);

  const [searchData, setsearchData] = useState("");

  const [tradehistoryData, settradehistoryData] = useState(null);

  const [apiSuccess, SetapiSuccess] = useState(null)

  const [apiError, SetapiError] = useState(null)

  const [socketInstruments, SetsocketInstruments] = useState(null)

  const [TickData, SetTickData] = useState(null);

  useEffect(() => {
    if (apiSuccess != null) {
      setTimeout(() => SetapiSuccess(null), 1000)
    }
  }, [apiSuccess]);

  useEffect(() => {
    if (apiError != null) {
      setTimeout(() => SetapiError(null), 1000)
    }
  }, [apiError]);

  useEffect(() => {
    LoadData(searchData, page);
  }, []);

  useEffect(() => {

    socket_io.on("event", (data) => {
      //console.log("data=" + data);
      if (data == "reload") {
        LoadData(searchData, page);
      }
    });

  }, []);

  function SearchData() {
    //alert("searchData=" + searchData);
    LoadData(searchData, page);
  }

  function LoadData(search, no) {

    var authUser = localStorage.getItem("authUser");
    if (authUser) { authUser = atob(authUser); }
    var email = JSON.parse(authUser).email;

    var obj = {
      "email": email,
      "search": search,
      "page": no
    };

    //console.log("page=" + no);

    const headers = {
      'Content-Type': 'application/json'
    };

    axios.post(API_URL + '/trading/getOrders', JSON.stringify(obj), { headers }).then(response => {

      //console.log(response) 

      var output = response.data;

      if (output.result == "success") {
        settradehistoryData(output.data);
        if (output.data) {
          var instruments = { "a": "subscribe", "v": [], "m": "marketdata" };
          var data = output.data;
          for (var i = 0; i < data.length; i++) {
            var exchange_code = parseInt(getExchangeCode(data[i].tradeProperties.exchange).code);
            var instrument_token = parseInt(data[i].tradeProperties.token);
            instruments.v.push([exchange_code, instrument_token]);
          }
          SetsocketInstruments(instruments);
        }
      }
      else {
        settradehistoryData(null);
      }
    });
  }

  function ClearStrategiesTradeHistory() {

    if (window.confirm('Are You Sure Want To Clear History ?')) {

      var authUser = localStorage.getItem("authUser");
      if (authUser) { authUser = atob(authUser); }
      var email = JSON.parse(authUser).email;

      var obj = {
        "email": email
      };

      //console.log("page=" + no);

      const headers = {
        'Content-Type': 'application/json'
      };

      axios.post(API_URL + '/trading/clearStrategiesTradeHistory', JSON.stringify(obj), { headers }).then(response => {

        //console.log(response) 

        var output = response.data;

        if (output.result == "success") {

          SetapiSuccess(output.message);

          settradehistoryData(null);

        }
        else {
          SetapiError(output.message);
        }

      });

    }

  }

  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const isMobile = width <= 768;

  function getExchangeCode(exchange) {
    var result = {}
    result.exchange = exchange;
    if (exchange == "NSE") {
      result.code = 1;
      result.multiplier = 100;
    }
    else if (exchange == "NFO") {
      result.code = 2;
      result.multiplier = 100;
    }
    else if (exchange == "CDS") {
      result.code = 3;
      result.multiplier = 10000000;
    }
    else if (exchange == "MCX") {
      result.code = 4;
      result.multiplier = 100;
    }
    else if (exchange == "BSE") {
      result.code = 6;
      result.multiplier = 100;
    }
    else if (exchange == "BFO") {
      result.code = 7;
      result.multiplier = 100;
    }

    return result;

  }

  useEffect(() => {

    if (socketInstruments != null) {

      if (socketInstruments.v.length > 0) {
        //console.log("socketInstruments=" + JSON.stringify(socketInstruments));
        AddToMarketDataSocketInstruments(socketInstruments.v);
      }

      socket_io.on("socket-marketdata", (result) => {

        //console.log("data=" + JSON.stringify(result));

        var tickdata_record = localStorage.getItem("tradehistory_tickdata_record");
        if (tickdata_record) {

          tickdata_record = JSON.parse(tickdata_record);

          var index = tickdata_record.findIndex(function (e) { return e.instrumentToken == result.instrumentToken });

          //console.log("index=" + index);

          if (index == -1) {
            tickdata_record.push(result);
            tickdata_record = JSON.stringify(tickdata_record);
            localStorage.setItem("tradehistory_tickdata_record", tickdata_record);
          }
          else {
            tickdata_record[index] = result;
            tickdata_record = JSON.stringify(tickdata_record);
            localStorage.setItem("tradehistory_tickdata_record", tickdata_record);
          }

        }
        else {
          var input = [];
          input.push(result)
          input = JSON.stringify(input);
          localStorage.setItem("tradehistory_tickdata_record", input);
        }

        SetTickData(result);

      });

    }

  }, [socketInstruments]);

  useEffect(() => {
    if (TickData != null) {
      var tickdata_record = localStorage.getItem("tradehistory_tickdata_record");
      if (tickdata_record) {
        tickdata_record = JSON.parse(tickdata_record);
        for (var i = 0; i < tickdata_record.length; i++) {
          fillInstrumentsTickData(tickdata_record[i]);
        }
      }
    }
  }, [TickData]);

  function fillInstrumentsTickData(data) {

    //console.log("data.instrumentToken=" + data.instrumentToken);

    data.lastTradedPrice = data.lastTradedPrice / 100;
    data.openPrice = data.openPrice / 100;
    data.highPrice = data.highPrice / 100;
    data.lowPrice = data.lowPrice / 100;
    data.closePrice = data.closePrice / 100;

    if (tradehistoryData) {
      for (var i = 0; i < tradehistoryData.length; i++) {

        //console.log("tradehistoryData[i].tradeProperties.symbol_code=" + tradehistoryData[i].tradeProperties.symbol_code + ",data.instrumentToken=" + data.instrumentToken);

        if (parseInt(tradehistoryData[i].tradeProperties.token) == data.instrumentToken) {

          tradehistoryData[i].pastprice = tradehistoryData[i].live_price || 0;
          tradehistoryData[i].live_price = data.lastTradedPrice;

          if (parseFloat(tradehistoryData[i].live_price) > parseFloat(tradehistoryData[i].pastprice)) {
            tradehistoryData[i].trend = "UP";
          }
          else if (parseFloat(tradehistoryData[i].live_price) < parseFloat(tradehistoryData[i].pastprice)) {
            tradehistoryData[i].trend = "DOWN";
          }

        }
      }
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Dashboards" breadcrumbItem="Trade History" />

          <Row>
            <Col>
              <Card>
                <CardBody>

                  <Row className="mb-4">
                    <Col md={6} className="mb-2">
                      <div className="d-flex flex-wrap gap-2">

                        <div className="btn-group">
                          <Link to="#" onClick={() => {
                            if (page > 0) {
                              LoadData(searchData, page - 1);
                              setpage(page - 1);
                            }
                          }} className="btn btn-outline-primary">Prev</Link>
                          <Link to="#" className="btn btn-outline-primary">{page + 1}</Link>
                          <Link to="#" onClick={() => {
                            LoadData(searchData, page + 1);
                            setpage(page + 1);
                          }} className="btn btn-outline-primary">Next</Link>
                        </div>
                      </div>
                    </Col>
                    <Col md={6} className="mb-2" style={isMobile == false ? { textAlign: 'right' } : {}}>
                      <div style={{ display: "inline-flex" }}>
                        <input value={searchData} onChange={(val) => {
                          if (val != "") {
                            setsearchData(val.target.value)
                          }
                        }}
                          onKeyPress={event => {
                            if (event.key === 'Enter') {
                              SearchData();
                            }
                          }}
                          className="form-control " type="text" placeholder="Search"></input>

                        <button
                          type="button"
                          className="btn btn-danger ms-2 me-3"
                          onClick={() => {
                            ClearStrategiesTradeHistory();
                          }}
                        >
                          Clear
                        </button>
                      </div>
                    </Col>
                  </Row>

                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                    >
                      <Table
                        id="tech-companies-1"
                        className="table table-striped table-bordered"
                      >
                        <Thead>
                          <Tr>
                            <Th data-priority="1">Email</Th>
                            <Th data-priority="2">Client ID</Th>
                            <Th data-priority="3">Broker</Th>
                            <Th data-priority="4">Symbol</Th>
                            <Th data-priority="5">Type</Th>
                            <Th data-priority="6">Qty</Th>
                            <Th data-priority="7">Live Price</Th>
                            <Th data-priority="8">DateTime</Th>
                            <Th data-priority="9">Status</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {tradehistoryData &&

                            tradehistoryData.map((data, key) => (
                              <Tr key={key}>
                                <Td>{data.email}</Td>
                                <Td>
                                  {(data.credentials.brokerId == "iifl" || data.credentials.brokerId == "agarwal" || data.credentials.brokerId == "bigul" || data.credentials.brokerId == "nirmal" || data.credentials.brokerId == "anand" || data.credentials.brokerId == "composite" || data.credentials.brokerId == "wisdom" || data.credentials.brokerId == "smc" || data.credentials.brokerId == "sscorp") ? (data.credentials.fields["xts_clientId"]) : (data.credentials.fields[data.credentials.brokerId + "_clientId"])}
                                </Td>
                                <Td>{data.broker.toUpperCase()}</Td>
                                <Td>{data.tradeProperties.symbol}</Td>
                                <Td><p style={{ color: (data.tradeProperties.transactionType == "BUY") ? "green" : "red" }}>{data.tradeProperties.transactionType}</p></Td>
                                <Td>{data.tradeProperties.qty}</Td>
                                <Td><p style={{ color: (data.trend == "UP") ? "green" : "red" }}>{(data.live_price || 0).toFixed(2)}</p></Td>
                                <Td>{(data.created_at) ? new Date(data.created_at).toLocaleString() : "-"}</Td>
                                <Td>

                                  <button
                                    type="button"
                                    className={((JSON.stringify(data.response).toLowerCase().indexOf("success") > -1 && JSON.stringify(data.response).toLowerCase().indexOf("invalid") == -1) || (JSON.stringify(data.response).toLowerCase().indexOf("ok") > -1) || (JSON.stringify(data.response).toLowerCase().indexOf("transit") > -1) || (JSON.stringify(data.response).toLowerCase().indexOf('"code":-99') > -1)) ? "btn btn-success" : "btn btn-danger"}
                                    id={"TooltipTop_" + key}
                                    onClick={() => {
                                      setttop({ id: "TooltipTop_" + key, open: !ttop.open, message: JSON.stringify(data.response) })
                                    }}

                                  >
                                    {((JSON.stringify(data.response).toLowerCase().indexOf("success") > -1 && JSON.stringify(data.response).toLowerCase().indexOf("invalid") == -1) || (JSON.stringify(data.response).toLowerCase().indexOf("ok") > -1) || (JSON.stringify(data.response).toLowerCase().indexOf("transit") > -1) || (JSON.stringify(data.response).toLowerCase().indexOf('"code":-99') > -1)) ? "SUCCESS" : "FAILED"}
                                  </button>

                                  <Tooltip
                                    placement="top"
                                    isOpen={ttop.id == "TooltipTop_" + key && ttop.open}
                                    target={"TooltipTop_" + key}
                                  >
                                    {ttop.id == "TooltipTop_" + key && ttop.message}
                                  </Tooltip>



                                </Td>
                              </Tr>
                            ))}
                        </Tbody>
                      </Table>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

        </Container>
      </div>
    </React.Fragment>
  )
}

export default TradeHistory
