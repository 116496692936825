import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom";
import { Col, Container, Form, Row, Input, Label, FormFeedback, FormGroup } from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// import images
import logodark from "../../../assets/images/logo.png";
import logolight from "../../../assets/images/logo.png";
import CarouselPage from "./CarouselPage";

import { API_URL } from "helpers/api_helper";

import axios from "axios";

//Verification code package
import AuthCode from "react-auth-code-input"

const Totp = props => {

    //meta title
    document.title = "TOTP | Algolingo ";

    const [apiSuccess, SetapiSuccess] = useState(null)

    const [apiError, SetapiError] = useState(null)

    const [totp, Setotp] = useState("")

    const [session, Setsession] = useState(null)

    const [showQR, SetshowQR] = useState(false)

    const [QRUrl, SetQRUrl] = useState("")

    const [mfaSecret, SetmfaSecret] = useState("")

    useEffect(() => {
        var element = document.getElementById("div-login");
        element.scrollIntoView();
        element.scrollIntoView(false);
        element.scrollIntoView({ block: "end" });
        element.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }, []);

    useEffect(() => {

        var authUser = localStorage.getItem("authUser");
        if (authUser) {
            authUser = atob(authUser);
            Setsession(JSON.parse(authUser));
        }

    }, []);

    useEffect(() => {

        if (session) {
            if ((session.twoFactor == "false") || (session.mfaEnabled && session.mfaEnabled == "true")) {
                props.history.push("/marketwatch");
            }
            else {

                if ((session.mfaSecret || "false") == "false") {
                    var url = API_URL + "/user/mfa_qr_code_image?name=" + session.name + "&email=" + session.email + "&mfaEnabled=" + session.mfaEnabled;
                    LoadSecret(url);
                }

            }
        }
    }, [session]);

    function onVerifyCodeSubmit() {

        //console.log("totp.length=" + totp.length)

        if (totp.length == 6) {

            var authUser = localStorage.getItem("authUser");
            if (authUser) { authUser = atob(authUser); }
            var email = JSON.parse(authUser).email;

            var obj = {
                "email": email,
                "code": totp
            };

            const headers = {
                'Content-Type': 'application/json'
            };

            axios.post(API_URL + '/user/verify_otp', JSON.stringify(obj), { headers }).then(response => {

                //console.log(response) 

                var output = response.data;

                if (output.result == "success") {

                    SetapiSuccess(output.message);

                    var authUser = localStorage.getItem("authUser");
                    if (authUser) {
                        authUser = atob(authUser);
                        authUser = JSON.parse(authUser);
                        authUser.mfaEnabled = "true";
                        localStorage.setItem("authUser", btoa(JSON.stringify(authUser)));
                    }

                    props.history.push("/marketwatch");

                }
                else {
                    SetapiError(output.message);
                }

            });

        } else {
            SetapiError("Invalid TOTP !!!");
        }
    }

    useEffect(() => {
        if (apiSuccess != null) {
            setTimeout(() => SetapiSuccess(null), 1000)
        }
    }, [apiSuccess]);

    useEffect(() => {
        if (apiError != null) {
            setTimeout(() => SetapiError(null), 1000)
        }
    }, [apiError]);

    function LoadSecret(url) {

        const headers = {
            'Content-Type': 'application/json'
        };

        axios.get(url, {}).then(response => {

            var output = response.data;

            console.log(output)

            if (output.result == "success") {

                //SetapiSuccess(output.message);

                SetmfaSecret(output.mfaSecret);

                SetQRUrl(output.image);

                SetshowQR(true);

            }
            else {
                SetapiError(output.message);
            }

        });

    }

    function CopyClipboard() {
        var copyText = document.getElementById("txt-qr-secret");
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        navigator.clipboard.writeText(copyText.value);

        SetapiSuccess("Secret Copied !!!");
    }

    return (
        <React.Fragment>
            <div>
                <Row className="g-0">
                    <CarouselPage />
                    <Col xl={3} id="div-login">
                        <div className="auth-full-page-content p-md-5 p-4">
                            <div className="w-100">
                                <div className="d-flex flex-column h-100">
                                    <div className="mb-4 mb-md-5">
                                        <Link to="/dashboard" className="d-block auth-logo">
                                            <img
                                                src={logodark}
                                                alt=""
                                                height="36"
                                                className="auth-logo-dark"
                                            />
                                            <img
                                                src={logolight}
                                                alt=""
                                                height="36"
                                                className="auth-logo-light"
                                            />
                                        </Link>
                                    </div>

                                    {apiSuccess && (
                                        <div
                                            className="alert alert-success text-center mb-4"
                                            role="alert">
                                            {apiSuccess}
                                        </div>
                                    )}

                                    {apiError && (
                                        <div
                                            className="alert alert-danger text-center mb-4"
                                            role="alert">
                                            {apiError}
                                        </div>
                                    )}

                                    <div className="my-auto">
                                        <div className="text-center">
                                            <div className="avatar-md mx-auto">
                                                <div className="avatar-title rounded-circle bg-light">
                                                    <i className="bx bx-shield-quarter h1 mb-0 text-primary"></i>
                                                </div>
                                            </div>

                                            <h4 className="mt-3">Verify Your Authentication</h4>

                                            {showQR && (
                                                <>
                                                    <div className="p-2 mt-4">
                                                        <div>
                                                            <p>Scan the QR code on your Authenticator App</p>
                                                            <img src={QRUrl} />
                                                        </div>
                                                    </div><div className="p-2 mt-4">
                                                        <div>
                                                            <p>Or if you are a mobile user, you can copy
                                                                the secret key and
                                                                paste on authenticator app</p>

                                                            <div className="input-group mb-3" style={{ cursor: "pointer" }} onClick={CopyClipboard}>
                                                                <input id="txt-qr-secret" style={{ cursor: "pointer" }} type="text" readOnly={true} disabled={true} className="form-control text-center" value={mfaSecret}></input>
                                                                <div className="input-group-text" style={{ cursor: "pointer" }}> <i className="btn-icon-prepend bx bx-clipboard"></i></div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                            <Form className="p-2 mt-4" onSubmit={(e) => {
                                                e.preventDefault();
                                                onVerifyCodeSubmit();
                                                return false;
                                            }}>

                                                <p>
                                                    Type 6 Digits TOTP from Authenticator App
                                                </p>

                                                <div>
                                                    <Row>
                                                        <Col xs={12}>
                                                            <FormGroup className="verification-2 mb-3">
                                                                <Label
                                                                    htmlFor="digit1-input"
                                                                    className="visually-hidden"
                                                                >
                                                                    TOTP
                                                                </Label>
                                                                <AuthCode
                                                                    characters={6}
                                                                    className="form-control form-control-lg text-center"
                                                                    inputStyle={{
                                                                        width: "50px",
                                                                        height: "calc(1.5em + 1rem + 2px)",
                                                                        padding: ".5rem 1rem",
                                                                        borderRadius: "8px",
                                                                        fontSize: "12px",
                                                                        textAlign: "center",
                                                                        marginTop: "5px",
                                                                        marginRight: "10px",
                                                                        border: "1px solid #ced4da",
                                                                        textTransform: "uppercase",
                                                                        borderRadius: ".4rem"
                                                                    }}
                                                                    onChange={data => { Setotp(data) }}
                                                                    value={totp}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                </div>

                                                <div className="mt-4">
                                                    <button type="submit" className="btn btn-success w-md">
                                                        Confirm
                                                    </button>
                                                </div>
                                            </Form>
                                        </div>
                                    </div>

                                    <div className="mt-4 mt-md-5 text-center">
                                        <p className="mb-0">
                                            © {new Date().getFullYear()} Algolingo. Crafted with{" "}
                                            <i className="mdi mdi-heart text-danger"></i> by
                                            Seventicks Software
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    );
};

export default Totp;
