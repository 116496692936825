import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

import { ADD_BROKER, GET_AUTHENTICATION_DATA, GET_BROKER_DATA, GET_MASTER_DATA, LINK_MASTER, GENERATE_TOKEN, VALIDATE_TOKEN } from "./actionTypes";
import { addBrokerSuccessful, addBrokerFailed, getAuthenticationDataSuccessful, getAuthenticationDataFailed, getBrokerDataSuccessful, getBrokerDataFailed, getMasterDataSuccessful, getMasterDataFailed, linkMasterSuccessful, linkMasterFailed, generateTokenSuccessful, generateTokenFailed, validateTokenSuccessful, validateTokenFailed } from "./actions";

import {
  addBrokerCall, getAuthenticationDataCall, getBrokerDataCall, getMasterDataCall, linkMasterCall,
  generateTokenCall, validateTokenCall,
} from "../../../helpers/backend_helper";


function* addBroker({ payload: { user, history } }) {
  try {
    var input = (user == undefined) ? {} : user;
    var authUser = localStorage.getItem("authUser");
    if (authUser) {
      authUser = atob(authUser);
    }
    input.email = JSON.parse(authUser).email;

    //console.log("input=" + JSON.stringify(input));

    const output = yield call(addBrokerCall, input);

    //console.log("output=" + JSON.stringify(output));

    if (output.result == "success") {
      yield put(addBrokerSuccessful(output.message))
    }
    else {
      yield put(addBrokerFailed(output.message))
    }
  } catch (error) {
    yield put(addBrokerFailed(error.message));
  }
}

function* getAuthenticationData({ payload: { user, history } }) {
  try {
    var input = (user == undefined) ? {} : user;
    var authUser = localStorage.getItem("authUser");
    if (authUser) {
      authUser = atob(authUser);
    }
    input.email = JSON.parse(authUser).email;

    //console.log("input=" + JSON.stringify(input));

    const output = yield call(getAuthenticationDataCall, input);

    //console.log("output=" + JSON.stringify(output));

    // //console.log("input=" + JSON.stringify(input));
    // var output = {
    //   "result": "success",
    //   "data": {
    //     "defaultBroker": "alice",
    //     "userKey": "FISPJULF3NHDSFL",
    //     "masterKey": "D81BBUYB4FV9QQT",
    //     "followingMasterKey": "6MKEGHMTMYA9I1H",
    //   }
    // }
    // //console.log("output=" + JSON.stringify(output));
    if (output.result == "success") {
      yield put(getAuthenticationDataSuccessful(output.data))
    }
    else {
      yield put(getAuthenticationDataFailed(output.message))
    }
  } catch (error) {
    yield put(getAuthenticationDataFailed(error.message));
  }
}

function* getBrokerData({ payload: { user, history } }) {
  try {
    var input = (user == undefined) ? {} : user;
    var authUser = localStorage.getItem("authUser");
    if (authUser) { authUser = atob(authUser); }
    input.email = JSON.parse(authUser).email;

    //console.log("input=" + JSON.stringify(input));

    const output = yield call(getBrokerDataCall, input);

    //console.log("output=" + JSON.stringify(output));

    // //console.log("input=" + JSON.stringify(input));
    // // var output = {
    // //   "result": "success",
    // //   "data": [{
    // //     "broker": user.broker,
    // //     "brokerId": user.brokerId,
    // //     "fields": {
    // //       "clientId": "1",
    // //       "password": "2",
    // //       "pin": "3"
    // //     }
    // //   }]
    // // }
    // //console.log("output=" + JSON.stringify(output));
    if (output.result == "success") {
      yield put(getBrokerDataSuccessful(output.data))
    }
    else {
      yield put(getBrokerDataFailed(output.message))
    }
  } catch (error) {
    yield put(getBrokerDataFailed(error.message));
  }
}

function* getMasterData({ payload: { user, history } }) {
  try {
    var input = (user == undefined) ? {} : user;

    //console.log("input=" + JSON.stringify(input));

    const output = yield call(getMasterDataCall, input);

    //console.log("output=" + JSON.stringify(output));

    // //console.log("input=" + JSON.stringify(input));
    // var output = {};

    // if (input.masterKey == "6MKEGHMTMYA9I1H") {
    //   output = {
    //     "result": "success",
    //     "data": [{
    //       "name": "",
    //       "email": "93@gmail.com",
    //     }]
    //   }
    // }
    // else {
    //   output = {
    //     "result": "success",
    //     "data": null
    //   }
    // }

    // //console.log("output=" + JSON.stringify(output));
    if (output.result == "success") {
      yield put(getMasterDataSuccessful(output.data))
    }
    else {
      yield put(getMasterDataFailed(output.message))
    }
  } catch (error) {
    yield put(getMasterDataFailed(error.message));
  }
}

function* linkMaster({ payload: { user, history } }) {
  try {
    var input = (user == undefined) ? {} : user;

    var authUser = localStorage.getItem("authUser");
    if (authUser) { authUser = atob(authUser); }
    input.email = JSON.parse(authUser).email;

    //console.log("input=" + JSON.stringify(input));

    const output = yield call(linkMasterCall, input);

    //console.log("output=" + JSON.stringify(output));

    if (output.result == "success") {
      yield put(linkMasterSuccessful(output.message))
    }
    else {
      yield put(linkMasterFailed(output.message))
    }
  } catch (error) {
    yield put(linkMasterFailed(error.message));
  }
}

function* generateToken({ payload: { user, history } }) {
  try {
    var input = (user == undefined) ? {} : user;

    var authUser = localStorage.getItem("authUser");
    if (authUser) { authUser = atob(authUser); }
    input.email = JSON.parse(authUser).email;

    //console.log("input=" + JSON.stringify(input));

    const output = yield call(generateTokenCall, input);

    //console.log("output=" + JSON.stringify(output));

    if (output.result == "success") {
      if (output.type == "redirect") {
        window.location.href = output.url;
      } else {
        yield put(generateTokenSuccessful(output.message))
      }
    }
    else {
      yield put(generateTokenFailed(output.message))
    }
  } catch (error) {
    yield put(generateTokenFailed(error.message));
  }
}

function* validateToken({ payload: { user, history } }) {
  try {
    var input = (user == undefined) ? {} : user;

    var authUser = localStorage.getItem("authUser");
    if (authUser) { authUser = atob(authUser); }
    input.email = JSON.parse(authUser).email;

    //console.log("input=" + JSON.stringify(input));

    const output = yield call(validateTokenCall, input);

    //console.log("output=" + JSON.stringify(output));

    if (output.result == "success") {
      yield put(validateTokenSuccessful(output.message))
    }
    else {
      yield put(validateTokenFailed(output.message))
    }
  } catch (error) {
    yield put(validateTokenFailed(error.message));
  }
}

function* authenticationSaga() {
  yield takeEvery(ADD_BROKER, addBroker);
  yield takeEvery(GET_AUTHENTICATION_DATA, getAuthenticationData);
  yield takeEvery(GET_BROKER_DATA, getBrokerData);
  yield takeEvery(GET_MASTER_DATA, getMasterData);
  yield takeEvery(LINK_MASTER, linkMaster);
  yield takeEvery(GENERATE_TOKEN, generateToken);
  yield takeEvery(VALIDATE_TOKEN, validateToken);
}

export default authenticationSaga;
