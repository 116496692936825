import React, { useEffect, useState } from "react"

//redux
import { useSelector, useDispatch } from "react-redux";

import { Container, Row, Col, Card, CardBody, CardTitle, CardSubtitle, Badge, Collapse } from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

import { Link } from "react-router-dom";

import classnames from "classnames";

import { API_URL } from "helpers/api_helper";

// actions
import { getAuthenticationData, getFollowRequests, followRequestsEvents, followClientsEvents, followClientsEventsSuccessful, followClientsEventsFailed, followClientsRenewal } from "../../../store/actions";

import axios from "axios";

const MyClients = props => {

  //meta title
  document.title = "My Clients | Algolingo ";

  const dispatch = useDispatch();

  var today = new Date(Date.now());

  today.setHours(0, 0, 0, 0);

  const { apiSuccess, apiError, followRequestsData, authenticationData } = useSelector(state => ({
    apiSuccess: state.MyClients.apiSuccess,
    apiError: state.MyClients.apiError,
    followRequestsData: state.MyClients.followRequestsData,
    authenticationData: state.Authentication.authenticationData,
  }
  ));

  const [col1, setcol1] = useState(true);
  const [col2, setcol2] = useState(false);

  const [page, setpage] = useState(0);

  const [searchData, setsearchData] = useState("");

  const [followClientsData, setfollowClientsData] = useState(null);

  const t_col1 = () => {
    setcol1(!col1);
    setcol2(false);
  };

  const t_col2 = () => {
    setcol2(!col2);
    setcol1(false);
  };

  useEffect(() => {
    setTimeout(() => { dispatch(getFollowRequests()); LoadData(searchData, page); }, 500);
  }, []);

  useEffect(() => {
    dispatch(followClientsEventsSuccessful(null));
    dispatch(followClientsEventsFailed(null));
  }, []);

  useEffect(() => {
    setTimeout(() => dispatch(getAuthenticationData()), 500);
  }, []);


  useEffect(() => {
    if (apiSuccess != null) {
      setTimeout(() => dispatch(followClientsEventsSuccessful(null)), 1000)
    }
  }, [apiSuccess]);

  useEffect(() => {
    if (apiError != null) {
      setTimeout(() => dispatch(followClientsEventsFailed(null)), 1000)
    }
  }, [apiError]);

  function CreatePayment(name, email, mobile, userKey, followingMasterKey, masterEmail, service) {

    // var test = name + "-" + email + "-" + mobile + "-" + userKey + "-" + followingMasterKey + "-" + masterEmail
    // alert(test);

    var obj = null;

    if (service == "copytrader") {

      var confirm = window.confirm("Do you really want to extend the  Copier subscription of this client?");
      if (confirm) {

        var amount = authenticationData.planAmount || 0;

        if (amount > 0) {

          var random_id = Math.floor((Math.random() * 1000) + 1);

          obj = {
            "customer_id": "cus" + "-" + mobile + "-" + random_id,
            "customer_email": email,
            "customer_phone": mobile,
            "order_id": "ord" + "-" + mobile + "-" + random_id,
            "order_amount": amount,
            "masterEmail": masterEmail,
            "order_tags": {
              "service": service.toString(),
              "name": name.toString(),
              "email": email.toString(),
              "mobile": mobile.toString(),
              "userKey": userKey.toString(),
              "followingMasterKey": followingMasterKey.toString(),
              "masterEmail": masterEmail.toString(),
              "actual_amount": amount.toString()
            }
          }

        }

      }
    }
    else if (service == "bridge") {
      var confirm = window.confirm("Do you really want to extend the Mt4 bridge subscription of this client?");
      if (confirm) {

        var amount = authenticationData.bridgeAmount;

        var random_id = Math.floor((Math.random() * 1000) + 1);

        obj = {
          "customer_id": "cus" + "-" + mobile + "-" + random_id,
          "customer_email": email,
          "customer_phone": mobile,
          "order_id": "ord" + "-" + mobile + "-" + random_id,
          "order_amount": amount,
          "masterEmail": masterEmail,
          "order_tags": {
            "service": service.toString(),
            "email": email.toString(),
            "userKey": userKey.toString(),
            "actual_amount": amount.toString()
          }
        }

      }
    }
    else if (service == "master") {
      var confirm = window.confirm("Are you Sure you want to change the role from Client to master?");
      if (confirm) {

        var amount = authenticationData.masterAmount;

        var random_id = Math.floor((Math.random() * 1000) + 1);

        var obj = {
          "customer_id": "cus" + "-" + mobile + "-" + random_id,
          "customer_email": email,
          "customer_phone": mobile,
          "order_id": "ord" + "-" + mobile + "-" + random_id,
          "order_amount": amount,
          "masterEmail": masterEmail,
          "order_tags": {
            "service": service.toString(),
            "email": email.toString(),
            "actual_amount": amount.toString()
          }
        }


      }
    }

    if (obj != null) {

      const headers = {
        'Content-Type': 'application/json'
      };

      axios.post(API_URL + '/whitelabel/create-order', JSON.stringify(obj), { headers }).then(response => {

        //console.log(response) 

        var output = response.data;

        if (output.result == "success") {
          dispatch(followClientsEventsSuccessful(output.message))
          SearchData();
        }
        else {
          dispatch(followClientsEventsFailed(output.message));
        }

      });
    }

  }

  function SearchData() {
    //alert("searchData=" + searchData);
    LoadData(searchData, page);
  }

  function LoadData(search, no) {

    var authUser = localStorage.getItem("authUser");
    if (authUser) { authUser = atob(authUser); }
    var email = JSON.parse(authUser).email;

    var obj = {
      "email": email,
      "search": search,
      "page": no
    };

    //console.log("page=" + no);

    const headers = {
      'Content-Type': 'application/json'
    };

    axios.post(API_URL + '/user/getFollowClients', JSON.stringify(obj), { headers }).then(response => {

      //console.log(response) 

      var output = response.data;

      if (output.result == "success") {
        setfollowClientsData(output.data);
      }
      else {
        setfollowClientsData(null);
      }
    });
  }

  function CheckAuthenticationTokenExists(defaultBroker, authentication) {
    var filterAuthentication = authentication.filter(auth => {
      return auth.brokerId.toLowerCase().match(defaultBroker.toLowerCase()) && new Date(auth.tokenUpdated_at) > today
    });
    if (filterAuthentication.length > 0) return true;
    return false;
  }

  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const isMobile = width <= 768;

  function ShowServiceExpiryDate(data) {
    //debugger;
    if (data.service == "copytrader") {
      data.expiry_date = new Date(data.expired_at).toLocaleString();
      data.days_left = parseInt((new Date(data.expired_at) - new Date(Date.now())) / (1000 * 60 * 60 * 24))
    }
    else if (data.service == "bridge") {
      data.expiry_date = (data.bridge_subscriptions.length > 0) ? (new Date(data.bridge_subscriptions[0].expired_at).toLocaleString()) : (0);
      data.days_left = (data.bridge_subscriptions.length > 0) ? parseInt((new Date(data.bridge_subscriptions[0].expired_at) - new Date(Date.now())) / (1000 * 60 * 60 * 24)) : (0);
    } else {
      data.expiry_date = 0;
      data.days_left = 0;
    }
  }

  function ResetQR(email) {

    var obj = {
      "email": email,
    };

    //console.log("page=" + no);

    const headers = {
      'Content-Type': 'application/json'
    };

    axios.post(API_URL + '/user/reset-qr-key', JSON.stringify(obj), { headers }).then(response => {

      //console.log(response) 

      var output = response.data;

      if (output.result == "success") {
        dispatch(followClientsEventsSuccessful(output.message));
      }
      else {
        dispatch(followClientsEventsFailed(output.message));
      }
    });

  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>

          {apiSuccess && (
            <div
              className="alert alert-success text-center"
              role="alert">
              {apiSuccess}
            </div>
          )}

          {apiError && (
            <div
              className="alert alert-danger text-center"
              role="alert">
              {apiError}
            </div>
          )}


          <Row>
            <Col>
              <Card>
                <CardBody>

                  <Row className="mt-3 mb-3">
                    <Col md={6} className="mb-2">
                      <div className="d-flex flex-wrap gap-2">

                        <div className="btn-group">
                          <Link to="#" onClick={() => {
                            if (page > 0) {
                              LoadData(searchData, page - 1);
                              setpage(page - 1);
                            }
                          }} className="btn btn-outline-primary">Prev</Link>
                          <Link to="#" className="btn btn-outline-primary">{page + 1}</Link>
                          <Link to="#" onClick={() => {
                            LoadData(searchData, page + 1);
                            setpage(page + 1);
                          }} className="btn btn-outline-primary">Next</Link>
                        </div>
                      </div>
                    </Col>
                    <Col md={6} className="mb-2" style={isMobile == false ? { textAlign: 'right' } : {}}>
                      <div style={{ display: "inline-flex" }}>
                        <input value={searchData} onChange={(val) => {
                          if (val != "") {
                            setsearchData(val.target.value)
                          }
                        }}
                          onKeyPress={event => {
                            if (event.key === 'Enter') {
                              SearchData();
                            }
                          }}
                          className="form-control " type="text" placeholder="Search"></input>
                      </div>
                    </Col>
                  </Row>

                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                    >
                      <Table
                        id="tech-companies-1"
                        className="table table-striped table-bordered"
                      >
                        <Thead>
                          <Tr>
                            <Th>Name</Th>
                            <Th data-priority="1">Email</Th>
                            <Th data-priority="2">Mobile</Th>
                            <Th data-priority="3">Auth token</Th>
                            <Th data-priority="4">Action</Th>
                            <Th data-priority="5">Services</Th>
                            <Th data-priority="6">Service Expiry</Th>
                            <Th data-priority="7">Days Left</Th>
                            <Th data-priority="7">Reset QR</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {followClientsData &&

                            followClientsData.map((data, key) => (
                              <Tr key={key}>
                                <Td>
                                  {data.name}
                                </Td>
                                <Td> {data.email}</Td>
                                <Td> {data.mobile}</Td>
                                <Td>
                                  {
                                    (data.users && data.users.length > 0 && data.users[0].authentication && data.users[0].authentication.length > 0) ?
                                      (
                                        CheckAuthenticationTokenExists(data.users[0].defaultBroker, data.users[0].authentication) == true ?
                                          (<Badge color="success" style={{ "padding": "8px 12px 8px 12px", "textAlign": "left" }}>Valid</Badge>)
                                          :
                                          (<Badge color="danger" style={{ "padding": "8px 12px 8px 12px", "textAlign": "left" }}>Expired</Badge>)
                                      )
                                      :
                                      (<Badge color="dark" style={{ "padding": "8px 12px 8px 12px", "textAlign": "left" }}>No Token</Badge>)
                                  }

                                </Td>
                                <Td>
                                  {data.active == "false" ? (
                                    <button
                                      type="button"
                                      className="btn btn-success me-2" onClick={() => { dispatch(followClientsEvents({ "userKey": data.userKey, "event": "start" }, props.history)); setTimeout(() => { LoadData(searchData, page); }, 500); }}
                                    >
                                      <i className="bx bx-check-double font-size-16 align-middle"></i>{" "}

                                    </button>) : (
                                    <button
                                      type="button"
                                      className="btn btn-warning me-2" onClick={() => { dispatch(followClientsEvents({ "userKey": data.userKey, "event": "stop" }, props.history)); setTimeout(() => { LoadData(searchData, page); }, 500); }}
                                    >
                                      <i className="bx bx-block font-size-16 align-middle"></i>{" "}

                                    </button>)}
                                  <button
                                    type="button"
                                    className="btn btn-danger" onClick={() => { dispatch(followClientsEvents({ "userKey": data.userKey, "event": "delete" }, props.history)); setTimeout(() => { LoadData(searchData, page); }, 500); }}
                                  >
                                    <i className="mdi mdi-trash-can font-size-16 align-middle"></i>{" "}

                                  </button>
                                </Td>
                                <Td>
                                  <select className="form-select" defaultValue={""} onChange={e => {
                                    //alert(e.target.value);
                                    data.service = e.target.value;
                                    ShowServiceExpiryDate(data);
                                  }}>
                                    <option value="">Select Service</option>
                                    <option value="copytrader">COPIER</option>
                                    <option value="bridge">BRIDGE</option>
                                    <option value="master">MASTER</option>
                                  </select>
                                </Td>
                                <Td>
                                  {data.expiry_date || 0}
                                </Td>
                                <Td>
                                  {data.days_left || 0}
                                </Td>
                                
                                <Td>
                                      <button
                                        type="button"
                                        className="btn btn-info me-2" onClick={() => { ResetQR(data.email) }}
                                      >
                                        <i className="bx bx-rotate-left font-size-16 align-middle"></i>{" "}

                                      </button>
                                    </Td>
                              </Tr>
                            ))}
                        </Tbody>
                      </Table>
                    </div>
                  </div>

                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div >
    </React.Fragment >
  )
}

export default MyClients
