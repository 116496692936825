import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { isEmpty, map } from "lodash";
import moment from "moment";
import {
  Button,
  Card,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  UncontrolledDropdown,
  UncontrolledTooltip,
  Tooltip,
  CardBody,
  CardFooter,
  CardTitle,
  Modal,
} from "reactstrap";
import classnames from "classnames";

//Import Scrollbar
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

import TradingPopup from "pages/TradingPopup";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import images from "assets/images";

//Import Images
import maintenance from "assets/images/maintenance.svg"
import logo from "assets/images/logo-dark.png"

import {
  placeOrders,
  placeOrdersSuccessful,
  placeOrdersFailed,
  addMarketwatch,
  deleteMarketwatch,
  resetApiEvent,
} from "store/actions";

//redux
import { useSelector, useDispatch } from "react-redux";

var tradeObject = {
  symbol: "",
  transactionType: "",
  qty: "0",
  sessionType: "DAY",
  tradeType: "NRML",
  productType: "MARKET",
  orderType: "REGULAR",
  price: "0",
  triggerPrice: "0",
  targetPrice: "0",
  stopPrice: "0",
  token: "0",
  exchange: ""
}

import axios from "axios";

import { API_URL } from "helpers/api_helper";

import { socket_io, AddToMarketDataSocketInstruments } from "../../../common/sockets"

import { ChartURL } from "../../../common/data/domains"

let ajaxRequest = null;

const MarketWatch = props => {

  //meta title
  document.title = "MarketWatch | Algolingo ";

  const dispatch = useDispatch();

  const { apiSuccess, apiError, apiEvent } = useSelector(state => ({
    apiSuccess: state.Trading.apiSuccess,
    apiError: state.Trading.apiError,
    apiEvent: state.Trading.apiEvent,
  }
  ));

  const [showSymbolEvent, setShowSymbolEvent] = useState({ id: 0, show: false });

  const [chartUrl, setchartUrl] = useState("");

  const [show_popup, set_show_popup] = useState(false);

  const [SearchSymbolText, SetSearchSymbolText] = useState("");

  const [TickData, SetTickData] = useState(null);

  const [modal_header_color, setmodal_header_color] = useState("bg-info text-white");

  const [modal_header_class, setmodal_header_class] = useState("btn-outline-info");

  const [orderSymbol, setorderSymbol] = useState("");

  const [transactionType, settransactionType] = useState("BUY");

  const [sessionType, setsessionType] = useState("DAY");

  const [tradeType, settradeType] = useState("NRML");

  const [productType, setproductType] = useState("MARKET");

  const [orderType, setorderType] = useState("REGULAR");

  const [qty, setqty] = useState("0");

  const [price, setprice] = useState("0");

  const [triggerPrice, settriggerPrice] = useState("0");

  const [targetPrice, settargetPrice] = useState("0");

  const [stopPrice, setstopPrice] = useState("0");

  const [qtyvalid, setqtyvalid] = useState(true);
  const [pricevalid, setpricevalid] = useState(true);
  const [triggerPricevalid, settriggerPricevalid] = useState(true);
  const [targetPricevalid, settargetPricevalid] = useState(true);
  const [stopPricevalid, setstopPricevalid] = useState(true);

  const [authenticationData, SetauthenticationData] = useState(null)

  const [brokerId, SetbrokerId] = useState(null)

  const [marketwatchData, SetmarketwatchData] = useState(null);

  const [symbolsData, SetsymbolsData] = useState(null);

  function UpdateUrl(exchange, token) {
    // //console.log("exchange=" + exchange+",token="+token);
    // if (exchange != undefined) {
    //   // var url = "https://masterswift-beta.mastertrust.co.in/mobilecharts/?color=%23fff&exchange=" + exchange +
    //   //   "&exchanges_subscribed=NSE%2CBSE%2CNFO%2CMCX%2CCDS&login_id=1&login_token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJibGFja2xpc3Rfa2V5IjoiTVQwMTE2MjovSzUvY0VJY1BMbUdwbUVGbEZNRUZ3IiwiY2xpZW50X2lkIjoiTVQwMTE2MiIsImNsaWVudF90b2tlbiI6IkJ1ZSt1VllTbE1TdDM0VE5KTjZMRmciLCJkZXZpY2UiOiJ3ZWIiLCJleHAiOjE2ODQ0OTA0MTMxODB9.3TEfTMLIT293bJ7pL5tvFQizftp2NV_PPeY4v4-0oaw&token=" + token + "&web=true";
    //   // setchartUrl(url);
    //   //console.log("url=" + url);
    //   ChartURL(brokerId, exchange, token, function (url) {
    //     console.log("url=" + url);
    //     setchartUrl(url);
    //   });
    //}
  }

  useEffect(() => {
    getMarketwatch();
  }, []);

  useEffect(() => {
    socket_io.on("socket-marketdata", (result) => {
      //console.log("data=" + JSON.stringify(result));

      var tickdata_record = localStorage.getItem("tickdata_record");
      if (tickdata_record) {

        tickdata_record = JSON.parse(tickdata_record);

        var index = tickdata_record.findIndex(function (e) { return e.instrumentToken == result.instrumentToken });

        //console.log("index=" + index);

        if (index == -1) {
          tickdata_record.push(result);
          tickdata_record = JSON.stringify(tickdata_record);
          localStorage.setItem("tickdata_record", tickdata_record);
        }
        else {
          tickdata_record[index] = result;
          tickdata_record = JSON.stringify(tickdata_record);
          localStorage.setItem("tickdata_record", tickdata_record);
        }

      }
      else {
        var input = [];
        input.push(result)
        input = JSON.stringify(input);
        localStorage.setItem("tickdata_record", input);
      }

      SetTickData(result);
    });
  }, []);

  useEffect(() => {
    setTimeout(function () {
      getMarketwatch();
      //console.log("inside socket setTimeout");
      collectInstrumentTokensFromUI();
    }, 2000);
  }, []);


  useEffect(() => {
    if (TickData != null) {
      var tickdata_record = localStorage.getItem("tickdata_record");
      if (tickdata_record) {
        tickdata_record = JSON.parse(tickdata_record);
        for (var i = 0; i < tickdata_record.length; i++) {
          fillInstrumentsTickData(tickdata_record[i]);
        }
      }
    }
  }, [TickData]);

  useEffect(() => {
    //console.log("outside socket");
    collectInstrumentTokensFromUI();
  }, [marketwatchData, symbolsData]);

  function collectInstrumentTokensFromUI() {

    //console.log("1");

    var instrument_req = getInstrumentsTokesFromCards();
    if (instrument_req != null) {
      if (instrument_req.v.length > 0) {
        //console.log("instrument_req=" + JSON.stringify(instrument_req));
        AddToMarketDataSocketInstruments(instrument_req.v);
      }
    }

  }

  useEffect(() => {
    if (apiEvent == "RELOAD") {
      getMarketwatch();
      dispatch(resetApiEvent());
    }
  }, [apiEvent]);


  function getAuthenticationData() {

    var authUser = localStorage.getItem("authUser");
    if (authUser) { authUser = atob(authUser); }
    var email = JSON.parse(authUser).email;

    var obj = {
      "email": email
    }

    const headers = {
      'Content-Type': 'application/json'
    };

    axios.post(API_URL + '/user/getAuthenticationData', JSON.stringify(obj), { headers }).then(response => {

      //console.log(response) 

      var output = response.data;

      if (output.result == "success") {

        SetauthenticationData(output.data);

      }
      else {
        callback(null);
      }

    });

  }

  function fillInstrumentsTickData(data) {

    //console.log("data.instrumentToken=" + data.instrumentToken);

    // if (marketwatchData)
    //   console.log("marketwatchData empty");

    // if (symbolsData)
    //   console.log("symbolsData empty");

    data.lastTradedPrice = data.lastTradedPrice / 100;
    data.openPrice = data.openPrice / 100;
    data.highPrice = data.highPrice / 100;
    data.lowPrice = data.lowPrice / 100;
    data.closePrice = data.closePrice / 100;

    if (marketwatchData) {
      for (var i = 0; i < marketwatchData.length; i++) {

        //console.log("marketwatchData.code=" + marketwatchData[i].code + ",data.instrumentToken=" + data.instrumentToken);

        if (marketwatchData[i].code == data.instrumentToken) {

          marketwatchData[i].pastprice = marketwatchData[i].price || 0;
          marketwatchData[i].price = data.lastTradedPrice;

          if (parseFloat(marketwatchData[i].price) > parseFloat(marketwatchData[i].pastprice)) {
            marketwatchData[i].trend = "UP";
          }
          else if (parseFloat(marketwatchData[i].price) < parseFloat(marketwatchData[i].pastprice)) {
            marketwatchData[i].trend = "DOWN";
          }

          // if (marketwatchData)
          //   dispatch(getMarketwatchSuccessful(marketwatchData));
          // else if (symbolsData)
          //   dispatch(getSymbolsSuccessful(marketwatchData));
        }
      }
    }

    if (symbolsData) {
      for (var i = 0; i < symbolsData.length; i++) {

        //console.log("symbolsData.code=" + symbolsData[i].code + ",data.instrumentToken=" + data.instrumentToken);

        if (symbolsData[i].code == data.instrumentToken) {

          symbolsData[i].pastprice = symbolsData[i].price || 0;
          symbolsData[i].price = data.lastTradedPrice;

          if (parseFloat(symbolsData[i].price) > parseFloat(symbolsData[i].pastprice)) {
            symbolsData[i].trend = "UP";
          }
          else if (parseFloat(symbolsData[i].price) < parseFloat(symbolsData[i].pastprice)) {
            symbolsData[i].trend = "DOWN";
          }

          // if (marketwatchData)
          //   dispatch(getMarketwatchSuccessful(symbolsData));
          // else if (symbolsData)
          //   dispatch(getSymbolsSuccessful(symbolsData));
        }
      }
    }

  }

  function getInstrumentsTokesFromCards() {

    //var data = (symbolsData == null) ? marketwatchData : symbolsData;

    var data = null;

    if (SearchSymbolText != "") {
      data = symbolsData;
    }
    else {
      data = marketwatchData;
    }

    // console.log("symbolsData1=" + JSON.stringify(symbolsData));

    // console.log("marketwatchData1=" + JSON.stringify(marketwatchData));

    if (data == null)
      return null;

    var instruments = { "a": "subscribe", "v": [], "m": "marketdata" };

    //console.log(JSON.stringify(data));

    for (var i = 0; i < data.length; i++) {

      var exchange_code = parseInt(getExchangeCode(data[i].exchange).code);

      var instrument_token = parseInt(data[i].code);

      instruments.v.push([exchange_code, instrument_token]);

    }

    return instruments;
  }

  function getExchangeCode(exchange) {
    var result = {}
    result.exchange = exchange;
    if (exchange == "NSE") {
      result.code = 1;
      result.multiplier = 100;
    }
    else if (exchange == "NFO") {
      result.code = 2;
      result.multiplier = 100;
    }
    else if (exchange == "CDS") {
      result.code = 3;
      result.multiplier = 10000000;
    }
    else if (exchange == "MCX") {
      result.code = 4;
      result.multiplier = 100;
    }
    else if (exchange == "BSE") {
      result.code = 6;
      result.multiplier = 100;
    }
    else if (exchange == "BFO") {
      result.code = 7;
      result.multiplier = 100;
    }

    return result;

  }

  function ResetVariables() {

    setorderSymbol("");
    settransactionType("BUY");
    setsessionType("DAY");
    settradeType("NRML");
    setproductType("MARKET");
    setorderType("REGULAR");
    setqty("0");
    setprice("0");
    settriggerPrice("0");
    settargetPrice("0");
    setstopPrice("0");

    tradeObject = {
      symbol: "",
      transactionType: "",
      qty: "0",
      sessionType: "DAY",
      tradeType: "NRML",
      productType: "MARKET",
      orderType: "REGULAR",
      price: "0",
      triggerPrice: "0",
      targetPrice: "0",
      stopPrice: "0",
      token: "0",
      exchange: ""
    }
  }

  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const isMobile = width <= 768;

  function handleScroll() {
    if (isMobile) {
      window.scroll({
        top: document.body.offsetHeight,
        left: 0,
        behavior: 'smooth',
      });
    }
  }

  useEffect(() => {
    if (apiSuccess != null) {
      setTimeout(() => dispatch(placeOrdersSuccessful(null)), 3000)
    }
  }, [apiSuccess]);

  useEffect(() => {
    if (apiError != null) {
      setTimeout(() => dispatch(placeOrdersFailed(null)), 3000)
    }
  }, [apiError]);

  const toggleShow = () => set_show_popup(p => !p);

  function getMarketwatch() {

    var authUser = localStorage.getItem("authUser");
    if (authUser) { authUser = atob(authUser); }
    var email = JSON.parse(authUser).email;

    var obj = {
      "email": email
    };

    const headers = {
      'Content-Type': 'application/json'
    };

    axios.post(API_URL + '/trading/getMarketwatch', JSON.stringify(obj), { headers }).then(response => {

      //console.log(response) 

      var output = response.data;

      if (output.result == "success") {
        SetmarketwatchData(null);
        SetmarketwatchData(output.data);
      }
      else {
        placeOrdersFailed(output.message);
      }

    });

  }

  function searchSymbols(symbol) {
    //console.log(data)
    if (symbol.length > 2) {
      SetmarketwatchData(null);
      getSymbols(symbol);
    }
    else {
      SetsymbolsData(null);
      getMarketwatch();
    }
  }

  useEffect(() => {
    searchSymbols(SearchSymbolText);
  }, [SearchSymbolText]);

  function getSymbols(symbol) {

    try {

      // cancel  previous ajax if exists
      if (ajaxRequest) {
        ajaxRequest.cancel();
      }

      var authUser = localStorage.getItem("authUser");
      if (authUser) { authUser = atob(authUser); }
      var email = JSON.parse(authUser).email;

      var obj = {
        "email": email,
        "symbol": symbol
      };

      const headers = {
        'Content-Type': 'application/json'
      };

      // creates a new token for upcomming ajax (overwrite the previous one)
      ajaxRequest = axios.CancelToken.source();

      axios.post(API_URL + '/trading/getSymbols', JSON.stringify(obj), { cancelToken: ajaxRequest.token, headers }).then(response => {

        //console.log(response) 
        var output = response.data;

        if (output.result == "success") {
          SetsymbolsData(output.data);
        }
        else {
          SetsymbolsData(null);
          placeOrdersFailed(output.message);
        }

      }).catch(function (err) {
        if (axios.isCancel(err)) {
          console.log('Previous request canceled, new request is send', err.message);
        } else {
          // handle error
        }
      });

    } catch (error) {
      if (axios.isCancel(error)) {
        // Handle request cancellation here
        console.log('Request canceled:', error.message);
      } else {
        // Handle other errors
        console.error('Error:', error);
      }
    }

  }

  useEffect(() => {
    if (marketwatchData && marketwatchData.length > 0) {

      SetsymbolsData(null);

    }
  }, [marketwatchData]);

  useEffect(() => {
    if (symbolsData && symbolsData.length > 0) {

      SetmarketwatchData(null);

    }
  }, [symbolsData]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>

          {apiSuccess && (
            <div
              className="alert alert-success text-center"
              role="alert">
              {apiSuccess}
            </div>
          )}

          {apiError && (
            <div
              className="alert alert-danger text-center"
              role="alert">
              {apiError}
            </div>
          )}

          {/* Render Breadcrumb */}
          <Breadcrumbs title="Algolingo" breadcrumbItem="MarketWatch" />

          <Row>
            <Col lg="12">
              <div className="d-lg-flex">
                <div className="chat-leftsidebar me-lg-4">
                  <div>

                    <div className="search-box chat-search-box">
                      <div className="position-relative">
                        <Input
                          value={SearchSymbolText}
                          id="search-symbol"
                          type="text"
                          className="form-control"
                          placeholder="Search..."
                          style={{ height: "40px" }}
                          onChange={(e) => {
                            SetSearchSymbolText(e.target.value);
                          }}
                        />
                        <i className="bx bx-search-alt search-icon" />
                      </div>
                    </div>

                    <div className="chat-leftsidebar-nav">

                      <TabContent activeTab="1" className="py-2">
                        <TabPane tabId="1">
                          <div>

                            <ul className="list-unstyled chat-list">
                              <PerfectScrollbar style={{ height: "600px" }}>


                                {marketwatchData &&
                                  (
                                    marketwatchData.map(symbol => (
                                      <li
                                        key={symbol.code} className="active mb-2"
                                      >
                                        <Link
                                          to="#"
                                        >
                                          <div style={{ "position": "relative" }} onMouseEnter={e => {
                                            setShowSymbolEvent({ id: symbol.code, show: true });
                                          }}
                                            onMouseLeave={e => {
                                              setShowSymbolEvent({ id: symbol.code, show: false })
                                            }}
                                          >
                                            <div className="d-flex">
                                              <div className="align-self-center me-3">
                                                <i
                                                  className={
                                                    symbol.trend === "UP"
                                                      ? "bx bxs-up-arrow text-success font-size-12"
                                                      : "bx bxs-down-arrow text-danger font-size-12"
                                                  }
                                                />
                                              </div>
                                              <div className="align-self-center me-3">
                                                <img
                                                  src={images["marketwatch"]}
                                                  className="rounded-circle avatar-xs"
                                                  alt=""
                                                />
                                              </div>

                                              <div className="flex-grow-1 overflow-hidden">
                                                <h5 className="text-truncate font-size-14 mb-1">
                                                  {symbol.trading_symbol}
                                                </h5>
                                                <p className="text-truncate mb-0">
                                                  {symbol.symbol}
                                                </p>
                                              </div>
                                              <div className={(symbol.trend == "UP") ? "font-size-14 text-success" : "font-size-14 text-danger"}>
                                                {symbol.price || 0}
                                              </div>
                                            </div>

                                            {showSymbolEvent.id == symbol.code && showSymbolEvent.show && (

                                              <div style={{
                                                textAlign: "right",
                                                position: "absolute",
                                                right: 0,
                                                top: 0,
                                                backgroundColor: "#f9f9f9"
                                              }}>
                                                <button type="button" className="btn btn-outline-success waves-effect waves-light me-1" onClick={() => {
                                                  setmodal_header_color("bg-info text-white");
                                                  setmodal_header_class("btn-outline-info");
                                                  ResetVariables();
                                                  settransactionType("BUY");
                                                  setorderSymbol(symbol.trading_symbol);
                                                  tradeObject.symbol = symbol.trading_symbol;
                                                  tradeObject.transactionType = "BUY";
                                                  tradeObject.token = symbol.code;
                                                  tradeObject.exchange = symbol.exchange;
                                                  if (tradeObject.exchange == "NSE") {
                                                    settradeType("CNC");
                                                    tradeObject.tradeType = "CNC";
                                                  }
                                                  set_show_popup(true);
                                                }}>
                                                  B
                                                </button>
                                                <button type="button" className="btn btn-outline-danger waves-effect waves-light me-1" onClick={() => {
                                                  setmodal_header_color("bg-danger text-white");
                                                  setmodal_header_class("btn-outline-danger");
                                                  ResetVariables();
                                                  settransactionType("SELL");
                                                  setorderSymbol(symbol.trading_symbol);
                                                  tradeObject.symbol = symbol.trading_symbol;
                                                  tradeObject.transactionType = "SELL";
                                                  tradeObject.token = symbol.code;
                                                  tradeObject.exchange = symbol.exchange;
                                                  if (tradeObject.exchange == "NSE") {
                                                    settradeType("CNC");
                                                    tradeObject.tradeType = "CNC";
                                                  }
                                                  set_show_popup(true);
                                                }}>
                                                  S
                                                </button>
                                                <button type="button" className="btn btn-outline-warning waves-effect waves-light me-1" onClick={() => {
                                                  UpdateUrl(symbol.exchange, symbol.code);
                                                  handleScroll();
                                                }}>
                                                  <i className="mdi mdi-chart-areaspline d-block font-size-14"></i>
                                                </button>
                                                {symbolsData ? (
                                                  <button type="button" className="btn btn-outline-success waves-effect waves-light me-1" onClick={() => { dispatch(addMarketwatch({ "token": symbol.code }, props.history)); }}>
                                                    <i className="mdi mdi-plus d-block font-size-14"></i>
                                                  </button>
                                                ) : (
                                                  <button type="button" className="btn btn-outline-primary waves-effect waves-light me-1" onClick={() => { dispatch(deleteMarketwatch({ "token": symbol.code }, props.history)); }}>
                                                    <i className="mdi mdi-trash-can d-block font-size-14"></i>
                                                  </button>
                                                )}
                                              </div>
                                            )}

                                          </div>
                                        </Link>
                                      </li>
                                    ))
                                  )
                                }

                                {symbolsData &&
                                  (
                                    symbolsData.map(symbol => (
                                      <li
                                        key={symbol._id} className="active mb-2"
                                      >
                                        <Link
                                          to="#"
                                        >
                                          <div style={{ "position": "relative" }} onMouseEnter={e => {
                                            setShowSymbolEvent({ id: symbol.code, show: true });
                                          }}
                                            onMouseLeave={e => {
                                              setShowSymbolEvent({ id: symbol.code, show: false })
                                            }}
                                          >
                                            <div className="d-flex">
                                              <div className="align-self-center me-3">
                                                <i
                                                  className={
                                                    symbol.trend === "UP"
                                                      ? "bx bxs-up-arrow text-success font-size-12"
                                                      : "bx bxs-down-arrow text-danger font-size-12"
                                                  }
                                                />
                                              </div>
                                              <div className="align-self-center me-3">
                                                <img
                                                  src={images["marketwatch"]}
                                                  className="rounded-circle avatar-xs"
                                                  alt=""
                                                />
                                              </div>

                                              <div className="flex-grow-1 overflow-hidden">
                                                <h5 className="text-truncate font-size-14 mb-1">
                                                  {symbol.trading_symbol}
                                                </h5>
                                                <p className="text-truncate mb-0">
                                                  {symbol.symbol}
                                                </p>
                                              </div>
                                              <div className={(symbol.trend == "UP") ? "font-size-14 text-success" : "font-size-14 text-danger"}>
                                                {symbol.price || 0}
                                              </div>
                                            </div>

                                            {showSymbolEvent.id == symbol.code && showSymbolEvent.show && (

                                              <div style={{
                                                textAlign: "right",
                                                position: "absolute",
                                                right: 0,
                                                top: 0,
                                                backgroundColor: "#f9f9f9"
                                              }}>
                                                <button type="button" className="btn btn-outline-success waves-effect waves-light me-1" onClick={() => {
                                                  setmodal_header_color("bg-info text-white");
                                                  setmodal_header_class("btn-outline-info");
                                                  ResetVariables();
                                                  settransactionType("BUY");
                                                  setorderSymbol(symbol.trading_symbol);
                                                  tradeObject.symbol = symbol.trading_symbol;
                                                  tradeObject.transactionType = "BUY";
                                                  tradeObject.token = symbol.code;
                                                  tradeObject.exchange = symbol.exchange;
                                                  if (tradeObject.exchange == "NSE") {
                                                    settradeType("CNC");
                                                    tradeObject.tradeType = "CNC";
                                                  }
                                                  set_show_popup(true);
                                                }}>
                                                  B
                                                </button>
                                                <button type="button" className="btn btn-outline-danger waves-effect waves-light me-1" onClick={() => {
                                                  setmodal_header_color("bg-danger text-white");
                                                  setmodal_header_class("btn-outline-danger");
                                                  ResetVariables();
                                                  settransactionType("SELL");
                                                  setorderSymbol(symbol.trading_symbol);
                                                  tradeObject.symbol = symbol.trading_symbol;
                                                  tradeObject.transactionType = "SELL";
                                                  tradeObject.token = symbol.code;
                                                  tradeObject.exchange = symbol.exchange;
                                                  if (tradeObject.exchange == "NSE") {
                                                    settradeType("CNC");
                                                    tradeObject.tradeType = "CNC";
                                                  }
                                                  set_show_popup(true);
                                                }}>
                                                  S
                                                </button>
                                                <button type="button" className="btn btn-outline-warning waves-effect waves-light me-1" onClick={() => {
                                                  UpdateUrl(symbol.exchange, symbol.code);
                                                  handleScroll();
                                                }}>
                                                  <i className="mdi mdi-chart-areaspline d-block font-size-14"></i>
                                                </button>
                                                {symbolsData ? (
                                                  <button type="button" className="btn btn-outline-success waves-effect waves-light me-1" onClick={() => { dispatch(addMarketwatch({ "token": symbol.code }, props.history)); }}>
                                                    <i className="mdi mdi-plus d-block font-size-14"></i>
                                                  </button>
                                                ) : (
                                                  <button type="button" className="btn btn-outline-primary waves-effect waves-light me-1" onClick={() => { dispatch(deleteMarketwatch({ "token": symbol.code }, props.history)); }}>
                                                    <i className="mdi mdi-trash-can d-block font-size-14"></i>
                                                  </button>
                                                )}
                                              </div>
                                            )}

                                          </div>
                                        </Link>
                                      </li>
                                    ))
                                  )
                                }


                              </PerfectScrollbar>
                            </ul>
                          </div>
                        </TabPane>

                      </TabContent>
                    </div>
                  </div>
                </div>
                <div className="w-100 user-chat" style={{ position: "relative", height: "600px" }}>
                  <h4 style={{ marginTop: "-43px", textTransform: "uppercase", fontWeight: 600 }} className="mb-0 font-size-16 mb-3">CHART WINDOW</h4>
                  <Card>
                    <section>
                      <Container style={{ padding: "0px" }}>
                        <Row>
                          <Col xs="12">
                            <div className="home-wrapper">

                              <iframe height="650" width="850" src="https://ssltvc.investing.com/?pair_ID=8985&height=720&width=1280&interval=300&plotStyle=candles&domain_ID=56&lang_ID=56&timezone_ID=20"></iframe>

                            </div>
                          </Col>
                        </Row>
                      </Container>
                    </section>

                  </Card>
                </div>
              </div>
            </Col>

            <TradingPopup tradeObject={tradeObject} show_popup={show_popup} toggleShow={toggleShow} ModifyLimit={false}> </TradingPopup>

          </Row >
        </Container >
      </div >
    </React.Fragment >
  );
};

export default MarketWatch;
