import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
    Button,
    Card,
    Col,
    Container,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Form,
    FormGroup,
    Input,
    InputGroup,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
    UncontrolledDropdown,
    UncontrolledTooltip,
    Tooltip,
    CardBody,
    CardFooter,
    CardTitle,
    Modal,
} from "reactstrap";

import { API_URL } from "helpers/api_helper";

import axios from "axios";

import OptionWritingModal from '../strategies/modal/option_writing';
import TimeRangeBreakoutModal from '../strategies/modal/time_range_breakout';
import RSIModal from '../strategies/modal/rsi';
import MACDModal from '../strategies/modal/macd';
import SUPERTRENDModal from '../strategies/modal/st';
import HALFTRENDModal from '../strategies/modal/ht';
import BBModal from '../strategies/modal/bb';

import OptionWritingGrid from '../strategies/grid/option_writing';
import TimeRangeBreakoutGrid from '../strategies/grid/time_range_breakout';
import RSIGrid from '../strategies/grid/rsi';
import MACDGrid from '../strategies/grid/macd';
import SUPERTRENDGrid from '../strategies/grid/st';
import HALFTRENDGrid from '../strategies/grid/ht';
import BBGrid from '../strategies/grid/bb';

var strategy_type = "NORMAL";

import { socket_io, AddToMarketDataSocketInstruments } from "../../../../common/sockets"

const CustomStrategies = props => {

    //meta title
    document.title = "CustomStrategies | Algolingo ";


    const [DefaultStrategies, SetDefaultStrategies] = useState(null);

    function GetDefaultStrategies() {
        var authUser = localStorage.getItem("authUser");
        if (authUser) { authUser = atob(authUser); }
        var email = JSON.parse(authUser).email;

        var obj = {
            "email": email
        };

        const headers = {
            'Content-Type': 'application/json'
        };

        axios.get(API_URL + '/user/getStrategies', JSON.stringify(obj), { headers }).then(response => {

            //console.log(response) 

            var output = response.data;

            if (output.result == "success") {

                SetDefaultStrategies(output.data);

            }
        });
    }

    const [apiSuccess, SetapiSuccess] = useState(null)

    const [apiError, SetapiError] = useState(null)

    useEffect(() => {
        if (apiSuccess != null) {
            setTimeout(() => SetapiSuccess(null), 1000)
        }
    }, [apiSuccess]);

    useEffect(() => {
        if (apiError != null) {
            setTimeout(() => SetapiError(null), 1000)
        }
    }, [apiError]);

    useEffect(() => {
        GetDefaultStrategies();
    }, []);

    const [modal_standard, setmodal_standard] = useState(false);

    function tog_standard() {
        setmodal_standard(!modal_standard);
        removeBodyCss();
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    const [strategy, Setstrategy] = useState("");

    const [strategyID, SetstrategyID] = useState("");

    const [strategy_name, SetstrategyName] = useState("");

    const [Strategies, SetStrategies] = useState(null);

    const [editData, SeteditData] = useState(null);

    function AddStrategy(input) {

        var authUser = localStorage.getItem("authUser");
        if (authUser) { authUser = atob(authUser); }
        var email = JSON.parse(authUser).email;

        var obj = input;

        obj.email = email;

        const headers = {
            'Content-Type': 'application/json'
        };

        axios.post(API_URL + '/user/addCustomStrategies', JSON.stringify(obj), { headers }).then(response => {

            //console.log(response) 

            var output = response.data;

            if (output.result == "success") {

                SetapiSuccess(output.message);

                Setstrategy("");
                SetstrategyID("");
                SetstrategyName("");
                SeteditData(null);

                LoadStrategies();
            }
            else {
                SetapiError(output.message);
            }

        });

    }

    function UpdateStrategy(input) {

        var authUser = localStorage.getItem("authUser");
        if (authUser) { authUser = atob(authUser); }
        var email = JSON.parse(authUser).email;

        var obj = input;

        obj.email = email;

        const headers = {
            'Content-Type': 'application/json'
        };

        axios.post(API_URL + '/user/updateCustomStrategies', JSON.stringify(obj), { headers }).then(response => {

            //console.log(response) 

            var output = response.data;

            if (output.result == "success") {

                SetapiSuccess(output.message);

                Setstrategy("");
                SetstrategyID("");
                SetstrategyName("");
                SeteditData(null);

                LoadStrategies();
            }
            else {
                SetapiError(output.message);
            }

        });

    }

    function DeleteStrategy(strategyID) {

        if (window.confirm('Are You Sure Want To Delete ?')) {
            var authUser = localStorage.getItem("authUser");
            if (authUser) { authUser = atob(authUser); }
            var email = JSON.parse(authUser).email;

            var obj = {
                "email": email,
                "strategyID": strategyID
            };

            const headers = {
                'Content-Type': 'application/json'
            };

            axios.post(API_URL + '/user/deleteCustomStrategies', JSON.stringify(obj), { headers }).then(response => {

                //console.log(response) 

                var output = response.data;

                if (output.result == "success") {

                    SetapiSuccess(output.message);

                    LoadStrategies();

                }
                else {
                    SetapiError(output.message);
                }

            });
        }
    }

    function StrategyEvents(strategyID, event) {

        var authUser = localStorage.getItem("authUser");
        if (authUser) { authUser = atob(authUser); }
        var email = JSON.parse(authUser).email;

        var obj = {
            "email": email,
            "strategyID": strategyID,
            "event": event
        };

        const headers = {
            'Content-Type': 'application/json'
        };

        axios.post(API_URL + '/user/customStrategiesEvents', JSON.stringify(obj), { headers }).then(response => {

            //console.log(response) 

            var output = response.data;

            if (output.result == "success") {

                SetapiSuccess(output.message);

                LoadStrategies();

            }
            else {
                SetapiError(output.message);
            }

        });

    }

    function EditStrategy(item) {

        Setstrategy(item.strategy);
        SetstrategyID(item.strategyID);
        SetstrategyName(item.strategy_name);

        SeteditData(item);

        tog_standard();

        return;

    }

    function SquareoffStrategy(strategyID) {

        if (window.confirm('Are You Sure Want To Squareoff ?')) {
            var authUser = localStorage.getItem("authUser");
            if (authUser) { authUser = atob(authUser); }
            var email = JSON.parse(authUser).email;

            var obj = {
                "email": email,
                "strategyID": strategyID
            };

            const headers = {
                'Content-Type': 'application/json'
            };

            axios.post(API_URL + '/trading/squareoffStrategyOrder', JSON.stringify(obj), { headers }).then(response => {

                //console.log(response) 

                var output = response.data;

                if (output.result == "success") {

                    SetapiSuccess(output.message);

                    LoadStrategies();

                }
                else {
                    SetapiError(output.message);
                }

            });
        }

    }

    useEffect(() => {
        LoadStrategies();
    }, []);

    function LoadStrategies() {

        var authUser = localStorage.getItem("authUser");
        if (authUser) { authUser = atob(authUser); }
        var email = JSON.parse(authUser).email;

        var obj = {
            "email": email,
            "strategy_type": strategy_type
        };

        const headers = {
            'Content-Type': 'application/json'
        };

        axios.post(API_URL + '/user/getCustomStrategies', JSON.stringify(obj), { headers }).then(response => {

            //console.log(response)

            var output = response.data;

            if (output.result == "success") {

                SetStrategies(output.data);

                if (output.data) {
                    var instruments = { "a": "subscribe", "v": [], "m": "marketdata" };
                    var data = output.data;
                    for (var i = 0; i < data.length; i++) {
                        var exchange_code = parseInt(data[i].exchange_code);
                        var instrument_token = parseInt(data[i].symbol_code);
                        instruments.v.push([exchange_code, instrument_token]);
                    }
                    SetsocketInstruments(instruments);
                }

            }
            else {
                SetapiError(output.message);
            }

        });
    }

    const [socketInstruments, SetsocketInstruments] = useState(null)

    const [TickData, SetTickData] = useState(null);

    useEffect(() => {

        if (socketInstruments) {

            if (socketInstruments.v.length > 0) {
                //console.log("socketInstruments=" + JSON.stringify(socketInstruments));
                AddToMarketDataSocketInstruments(socketInstruments.v);
            }

            socket_io.on("socket-marketdata", (result) => {

                //console.log("data=" + JSON.stringify(result));

                //console.log(JSON.stringify(result));

                var strategies_tickdata_record = localStorage.getItem("strategies_tickdata_record");
                if (strategies_tickdata_record) {

                    strategies_tickdata_record = JSON.parse(strategies_tickdata_record);

                    var index = strategies_tickdata_record.findIndex(function (e) { return e.instrumentToken == result.instrumentToken });

                    //console.log("index=" + index);

                    if (index == -1) {
                        strategies_tickdata_record.push(result);
                        strategies_tickdata_record = JSON.stringify(strategies_tickdata_record);
                        localStorage.setItem("strategies_tickdata_record", strategies_tickdata_record);
                    }
                    else {
                        strategies_tickdata_record[index] = result;
                        strategies_tickdata_record = JSON.stringify(strategies_tickdata_record);
                        localStorage.setItem("strategies_tickdata_record", strategies_tickdata_record);
                    }

                }
                else {
                    var input = [];
                    input.push(result)
                    input = JSON.stringify(input);
                    localStorage.setItem("strategies_tickdata_record", input);
                }
                SetTickData(result);
            });

        };

    }, [socketInstruments]);

    useEffect(() => {
        if (TickData) {
            var strategies_tickdata_record = localStorage.getItem("strategies_tickdata_record");
            if (strategies_tickdata_record) {
                strategies_tickdata_record = JSON.parse(strategies_tickdata_record);
                for (var i = 0; i < strategies_tickdata_record.length; i++) {
                    fillInstrumentsTickData(strategies_tickdata_record[i]);
                }
            }
        }
    }, [TickData]);

    function fillInstrumentsTickData(data) {

        //console.log("data.instrumentToken=" + data.instrumentToken);

        data.lastTradedPrice = data.lastTradedPrice / 100;
        data.openPrice = data.openPrice / 100;
        data.highPrice = data.highPrice / 100;
        data.lowPrice = data.lowPrice / 100;
        data.closePrice = data.closePrice / 100;


        if (Strategies) {

            const newStrategies = [...Strategies]; // make a copy of the array

            for (var i = 0; i < newStrategies.length; i++) {

                //console.log("newStrategies[i].symbol_code=" + newStrategies[i].symbol_code + ",data.instrumentToken=" + data.instrumentToken);

                if (parseInt(newStrategies[i].symbol_code) == data.instrumentToken) {

                    newStrategies[i].pastprice = newStrategies[i].live_price || 0;
                    newStrategies[i].live_price = data.lastTradedPrice;

                    if (parseFloat(newStrategies[i].live_price) > parseFloat(newStrategies[i].pastprice)) {
                        newStrategies[i].trend = "UP";
                    }
                    else if (parseFloat(newStrategies[i].live_price) < parseFloat(newStrategies[i].pastprice)) {
                        newStrategies[i].trend = "DOWN";
                    }

                    var pnl = parseFloat(newStrategies[i].live_price) - parseFloat(data.closePrice);

                    pnl = parseFloat(pnl).toFixed(2);

                    var pnl_percentage = (pnl * 100) / parseFloat(data.closePrice);

                    pnl_percentage = parseFloat(pnl_percentage).toFixed(2);

                    newStrategies[i].pnl = pnl;

                    newStrategies[i].pnl_percentage = pnl_percentage;
                }
            }

            SetStrategies(newStrategies);

        }
    }

    useEffect(() => {

        socket_io.on("event", (data) => {
            //console.log("socket data=" + data);
            if (data == "reload") {
                LoadStrategies();
            }
        });

    }, []);

    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>

                    {apiSuccess && (
                        <div
                            className="alert alert-success text-center"
                            role="alert">
                            {apiSuccess}
                        </div>
                    )}

                    {apiError && (
                        <div
                            className="alert alert-danger text-center"
                            role="alert">
                            {apiError}
                        </div>
                    )}

                    <Row>
                        <Col lg={12}>
                            <div>
                                <button
                                    type="button"
                                    onClick={() => {
                                        tog_standard();
                                    }}
                                    className="btn btn-primary float-end"
                                    data-toggle="modal"
                                    data-target="#myModal"
                                >
                                    Create Strategy
                                </button>

                                <Modal
                                    isOpen={modal_standard}
                                    toggle={() => {
                                        tog_standard();
                                    }}
                                >
                                    <div className="modal-header">
                                        <h5 className="modal-title mt-0">
                                            Create Strategy
                                        </h5>
                                        <button
                                            type="button"
                                            onClick={() => {
                                                setmodal_standard(false);
                                            }}
                                            className="close"
                                            data-dismiss="modal"
                                            aria-label="Close"
                                        >
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <Row className="mb-2">

                                            <Col className="mb-2" md={12}>
                                                <div className="form-floating mb-3">
                                                    <select value={strategy} name="strategy" required={true} className="form-select" onChange={e => { Setstrategy(e.target.value); SetstrategyName(e.target.selectedOptions[0].getAttribute('data-name')); }} disabled={strategyID != "" && true}>
                                                        <option key="0" value="">SELECT</option>
                                                        {(DefaultStrategies || []).map((option, index) => (
                                                            <>
                                                                <option key={index + 1} data-name={option.name} value={option.ID}>{option.name}</option>
                                                            </>
                                                        ))}
                                                    </select>
                                                    <label htmlFor="floatingSelectGrid">Strategy</label>
                                                </div>
                                            </Col>

                                            {strategy == "OPTION_WRITING" && (
                                                <OptionWritingModal editData={editData} strategy={strategy} strategy_type={strategy_type} strategy_name={strategy_name} strategyID={strategyID} AddStrategy={AddStrategy} UpdateStrategy={UpdateStrategy} setmodal_standard={setmodal_standard} />
                                            )}

                                            {strategy == "TIME_RANGE_BREAKOUT" && (
                                                <TimeRangeBreakoutModal editData={editData} strategy={strategy} strategy_type={strategy_type} strategy_name={strategy_name} strategyID={strategyID} AddStrategy={AddStrategy} UpdateStrategy={UpdateStrategy} setmodal_standard={setmodal_standard} />
                                            )}

                                            {strategy == "RSI_STRATEGY" && (
                                                <RSIModal editData={editData} strategy={strategy} strategy_type={strategy_type} strategy_name={strategy_name} strategyID={strategyID} AddStrategy={AddStrategy} UpdateStrategy={UpdateStrategy} setmodal_standard={setmodal_standard} />
                                            )}

                                            {strategy == "MACD_STRATEGY" && (
                                                <MACDModal editData={editData} strategy={strategy} strategy_type={strategy_type} strategy_name={strategy_name} strategyID={strategyID} AddStrategy={AddStrategy} UpdateStrategy={UpdateStrategy} setmodal_standard={setmodal_standard} />
                                            )}

                                            {strategy == "ST_STRATEGY" && (
                                                <SUPERTRENDModal editData={editData} strategy={strategy} strategy_type={strategy_type} strategy_name={strategy_name} strategyID={strategyID} AddStrategy={AddStrategy} UpdateStrategy={UpdateStrategy} setmodal_standard={setmodal_standard} />
                                            )}

                                            {strategy == "HT_STRATEGY" && (
                                                <HALFTRENDModal editData={editData} strategy={strategy} strategy_type={strategy_type} strategy_name={strategy_name} strategyID={strategyID} AddStrategy={AddStrategy} UpdateStrategy={UpdateStrategy} setmodal_standard={setmodal_standard} />
                                            )}

                                            {strategy == "BB_STRATEGY" && (
                                                <BBModal editData={editData} strategy={strategy} strategy_type={strategy_type} strategy_name={strategy_name} strategyID={strategyID} AddStrategy={AddStrategy} UpdateStrategy={UpdateStrategy} setmodal_standard={setmodal_standard} />
                                            )}

                                        </Row>
                                    </div>

                                </Modal>
                            </div>
                        </Col>
                    </Row>

                    <Row className="mt-3">
                        {Strategies && (Strategies).map((item, key) => (

                            <Col md={6} key={key}>

                                {item.strategy == "OPTION_WRITING" && (
                                    <OptionWritingGrid item={item} isMobile={isMobile} strategy_type={strategy_type} EditStrategy={EditStrategy} DeleteStrategy={DeleteStrategy} StrategyEvents={StrategyEvents} SquareoffStrategy={SquareoffStrategy} />
                                )}

                                {item.strategy == "TIME_RANGE_BREAKOUT" && (
                                    <TimeRangeBreakoutGrid item={item} isMobile={isMobile} strategy_type={strategy_type} EditStrategy={EditStrategy} DeleteStrategy={DeleteStrategy} StrategyEvents={StrategyEvents} SquareoffStrategy={SquareoffStrategy} />
                                )}

                                {item.strategy == "RSI_STRATEGY" && (
                                    <RSIGrid item={item} isMobile={isMobile} strategy_type={strategy_type} EditStrategy={EditStrategy} DeleteStrategy={DeleteStrategy} StrategyEvents={StrategyEvents} SquareoffStrategy={SquareoffStrategy} />
                                )}

                                {item.strategy == "MACD_STRATEGY" && (
                                    <MACDGrid item={item} isMobile={isMobile} strategy_type={strategy_type} EditStrategy={EditStrategy} DeleteStrategy={DeleteStrategy} StrategyEvents={StrategyEvents} SquareoffStrategy={SquareoffStrategy} />
                                )}

                                {item.strategy == "ST_STRATEGY" && (
                                    <SUPERTRENDGrid item={item} isMobile={isMobile} strategy_type={strategy_type} EditStrategy={EditStrategy} DeleteStrategy={DeleteStrategy} StrategyEvents={StrategyEvents} SquareoffStrategy={SquareoffStrategy} />
                                )}

                                {item.strategy == "HT_STRATEGY" && (
                                    <HALFTRENDGrid item={item} isMobile={isMobile} strategy_type={strategy_type} EditStrategy={EditStrategy} DeleteStrategy={DeleteStrategy} StrategyEvents={StrategyEvents} SquareoffStrategy={SquareoffStrategy} />
                                )}

                                {item.strategy == "BB_STRATEGY" && (
                                    <BBGrid item={item} isMobile={isMobile} strategy_type={strategy_type} EditStrategy={EditStrategy} DeleteStrategy={DeleteStrategy} StrategyEvents={StrategyEvents} SquareoffStrategy={SquareoffStrategy} />
                                )}

                            </Col>

                        ))}
                    </Row>

                </Container>
            </div>
        </React.Fragment>);
}

export default CustomStrategies;