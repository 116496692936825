import React, { useEffect, useState } from "react"

//redux
import { useSelector, useDispatch } from "react-redux";

import { Container, Row, Col, Card, CardBody, CardTitle, CardSubtitle, Input } from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

import { Link } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

// actions
import { getMasters, getMasterDataSuccessful, mastersEvents, masterAmountChange, mastersEventsSuccessful, mastersEventsFailed } from "../../../store/actions";

import axios from "axios";

import { API_URL } from "helpers/api_helper";

// var mastersData = [
//     {
//         name: "abc",
//         email: "abc@gmail.com",
//         mobile: "123",
//         amount: "1000",
//         active: "true"
//     }
// ];

const Masters = props => {

    //meta title
    document.title = "Masters | Algolingo ";

    const dispatch = useDispatch();

    const { apiSuccess, apiError } = useSelector(state => ({
        apiSuccess: state.AdminMasters.apiSuccess,
        apiError: state.AdminMasters.apiError,
    }
    ));


    const [page, setpage] = useState(0);

    const [searchData, setsearchData] = useState("");

    const [mastersData, setmastersData] = useState(null);

    useEffect(() => {
        setTimeout(() => { LoadData(searchData, page) }, 500);
    }, []);

    useEffect(() => {
        dispatch(mastersEventsSuccessful(null));
        dispatch(mastersEventsFailed(null));
    }, []);

    useEffect(() => {
        if (apiSuccess != null) {
            setTimeout(() => dispatch(mastersEventsSuccessful(null)), 1000)
        }
    }, [apiSuccess]);

    useEffect(() => {
        if (apiError != null) {
            setTimeout(() => dispatch(mastersEventsFailed(null)), 1000)
        }
    }, [apiError]);

    function handleAmountChange(email, planAmount) {
        planAmount = parseInt(planAmount) || 0;
        if (planAmount >= 0) {
            var index = mastersData.findIndex(item => item.email == email);
            if (index > -1) {
                mastersData[index].planAmount = planAmount;
                dispatch(getMasterDataSuccessful(mastersData));
            }
        }
    }

    function SearchData() {
        //alert("searchData=" + searchData);
        LoadData(searchData, page);
    }

    function LoadData(search, no) {

        var obj = {
            "search": search,
            "page": no
        };

        //console.log("page=" + no);

        const headers = {
            'Content-Type': 'application/json'
        };

        axios.post(API_URL + '/admin/getMasters', JSON.stringify(obj), { headers }).then(response => {

            //console.log(response) 

            var output = response.data;

            if (output.result == "success") {
                setmastersData(output.data);
            }
            else {
                setmastersData(null);
            }
        });
    }

    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumb */}

                    <Breadcrumbs title="Dashboards" breadcrumbItem="Masters" />

                    {apiSuccess && (
                        <div
                            className="alert alert-success text-center"
                            role="alert">
                            {apiSuccess}
                        </div>
                    )}

                    {apiError && (
                        <div
                            className="alert alert-danger text-center"
                            role="alert">
                            {apiError}
                        </div>
                    )}

                    <Row>
                        <Col>
                            <Card>
                                <CardBody>

                                    <Row className="mt-3 mb-3">
                                        <Col md={6} className="mb-2">
                                            <div className="d-flex flex-wrap gap-2">

                                                <div className="btn-group">
                                                    <Link to="#" onClick={() => {
                                                        if (page > 0) {
                                                            LoadData(searchData, page - 1);
                                                            setpage(page - 1);
                                                        }
                                                    }} className="btn btn-outline-primary">Prev</Link>
                                                    <Link to="#" className="btn btn-outline-primary">{page + 1}</Link>
                                                    <Link to="#" onClick={() => {
                                                        LoadData(searchData, page + 1);
                                                        setpage(page + 1);
                                                    }} className="btn btn-outline-primary">Next</Link>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col md={6} className="mb-2" style={isMobile == false ? { textAlign: 'right' } : {}}>
                                            <div style={{ display: "inline-flex" }}>
                                                <input  value={searchData} onChange={(val) => {
                                                    if (val != "") {
                                                        setsearchData(val.target.value)
                                                    }
                                                }}
                                                    onKeyPress={event => {
                                                        if (event.key === 'Enter') {
                                                            SearchData();
                                                        }
                                                    }}
                                                    className="form-control " type="text" placeholder="Search"></input>
                                            </div>
                                        </Col>
                                    </Row>

                                    <div className="table-rep-plugin">
                                        <div
                                            className="table-responsive mb-0"
                                            data-pattern="priority-columns"
                                        >
                                            <Table
                                                id="tech-companies-1"
                                                className="table table-striped table-bordered"
                                            >
                                                <Thead>
                                                    <Tr>
                                                        <Th data-priority="2">Name</Th>
                                                        <Th data-priority="3">Master Key</Th>
                                                        <Th data-priority="3">Email</Th>
                                                        <Th data-priority="4">Mobile</Th>
                                                        <Th data-priority="1">Plan Amount</Th>
                                                        <Th data-priority="7">Action</Th>
                                                    </Tr>
                                                </Thead>
                                                <Tbody>
                                                    {mastersData &&

                                                        mastersData.map((data, key) => (
                                                            <Tr key={key}>
                                                                <Td>{data.name}</Td>
                                                                <Td>{data.masterKey}</Td>
                                                                <Td>{data.email}</Td>
                                                                <Td>{data.mobile}</Td>
                                                                <Td>
                                                                    <Input
                                                                        name="amount"
                                                                        className="form-control"
                                                                        type="text"
                                                                        onChange={(val) => { handleAmountChange(data.email, val.target.value) }}
                                                                        value={data.planAmount}
                                                                        style={{ width: "150px" }}
                                                                    />

                                                                </Td>
                                                                <Td>

                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-info me-2" onClick={() => { dispatch(masterAmountChange({ "email": data.email, "planAmount": data.planAmount }, props.history)); setTimeout(() => { LoadData(searchData, page) }, 500); }}
                                                                    >
                                                                        <i className="bx bx-check-double font-size-16 align-middle"></i>

                                                                    </button>

                                                                    {data.active == "false" ? (
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-success me-2" onClick={() => { dispatch(mastersEvents({ "email": data.email, "event": "start" }, props.history)); setTimeout(() => { LoadData(searchData, page) }, 500); }}
                                                                        >
                                                                            <i className="bx bx-check-double font-size-16 align-middle"></i>

                                                                        </button>) : (
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-warning me-2" onClick={() => { dispatch(mastersEvents({ "email": data.email, "event": "stop" }, props.history)); setTimeout(() => { LoadData(searchData, page) }, 500); }}
                                                                        >
                                                                            <i className="bx bx-block font-size-16 align-middle"></i>

                                                                        </button>)}

                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-danger" onClick={() => { dispatch(mastersEvents({ "email": data.email, "event": "delete" }, props.history)); setTimeout(() => { LoadData(searchData, page) }, 500); }}
                                                                    >
                                                                        <i className="bx bx-trash font-size-16 align-middle"></i>

                                                                    </button>




                                                                </Td>
                                                            </Tr>
                                                        ))}
                                                </Tbody>
                                            </Table>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </Container>
            </div>
        </React.Fragment>
    )
}

export default Masters
