import React, { useEffect, useState } from "react"

//redux
import { useSelector, useDispatch } from "react-redux";

import { Container, Row, Col, Card, CardBody, CardTitle, CardSubtitle, Badge, Collapse, Form } from "reactstrap";

import { Link } from "react-router-dom";

import classnames from "classnames";

import { API_URL } from "helpers/api_helper";

// actions

import axios from "axios";

const CreateUser = props => {

    //meta title
    document.title = "Create User | Algolingo ";

    const dispatch = useDispatch();

    const [authenticationData, SetauthenticationData] = useState(null)

    const [apiSuccess, SetapiSuccess] = useState(null)

    const [apiError, SetapiError] = useState(null)

    useEffect(() => {

        if (!authenticationData) {
            var authUser = localStorage.getItem("authUser");
            if (authUser) {
                authUser = atob(authUser);
                SetauthenticationData(JSON.parse(authUser));
            } else {
                var authAdmin = localStorage.getItem("authAdmin");
                if (authAdmin) {
                    authAdmin = atob(authAdmin);
                    SetauthenticationData(JSON.parse(authAdmin));
                }
            }
        }

    }, [authenticationData]);

    useEffect(() => {
        if (apiSuccess != null) {
            setTimeout(() => SetapiSuccess(null), 1000)
        }
    }, [apiSuccess]);

    useEffect(() => {
        if (apiError != null) {
            setTimeout(() => SetapiError(null), 1000)
        }
    }, [apiError]);


    function getAuthenticationData(email, callback) {

        var obj = {
            "email": email
        }


        const headers = {
            'Content-Type': 'application/json'
        };

        axios.post(API_URL + '/user/getAuthenticationData', JSON.stringify(obj), { headers }).then(response => {

            //console.log(response) 

            var output = response.data;

            if (output.result == "success") {

                callback(output.data);

            }
            else {
                callback(null);
            }

        });

    }

    function AddUser(input) {

        var obj = input;

        if (authenticationData && authenticationData.role == "master") {

            var authUser = localStorage.getItem("authUser");
            if (authUser) { authUser = atob(authUser); }
            var email = JSON.parse(authUser).email;

            obj.createdBy = "Master";

            obj.masterEmail = email;

            getAuthenticationData(email, function (authdata) {
                if (authdata) {

                    obj.followingMasterKey = authdata.masterKey;

                    const headers = {
                        'Content-Type': 'application/json'
                    };

                    axios.post(API_URL + '/user/createUserForMaster', JSON.stringify(obj), { headers }).then(response => {

                        //console.log(response) 

                        var output = response.data;

                        if (output.result == "success") {

                            SetapiSuccess(output.message);

                            ResetVariables();

                        }
                        else {
                            SetapiError(output.message);
                        }

                    });

                }
            });

        }
        else if (authenticationData && (authenticationData.role == "admin" || authenticationData.role == "subadmin")) {

            obj.createdBy = "Admin";

            const headers = {
                'Content-Type': 'application/json'
            };

            axios.post(API_URL + '/admin/createUser', JSON.stringify(obj), { headers }).then(response => {

                //console.log(response) 

                var output = response.data;

                if (output.result == "success") {

                    SetapiSuccess(output.message);

                    ResetVariables();

                }
                else {
                    SetapiError(output.message);
                }

            });

        }

    }


    function ResetVariables() {
        document.getElementById("form-create-user").reset();
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>

                    {apiSuccess && (
                        <div
                            className="alert alert-success text-center"
                            role="alert">
                            {apiSuccess}
                        </div>
                    )}

                    {apiError && (
                        <div
                            className="alert alert-danger text-center"
                            role="alert">
                            {apiError}
                        </div>
                    )}

                    <Row>
                        <Col lg={6}>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>
                                        <CardTitle className="mb-4">Create User</CardTitle>

                                        <Form id="form-create-user" className="row" onSubmit={(e) => {
                                            e.preventDefault();

                                            var data = new FormData(e.target);

                                            var input = {
                                                name: data.get("name"),
                                                email: data.get("email"),
                                                mobile: data.get("mobile"),
                                                password: data.get("password")
                                            };

                                            //console.log(input);

                                            AddUser(input);

                                        }
                                        }>
                                            <Col className="mb-2" md={6}>
                                                <div className="form-floating mb-3">
                                                    <input type="text" name="name" className="form-control" id="txt-name" placeholder="Enter Name" defaultValue="" required={true} />
                                                    <label htmlFor="txt-name">Name</label>
                                                </div>
                                            </Col>

                                            <Col className="mb-2" md={6}>
                                                <div className="form-floating mb-3">
                                                    <input type="text" name="email" className="form-control" id="txt-email" placeholder="Enter Name" defaultValue="" required={true} />
                                                    <label htmlFor="txt-email">Email</label>
                                                </div>
                                            </Col>

                                            <Col className="mb-2" md={6}>
                                                <div className="form-floating mb-3">
                                                    <input type="text" name="mobile" className="form-control" id="txt-mobile" placeholder="Enter Name" defaultValue="" required={true} />
                                                    <label htmlFor="txt-mobile">Mobile</label>
                                                </div>
                                            </Col>

                                            <Col className="mb-2" md={6}>
                                                <div className="form-floating mb-3">
                                                    <input type="text" name="password" className="form-control" id="txt-password" placeholder="Enter Name" defaultValue="" required={true} />
                                                    <label htmlFor="txt-password">Password</label>
                                                </div>
                                            </Col>

                                            <div style={{ textAlign: "Right" }}>
                                                <button type="submit" className="btn btn-primary w-md">
                                                    Submit
                                                </button>
                                            </div>

                                        </Form>

                                    </CardBody>
                                </Card>
                            </Col>
                        </Col>
                    </Row>

                </Container>
            </div>
        </React.Fragment>
    )
}

export default CreateUser
