import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom";
import { Col, Container, Form, Row, Input, Label, FormFeedback } from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// import images
import logodark from "../../../assets/images/logo.png";
import logolight from "../../../assets/images/logo.png";
import CarouselPage from "./CarouselPage";

// actions
import { loginAdmin, loginAdminSuccessful, loginAdminFailed } from "../../../store/actions";

const Login = props => {

  //meta title
  document.title = "Login | Algolingo ";

  const dispatch = useDispatch();

  const { apiData, apiSuccess, apiError } = useSelector(state => ({
    apiData: state.AdminLogin.apiData,
    apiSuccess: state.AdminLogin.apiSuccess,
    apiError: state.AdminLogin.apiError
  }));

  // Form validation 
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values) => {
      //console.log("values", values);
      dispatch(loginAdmin(values, props.history));
    }
  });

  useEffect(() => {
    dispatch(loginAdminSuccessful(null));
    dispatch(loginAdminFailed(null));
  }, []);

  useEffect(() => {
    if (apiSuccess != null) {
      setTimeout(() => dispatch(loginAdminSuccessful(null)), 1000)
    }
  }, [apiSuccess]);

  useEffect(() => {
    if (apiError != null) {
      setTimeout(() => dispatch(loginAdminFailed(null)), 1000)
    }
  }, [apiError]);

  return (
    <React.Fragment>
      <div>
        <Row className="g-0">
          <CarouselPage />
          <Col xl={3}>
            <div className="auth-full-page-content p-md-5 p-4">
              <div className="w-100">
                <div className="d-flex flex-column h-100">
                  <div className="mb-4 mb-md-5">
                    <Link to="/dashboard" className="d-block auth-logo">
                      <img
                        src={logodark}
                        alt=""
                        height="36"
                        className="auth-logo-dark"
                      />
                      <img
                        src={logolight}
                        alt=""
                        height="36"
                        className="auth-logo-light"
                      />
                    </Link>
                  </div>

                  {apiSuccess && (
                    <div
                      className="alert alert-success text-center mb-4"
                      role="alert">
                      {apiSuccess}
                    </div>
                  )}

                  {apiError && (
                    <div
                      className="alert alert-danger text-center mb-4"
                      role="alert">
                      {apiError}
                    </div>
                  )}

                  <div className="my-auto">
                    <div>
                      <h5 className="text-primary">Welcome Back !</h5>
                      <p className="text-muted">
                        Sign in to continue to Algolingo.
                      </p>
                    </div>

                    <div className="mt-4">
                      <Form className="form-horizontal"
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <div className="form-floating mb-3">
                          <Input
                            name="email"
                            className="form-control"
                            placeholder="Enter email"
                            type="email"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={
                              validation.touched.email && validation.errors.email ? true : false
                            }
                          />
                          <Label className="form-label">Email</Label>
                          {validation.touched.email && validation.errors.email ? (
                            <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                          ) : null}
                        </div>

                        <div className="form-floating mb-3">
                          <Input
                            name="password"
                            value={validation.values.password || ""}
                            type="password"
                            placeholder="Enter Password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.password && validation.errors.password ? true : false
                            }
                          />
                          <Label className="form-label">Password</Label>
                          {validation.touched.password && validation.errors.password ? (
                            <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                          ) : null}
                        </div>

                        <div className="form-check">
                          <Input
                            type="checkbox"
                            className="form-check-input"
                            id="auth-remember-check"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="auth-remember-check"
                          >
                            Remember me
                          </label>
                        </div>

                        <div className="mt-3 d-grid">
                          <button
                            className="btn btn-primary btn-block "
                            type="submit"
                          >
                            Log In
                          </button>
                        </div>
                      </Form>

                    </div>
                  </div>

                  <div className="mt-4 mt-md-5 text-center">
                    <p className="mb-0">
                      © {new Date().getFullYear()} Algolingo. Crafted with{" "}
                      <i className="mdi mdi-heart text-danger"></i> by
                      Algolingo
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default Login;
