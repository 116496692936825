import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Row, Col, Collapse } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import classname from "classnames";

//i18n
import { withTranslation } from "react-i18next";

import { connect } from "react-redux";

const Navbar = props => {

  const [dashboard, setdashboard] = useState(false);
  const [ui, setui] = useState(false);
  const [app, setapp] = useState(false);
  const [email, setemail] = useState(false);
  const [ecommerce, setecommerce] = useState(false);
  const [crypto, setcrypto] = useState(false);
  const [project, setproject] = useState(false);
  const [task, settask] = useState(false);
  const [contact, setcontact] = useState(false);
  const [blog, setBlog] = useState(false);
  const [component, setcomponent] = useState(false);
  const [form, setform] = useState(false);
  const [table, settable] = useState(false);
  const [chart, setchart] = useState(false);
  const [icon, seticon] = useState(false);
  const [map, setmap] = useState(false);
  const [extra, setextra] = useState(false);
  const [invoice, setinvoice] = useState(false);
  const [auth, setauth] = useState(false);
  const [utility, setutility] = useState(false);

  const [role, setRole] = useState("");

  useEffect(() => {
    if (localStorage.getItem("authAdmin")) {
      var authAdmin = localStorage.getItem("authAdmin");
      if (authAdmin) {
        authAdmin = atob(authAdmin);
      }
      const obj = JSON.parse(authAdmin);
      setRole(obj.role || "");
    }
    else if (localStorage.getItem("authUser")) {
      var authUser = localStorage.getItem("authUser");
      if (authUser) {
        authUser = atob(authUser);
      }
      const obj = JSON.parse(authUser);
      setRole(obj.role || "");
    }
  }, [props.success]);

  useEffect(() => {
    var matchingMenuItem = null;
    var ul = document.getElementById("navigation");
    var items = ul.getElementsByTagName("a");
    for (var i = 0; i < items.length; ++i) {
      if (props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  });

  function activateParentDropdown(item) {
    item.classList.add("active");
    const parent = item.parentElement;
    if (parent) {
      parent.classList.add("active"); // li
      const parent2 = parent.parentElement;
      parent2.classList.add("active"); // li
      const parent3 = parent2.parentElement;
      if (parent3) {
        parent3.classList.add("active"); // li
        const parent4 = parent3.parentElement;
        if (parent4) {
          parent4.classList.add("active"); // li
          const parent5 = parent4.parentElement;
          if (parent5) {
            parent5.classList.add("active"); // li
            const parent6 = parent5.parentElement;
            if (parent6) {
              parent6.classList.add("active"); // li
            }
          }
        }
      }
    }
    return false;
  }

  const hideNavBar = () => {
    document.getElementById("header-toggle").click();
  }

  return (
    <React.Fragment>
      <div className="topnav">
        <div className="container-fluid">
          <nav
            className="navbar navbar-light navbar-expand-lg topnav-menu"
            id="navigation"
          >
            <Collapse
              isOpen={props.leftMenu}
              className="navbar-collapse"
              id="topnav-menu-content"
            >

              {(role == "user" || role == "master") && (

                <ul className="navbar-nav">

                  <li className="nav-item">
                    <Link onClick={hideNavBar}
                      className="nav-link dropdown-toggle arrow-none" to="/authentication" >
                      <i className="fas fa-fingerprint me-2"></i>
                      <span>{props.t("Authentication")}</span>
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link onClick={hideNavBar} className="nav-link dropdown-toggle arrow-none" to="/marketwatch" >
                      <i className="fas fa-chart-line me-2"></i>
                      <span>{props.t("MarketWatch")}</span>
                    </Link>
                  </li>

                  <li className="nav-item dropdown">
                    <Link
                      to="/#"
                      onClick={e => {
                        e.preventDefault();
                        setecommerce(!ecommerce);
                      }}
                      className="nav-link dropdown-toggle arrow-none"
                    >
                      <i className="bx bx-customize me-2"></i>
                      {props.t("Trade Info")} <div className="arrow-down"></div>
                    </Link>
                    <div className={classname("dropdown-menu", { show: ecommerce })}>

                      {role == "master" && (

                        <Link onClick={hideNavBar} to="/tradehistory" className="dropdown-item">
                          {props.t("Trade History")}
                        </Link>

                      )}

                      <Link onClick={hideNavBar} to="/bridgetradehistory" className="dropdown-item">
                        {props.t("Bridge Trade History")}
                      </Link>

                    </div>
                  </li>

                  <li className="nav-item dropdown">
                    <Link
                      to="/#"
                      onClick={e => {
                        e.preventDefault();
                        setemail(!email);
                      }}
                      className="nav-link dropdown-toggle arrow-none"
                    >
                      <i className="bx bx-customize me-2"></i>
                      {props.t("Broker Panel")} <div className="arrow-down"></div>
                    </Link>
                    <div className={classname("dropdown-menu", { show: email })}>

                      <Link onClick={hideNavBar} to="/orderbook" className="dropdown-item">
                        {props.t("OrderBook")}
                      </Link>
                      <Link onClick={hideNavBar} to="/tradebook" className="dropdown-item">
                        {props.t("TradeBook")}
                      </Link>
                      <Link onClick={hideNavBar} to="/positions" className="dropdown-item">
                        {props.t("Positions")}
                      </Link>
                    </div>
                  </li>

                  {role == "master" && (
                    <li className="nav-item dropdown">
                      <Link
                        to="/#"
                        onClick={e => {
                          e.preventDefault();
                          setcrypto(!crypto);
                        }}
                        className="nav-link dropdown-toggle arrow-none"
                      >
                        <i className="bx bx-customize me-2"></i>
                        {props.t("User Info")} <div className="arrow-down"></div>
                      </Link>
                      <div className={classname("dropdown-menu", { show: crypto })}>

                        <Link onClick={hideNavBar} to="/create-user" className="dropdown-item">
                          {props.t("Create User")}
                        </Link>
                        <Link onClick={hideNavBar} to="/created-accounts" className="dropdown-item">
                          {props.t("Created Accounts")}
                        </Link>
                        <Link onClick={hideNavBar} to="/clients" className="dropdown-item">
                          {props.t("My Clients")}
                        </Link>

                      </div>
                    </li>
                  )}

                </ul>

              )}

              {(role == "admin" || role == "subadmin") && (

                <ul className="navbar-nav">

                  <li className="nav-item">
                    <Link onClick={hideNavBar} className="nav-link dropdown-toggle arrow-none" to="/admin/users" >
                      <i className="fas fa-handshake me-2"></i>
                      <span>{props.t("Users")}</span>
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link onClick={hideNavBar} className="nav-link dropdown-toggle arrow-none" to="/admin/masters" >
                      <i className="bx bx-bolt-circle me-2"></i>
                      <span>{props.t("Masters")}</span>
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link onClick={hideNavBar} className="nav-link dropdown-toggle arrow-none" to="/admin/payments" >
                      <i className="bx bx-rupee me-2"></i>
                      <span>{props.t("Payments")}</span>
                    </Link>
                  </li>

                </ul>

              )}



            </Collapse>
          </nav>
        </div>
      </div>
    </React.Fragment>
  );
};

Navbar.propTypes = {
  leftMenu: PropTypes.any,
  location: PropTypes.any,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
};

const mapStatetoProps = state => {
  const { leftMenu } = state.Layout;
  return { leftMenu };
};

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(Navbar))
);
