import React from "react"
import { Redirect } from "react-router-dom"

//USER MODULES

// Authentication related pages
import UsersLogin from "../pages/Users/Auth/Login"
import UsersLogout from "../pages/Users/Auth/Logout"
import UsersRegister from "../pages/Users/Auth/Register"
import UsersForgetPwd from "../pages/Users/Auth/ForgetPassword"
import UsersResetPwd from "../pages/Users/Auth/ResetPassword"
import UsersTotp from "../pages/Users/Auth/Totp"

// Dashboard
import UsersAuthentication from "../pages/Users/Authentication/index"
import UsersOrderBook from "../pages/Users/OrderBook/index"
import UsersTradeBook from "../pages/Users/TradeBook/index"
import UsersPositions from "../pages/Users/Positions/index"
import UsersMarketWatch from "../pages/Users/MarketWatch/index"
import UsersMyClients from "../pages/Users/Clients/index"
import UsersTradeHistory from "../pages/Users/TradeHistory/index"
import UsersSettings from "../pages/Users/Settings/index"
import UsersFreebies from "../pages/Users/Freebies/index"
import OptionChain from "../pages/Users/OptionChain/index"
import UsersCreatedAccounts from "../pages/Users/CreatedAccounts/index"

import PaymentStatus from "../pages/Users/PaymentStatus/index"
import PaymentSuccess from "../pages/Users/PaymentSuccess/index"
import PaymentFailed from "../pages/Users/PaymentFailed/index"

import MasterCreateUser from "../pages/Users/CreateUser/index"

import UsersBridgeTradeHistory from "../pages/Users/BridgeTradeHistory/index"

import UsersDisclaimers from "../pages/Users/Disclaimer/index"

import UsersLogs from "../pages/Users/Logs/index"

import UsersPayments from "../pages/Users/Payments/index"

import UsersStrategies from "../pages/Users/Strategies/index"

import UsersCustomStrategies from "../pages/Users/CustomStrategies/Normal/index"

import UsersCustomStrategyTradeHistory from "../pages/Users/CustomStrategyTradeHistory/index"

import UsersCustomStrategySignalHistory from "../pages/Users/CustomStrategySignalHistory/index"

import UsersCustomStrategiesOptions from "../pages/Users/CustomStrategies/Options/index"


import UsersMySubscriptions from "../pages/Users/MySubscriptions/index"

import UsersWallet from "../pages/Users/Wallet/index"

import OptionStrategies from "../pages/Users/OptionStrategies/index"

//ADMIM MODULES

import AdminLogin from "../pages/Admin/Auth/Login"
import AdminLogout from "../pages/Admin/Auth/Logout"

import Users from "../pages/Admin/Users/index"
import Masters from "../pages/Admin/Masters/index"
import Payments from "../pages/Admin/Payments/index"


const authProtectedRoutes = [
  { path: "/authentication", component: UsersAuthentication },
  { path: "/marketwatch", component: UsersMarketWatch },
  { path: "/orderbook", component: UsersOrderBook },
  { path: "/tradebook", component: UsersTradeBook },
  { path: "/positions", component: UsersPositions },
  { path: "/clients", component: UsersMyClients },
  { path: "/tradehistory", component: UsersTradeHistory },
  { path: "/bridgetradehistory", component: UsersBridgeTradeHistory },
  { path: "/settings", component: UsersSettings },
  { path: "/create-user", component: MasterCreateUser },
  { path: "/freebies", component: UsersFreebies },
  { path: "/payments", component: UsersPayments },
  { path: "/payment-status", component: PaymentStatus },
  { path: "/payment-success", component: PaymentSuccess },
  { path: "/payment-failed", component: PaymentFailed },
  { path: "/optionchain", component: OptionChain },
  { path: "/created-accounts", component: UsersCreatedAccounts },
  { path: "/disclaimer", component: UsersDisclaimers },
  { path: "/logs", component: UsersLogs },

  { path: "/mysubscriptions", component: UsersMySubscriptions },

  { path: "/strategies", component: UsersStrategies },
  { path: "/customstrategies", component: UsersCustomStrategies },
  { path: "/customstrategiestradehistory", component: UsersCustomStrategyTradeHistory },
  { path: "/customstrategiessignalhistory", component: UsersCustomStrategySignalHistory },

  { path: "/customstrategiesoptions", component: UsersCustomStrategiesOptions },

  { path: "/optionstrategies", component: OptionStrategies },

  { path: "/wallet", component: UsersWallet },

  { path: "/admin/users", component: Users },
  { path: "/admin/masters", component: Masters },
  { path: "/admin/payments", component: Payments },
  { path: "/admin/create-user", component: MasterCreateUser },


  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/admin/", exact: true, component: () => <Redirect to="/admin/users" /> },

  { path: "/", exact: true, component: () => <Redirect to="/marketwatch" /> },
]

const publicRoutes = [
  { path: "/logout", component: UsersLogout },
  { path: "/login", component: UsersLogin },
  { path: "/totp", component: UsersTotp },
  { path: "/forgot-password", component: UsersForgetPwd },
  { path: "/reset-password", component: UsersResetPwd },
  { path: "/register", component: UsersRegister },

  { path: "/admin/logout", component: AdminLogout },
  { path: "/admin/login", component: AdminLogin },
]

export { publicRoutes, authProtectedRoutes }
