import React, { useEffect } from "react"

//redux
import { useSelector, useDispatch } from "react-redux";

import { Container, Row, Col, Card, CardBody, CardTitle, CardSubtitle } from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

// actions
import { getTradebook, getAuthenticationData } from "../../../store/actions";

import { API_URL } from "helpers/api_helper";

import { socket_io, AddToMarketDataSocketInstruments } from "../../../common/sockets"

const TradeBook = props => {

  //meta title
  document.title = "TradeBook | Algolingo ";

  const dispatch = useDispatch();

  const { tradebookData, authenticationData } = useSelector(state => ({
    tradebookData: state.Trading.tradebookData,
    authenticationData: state.Authentication.authenticationData,
  }
  ));

  useEffect(() => {
    setTimeout(() => { dispatch(getAuthenticationData()); getTradebookData }, 100);
  }, []);

  useEffect(() => {
    setInterval(getTradebookData, 3000);
  }, []);

  function getTradebookData() {
    if (document.title.indexOf("TradeBook") == -1) {
      return;
    }
    dispatch(getTradebook());
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Dashboards" breadcrumbItem="TradeBook" />

          <Row>
            <Col>
              <Card>
                <CardBody>

                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                    >
                      <Table
                        id="tech-companies-1"
                        className="table table-striped table-bordered"
                      >
                        <Thead>
                          <Tr>
                            <Th>Time</Th>
                            <Th data-priority="1">Type</Th>
                            <Th data-priority="2">Instrument</Th>
                            <Th data-priority="3">Product</Th>
                            <Th data-priority="4">Qty</Th>
                            <Th data-priority="5">Avg Price</Th>
                            <Th data-priority="6">Status</Th>
                            <Th data-priority="7">Trade By</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {tradebookData && authenticationData && authenticationData.defaultBroker == "alice" &&

                            tradebookData.map((data, key) => (
                              // console.log(JSON.stringify(data)),
                              <Tr key={key}>
                                <Td>
                                  {data.Time}
                                </Td>
                                <Td><span className={data.Trantype.toUpperCase() == "B" ? "text-success" : "text-danger"}>{data.Trantype}</span> </Td>
                                <Td>{data.Tsym}</Td>
                                <Td>{data.Pcode}</Td>
                                <Td>{data.Qty}</Td>
                                <Td>{data.AvgPrice}</Td>
                                <Td>{data.stat.toUpperCase()}</Td>
                                <Td>{data.remarks == "--" ? "MANUAL" : data.remarks.split("#")[0]}</Td>
                              </Tr>
                            ))}

                          {tradebookData && authenticationData && authenticationData.defaultBroker == "zerodha" &&

                            tradebookData.sort((a, b) => a.order_timestamp > b.order_timestamp ? -1 : 1).map((data, key) => (
                              // console.log(JSON.stringify(data)),
                              <Tr key={key}>
                                <Td>
                                  {data.order_timestamp}
                                </Td>
                                <Td><span className={data.transaction_type.toUpperCase() == "BUY" ? "text-success" : "text-danger"}>{data.transaction_type}</span> </Td>
                                <Td>{data.tradingsymbol}</Td>
                                <Td>{data.product}</Td>
                                <Td>{data.quantity}</Td>
                                <Td>{data.average_price}</Td>
                                <Td>-</Td>
                                <Td>{data.tag == null ? "MANUAL" : data.tag.split("#")[0]}</Td>
                              </Tr>
                            ))}

                          {tradebookData && authenticationData && authenticationData.defaultBroker == "angel" &&

                            tradebookData.sort((a, b) => a.filltime > b.filltime ? -1 : 1).map((data, key) => (
                              // console.log(JSON.stringify(data)),
                              <Tr key={key}>
                                <Td>
                                  {data.filltime}
                                </Td>
                                <Td><span className={data.transactiontype.toUpperCase() == "BUY" ? "text-success" : "text-danger"}>{data.transactiontype}</span> </Td>
                                <Td>{data.tradingsymbol}</Td>
                                <Td>{data.producttype}</Td>
                                <Td>{data.fillsize}</Td>
                                <Td>{data.fillprice}</Td>
                                <Td>-</Td>
                                <Td>{data.ordertag == null ? "MANUAL" : data.ordertag.split("#")[0]}</Td>
                              </Tr>
                            ))}

                          {tradebookData && authenticationData && (authenticationData.defaultBroker == "basan" || authenticationData.defaultBroker == "sas" || authenticationData.defaultBroker == "mastertrust") &&

                            tradebookData.sort((a, b) => a.trade_time > b.trade_time ? -1 : 1).map((data, key) => (
                              // console.log(JSON.stringify(data)),
                              <Tr key={key}>
                                <Td>
                                  {new Date(parseInt(data.trade_time) * 1000).toLocaleString()}
                                </Td>
                                <Td><span className={data.order_side.toUpperCase() == "BUY" ? "text-success" : "text-danger"}>{data.order_side}</span> </Td>
                                <Td>{data.trading_symbol}</Td>
                                <Td>{data.product}</Td>
                                <Td>{data.filled_quantity}</Td>
                                <Td>{data.trade_price}</Td>
                                <Td>-</Td>
                                <Td>{data.ordertag == null ? "MANUAL" : data.ordertag.split("#")[0]}</Td>
                              </Tr>
                            ))}

                          {tradebookData && authenticationData && authenticationData.defaultBroker == "dhan" &&

                            tradebookData.sort((a, b) => a.createTime > b.createTime ? -1 : 1).map((data, key) => (
                              // console.log(JSON.stringify(data)),
                              <Tr key={key}>
                                <Td>
                                  {data.createTime}
                                </Td>
                                <Td><span className={data.transactionType.toUpperCase() == "BUY" ? "text-success" : "text-danger"}>{data.transactionType}</span> </Td>
                                <Td>{data.tradingSymbol}</Td>
                                <Td>{data.productType}</Td>
                                <Td>{data.tradedQuantity}</Td>
                                <Td>{data.tradedPrice}</Td>
                                <Td>-</Td>
                                <Td>{data.correlationId == null ? "MANUAL" : data.correlationId.split("#")[0]}</Td>
                              </Tr>
                            ))}

                          {tradebookData && authenticationData && authenticationData.defaultBroker == "finvasia" &&

                            tradebookData.sort((a, b) => a.norenordno > b.norenordno ? -1 : 1).map((data, key) => (
                              // console.log(JSON.stringify(data)),
                              <Tr key={key}>
                                <Td>
                                  {data.norentm}
                                </Td>
                                <Td><span className={data.trantype.toUpperCase() == "B" ? "text-success" : "text-danger"}>{data.trantype}</span> </Td>
                                <Td>{data.tsym}</Td>
                                <Td>{data.prctyp}</Td>
                                <Td>{data.qty}</Td>
                                <Td>{data.prc}</Td>
                                <Td>-</Td>
                                <Td>{data.remarks == null ? "MANUAL" : data.remarks.split("#")[0]}</Td>
                              </Tr>
                            ))}

                          {tradebookData && authenticationData && authenticationData.defaultBroker == "fyers" &&

                            tradebookData.sort((a, b) => a.orderDateTime > b.orderDateTime ? -1 : 1).map((data, key) => (
                              // console.log(JSON.stringify(data)),
                              <Tr key={key}>
                                <Td>
                                  {data.orderDateTime}
                                </Td>
                                <Td><span className={data.transactionType == 1 ? "text-success" : "text-danger"}>{data.transactionType == 1 ? "BUY" : "SELL"}</span> </Td>
                                <Td>{data.symbol}</Td>
                                <Td>{data.productType}</Td>
                                <Td>{data.tradedQty}</Td>
                                <Td>{data.tradePrice}</Td>
                                <Td>-</Td>
                                <Td>-</Td>
                              </Tr>
                            ))}

                          {tradebookData && authenticationData && (authenticationData.defaultBroker == "iifl"   || authenticationData.defaultBroker == "agarwal" || data.credentials.brokerId == "bigul" || authenticationData.defaultBroker == "nirmal" || authenticationData.defaultBroker == "anand" || authenticationData.defaultBroker == "composite" || authenticationData.defaultBroker == "wisdom" || authenticationData.defaultBroker == "smc" || authenticationData.defaultBroker == "sscorp") &&

                            tradebookData.sort((a, b) => a.OrderGeneratedDateTime > b.OrderGeneratedDateTime ? -1 : 1).map((data, key) => (
                              // console.log(JSON.stringify(data)),
                              <Tr key={key}>
                                <Td>
                                  {data.OrderGeneratedDateTime}
                                </Td>
                                <Td><span className={data.OrderSide == "BUY" ? "text-success" : "text-danger"}>{data.OrderSide}</span> </Td>
                                <Td>{data.ExchangeInstrumentID}</Td>
                                <Td>{data.ProductType}</Td>
                                <Td>{data.OrderQuantity}</Td>
                                <Td>{data.OrderAverageTradedPrice}</Td>
                                <Td>{data.OrderStatus}</Td>
                                <Td>{data.OrderUniqueIdentifier == null ? "MANUAL" : data.OrderUniqueIdentifier.split("#")[0]}</Td>
                              </Tr>
                            ))}

                          {tradebookData && authenticationData && authenticationData.defaultBroker == "goodwill" &&

                            tradebookData.sort((a, b) => a.exchtime > b.exchtime ? -1 : 1).map((data, key) => (
                              // console.log(JSON.stringify(data)),
                              <Tr key={key}>
                                <Td>
                                  {data.exchtime}
                                </Td>
                                <Td><span className={data.trantype == "B" ? "text-success" : "text-danger"}>{data.trantype}</span> </Td>
                                <Td>{data.tsym}</Td>
                                <Td>{data.product}</Td>
                                <Td>{data.qty}</Td>
                                <Td>{data.price}</Td>
                                <Td>-</Td>
                                <Td>-</Td>
                              </Tr>
                            ))}

                          {tradebookData && authenticationData && authenticationData.defaultBroker == "icici" &&

                            tradebookData.map((data, key) => (
                              // console.log(JSON.stringify(data)),
                              <Tr key={key}>
                                <Td>
                                  {data.order_trade_date}
                                </Td>
                                <Td><span className={data.order_flow.toUpperCase() == "B" ? "text-success" : "text-danger"}>{data.order_flow}</span> </Td>
                                <Td>{data.order_stock_code}</Td>
                                <Td>{data.order_product}</Td>
                                <Td>{data.order_quantity}</Td>
                                <Td>{data.order_average_executed_rate}</Td>
                                <Td>-</Td>
                                <Td>-</Td>
                              </Tr>
                            ))}

                          {tradebookData && authenticationData && authenticationData.defaultBroker == "neo" &&

                            tradebookData.map((data, key) => (
                              // console.log(JSON.stringify(data)),
                              <Tr key={key}>
                                <Td>
                                  {data.exTm}
                                </Td>
                                <Td><span className={data.trnsTp.toUpperCase() == "B" ? "text-success" : "text-danger"}>{data.trnsTp}</span> </Td>
                                <Td>{data.trdSym}</Td>
                                <Td>{data.prod}</Td>
                                <Td>{data.fldQty}</Td>
                                <Td>{data.avgPrc}</Td>
                                <Td>{data.nOrdNo}</Td>
                                <Td>-</Td>
                              </Tr>
                            ))}

                        </Tbody>
                      </Table>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

        </Container>
      </div>
    </React.Fragment>
  )
}

export default TradeBook
