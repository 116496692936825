import React, { useEffect, useState } from "react"

//redux
import { useSelector, useDispatch } from "react-redux";

import { Container, Row, Col, Card, CardBody, CardTitle, CardSubtitle, Tooltip } from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

import { Link } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

// actions

import axios from "axios";

import { API_URL } from "helpers/api_helper";

import { socket_io, AddToMarketDataSocketInstruments } from "../../../common/sockets"

const Logs = props => {

  //meta title
  document.title = "Logs | Algolingo ";


  const [ttop, setttop] = useState({ id: "TooltipTop_0", open: false, message: "" });

  const [page, setpage] = useState(0);

  const [searchData, setsearchData] = useState("");

  const [logsData, setlogsData] = useState(null);

  const [apiSuccess, SetapiSuccess] = useState(null)

  const [apiError, SetapiError] = useState(null)

  useEffect(() => {
    if (apiSuccess != null) {
      setTimeout(() => SetapiSuccess(null), 1000)
    }
  }, [apiSuccess]);

  useEffect(() => {
    if (apiError != null) {
      setTimeout(() => SetapiError(null), 1000)
    }
  }, [apiError]);

  useEffect(() => {
    LoadData(searchData, page);
  }, []);


  function SearchData() {
    //alert("searchData=" + searchData);
    LoadData(searchData, page);
  }

  function LoadData(search, no) {

    var authUser = localStorage.getItem("authUser");
    if (authUser) { authUser = atob(authUser); }
    var email = JSON.parse(authUser).email;

    var obj = {
      "email": email,
      "search": search,
      "page": no
    };

    //console.log("page=" + no);

    const headers = {
      'Content-Type': 'application/json'
    };

    axios.post(API_URL + '/trading/getErrorLogs', JSON.stringify(obj), { headers }).then(response => {

      //console.log(response) 

      var output = response.data;

      if (output.result == "success") {
        setlogsData(output.data);
      }
      else {
        setlogsData(null);
      }
    });
  }

  function ClearLogsHistory() {

    if (window.confirm('Are You Sure Want To Clear Logs ?')) {

      var authUser = localStorage.getItem("authUser");
      if (authUser) { authUser = atob(authUser); }
      var email = JSON.parse(authUser).email;

      var obj = {
        "email": email
      };

      //console.log("page=" + no);

      const headers = {
        'Content-Type': 'application/json'
      };

      axios.post(API_URL + '/trading/clearLogsHistory', JSON.stringify(obj), { headers }).then(response => {

        //console.log(response) 

        var output = response.data;

        if (output.result == "success") {

          SetapiSuccess(output.message);

          setlogsData(null);

        }
        else {
          SetapiError(output.message);
        }

      });

    }

  }

  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const isMobile = width <= 768;

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Dashboards" breadcrumbItem="Logs History" />

          <Row>
            <Col>
              <Card>
                <CardBody>

                  <Row className="mb-4">

                    <Col md={6} className="mb-2">
                      <div className="d-flex flex-wrap gap-2">

                        <div className="btn-group">
                          <Link to="#" onClick={() => {
                            if (page > 0) {
                              LoadData(searchData, page - 1);
                              setpage(page - 1);
                            }
                          }} className="btn btn-outline-primary">Prev</Link>
                          <Link to="#" className="btn btn-outline-primary">{page + 1}</Link>
                          <Link to="#" onClick={() => {
                            LoadData(searchData, page + 1);
                            setpage(page + 1);
                          }} className="btn btn-outline-primary">Next</Link>
                        </div>
                      </div>
                    </Col>

                    <Col md={6} className="mb-2" style={isMobile == false ? { textAlign: 'right' } : {}}>

                      <div style={{ display: "inline-flex" }}>

                        <input value={searchData} onChange={(val) => {
                          if (val != "") {
                            setsearchData(val.target.value)
                          }
                        }}
                          onKeyPress={event => {
                            if (event.key === 'Enter') {
                              SearchData();
                            }
                          }}
                          className="form-control" type="text" placeholder="Search"></input>

                        <button
                          type="button"
                          className="btn btn-danger ms-2 me-3"
                          onClick={() => {
                            ClearLogsHistory();
                          }}
                        >
                          Clear
                        </button>

                      </div>

                    </Col>

                  </Row>

                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                    >
                      <Table
                        id="tech-companies-1"
                        className="table table-striped table-bordered"
                      >
                        <Thead>
                          <Tr>
                            <Th data-priority="1">Email</Th>
                            <Th data-priority="2">Event</Th>
                            <Th data-priority="3">Message</Th>
                            <Th data-priority="7">DateTime</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {logsData &&

                            logsData.map((data, key) => (
                              <Tr key={key}>
                                <Td>{data.email}</Td>
                                <Td>{data.event}</Td>
                                <Td>{data.message}</Td>
                                <Td>{(data.created_at) ? new Date(data.created_at).toLocaleString() : "-"}</Td>
                              </Tr>
                            ))}
                        </Tbody>
                      </Table>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

        </Container>
      </div>
    </React.Fragment>
  )
}

export default Logs
