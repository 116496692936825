import React, { useEffect, useState } from "react"

//redux
import { useSelector, useDispatch } from "react-redux";

import {
    Card,
    Col,
    Container,
    Row,
    CardBody,
    CardTitle,
    Label,
    Button,
    Form,
    Input,
    InputGroup,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    FormGroup,
    Badge
} from "reactstrap";

import { Link } from "react-router-dom";

import { isEmpty, map } from "lodash";

import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

import Slider from "react-rangeslider"
import "react-rangeslider/lib/index.css"

//Import Scrollbar
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

import images from "assets/images";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

//Import Image
import features from "../../../assets/images/crypto/features-img/img-1.png"

// actions
import { getAuthenticationData } from "../../../store/actions";

import classnames from "classnames";

import { API_URL } from "helpers/api_helper";

import axios from "axios";

const Settings = props => {

    //meta title
    document.title = "Settings | Algolingo ";

    const dispatch = useDispatch();

    const { authenticationData } = useSelector(state => ({
        authenticationData: state.Authentication.authenticationData,
    }
    ));

    const [Multiplier, SetMultiplier] = useState(1)
    const [LastMultiplier, SetLastMultiplier] = useState(1)
    const [CopyAllTrades, SetCopyAllTrades] = useState(true)
    const [CopySelectedSymbols, SetCopySelectedSymbols] = useState(false)
    const [CopyTradeSymbol, SetCopyTradeSymbol] = useState("")
    const [CopyTradeQty, SetCopyTradeQty] = useState(1)

    const [CopyReverse, SetCopyReverse] = useState(false)

    const [Bridge, SetBridge] = useState(true)
    const [BridgeCopyCashTrades, SetBridgeCopyCashTrades] = useState(true)
    const [BridgeCopyFuturesTrades, SetBridgeCopyFuturesTrades] = useState(true)
    const [BridgeCopyTradeSymbol, SetBridgeCopyTradeSymbol] = useState("")
    const [BridgeCopyTradeBrokerSymbol, SetBridgeCopyTradeBrokerSymbol] = useState("")
    const [BridgeCopyTradeQty, SetBridgeCopyTradeQty] = useState(1)
    const [BridgeCopyTradeExchange, SetBridgeCopyTradeExchange] = useState("")
    const [BridgeCopyTradeToken, SetBridgeCopyTradeToken] = useState("")

    const [OptionBridge, SetOptionBridge] = useState(true)
    const [OptionBridgeCopyTradeSymbol, SetOptionBridgeCopyTradeSymbol] = useState("")
    const [OptionBridgeCopyTradeQty, SetOptionBridgeCopyTradeQty] = useState(1)
    const [OptionBridgeDay, SetOptionBridgeDay] = useState(0);
    const [OptionBridgeMonth, SetOptionBridgeMonth] = useState(0);
    const [OptionBridgeYear, SetOptionBridgeYear] = useState(0);

    const [CopyTradeBook, SetCopyTradeBook] = useState(false)

    const [SettingsTradeSymbolsData, SetSettingsTradeSymbolsData] = useState(null)

    const [apiSuccess, SetapiSuccess] = useState(null)

    const [apiError, SetapiError] = useState(null)

    const [SeachSymbolsData, SetSeachSymbolsData] = useState(null)

    const [role, Setrole] = useState(null)

    const [NewPassword, SetNewPassword] = useState("")

    const [NewPasswordClass, SetNewPasswordClass] = useState("")

    const [ConfirmPassword, SetConfirmPassword] = useState("")

    const [ConfirmPasswordClass, SetConfirmPasswordClass] = useState("")

    const [BridgeSubscriptionDays, SetBridgeSubscriptionDays] = useState(0)

    const [twoFactor, SettwoFactor] = useState(false)

    function ResetVariables() {
        SetCopyTradeSymbol("");
        SetCopyTradeQty(1);

        SetBridgeCopyTradeSymbol("");
        SetBridgeCopyTradeQty(1);
        SetBridgeCopyTradeExchange("");
        SetBridgeCopyTradeToken("");
        SetBridgeCopyTradeBrokerSymbol("");

        SetOptionBridgeCopyTradeSymbol("");
        SetOptionBridgeCopyTradeQty(1);
        SetOptionBridgeDay(0);
        SetOptionBridgeMonth(0);
        SetOptionBridgeYear(0);

    }

    useEffect(() => {
        if (apiSuccess != null) {
            setTimeout(() => SetapiSuccess(null), 1000)
        }
    }, [apiSuccess]);

    useEffect(() => {
        if (apiError != null) {
            setTimeout(() => SetapiError(null), 1000)
        }
    }, [apiError]);

    useEffect(() => {

        LoadSettings();

        GetBridgeSubscriptions();

    }, []);

    useEffect(() => {
        LoadSettingsTradingSymbol();
    }, []);

    useEffect(() => {
        dispatch(getAuthenticationData());
    }, []);

    useEffect(() => {

        var authUser = localStorage.getItem("authUser");
        if (authUser) { authUser = atob(authUser); }
        var role = JSON.parse(authUser).role;
        Setrole(role);

    }, []);

    function AddSymbol(input) {

        var authUser = localStorage.getItem("authUser");
        if (authUser) { authUser = atob(authUser); }
        var email = JSON.parse(authUser).email;

        var obj = input;

        obj.email = email;

        const headers = {
            'Content-Type': 'application/json'
        };

        axios.post(API_URL + '/user/addSettingSymbols', JSON.stringify(obj), { headers }).then(response => {

            //console.log(response) 

            var output = response.data;

            if (output.result == "success") {

                SetapiSuccess(output.message);

                ResetVariables();
                LoadSettingsTradingSymbol();

            }
            else {
                SetapiError(output.message);
            }

        });

    }

    function DeleteSymbol(symbol, type) {

        var authUser = localStorage.getItem("authUser");
        if (authUser) { authUser = atob(authUser); }
        var email = JSON.parse(authUser).email;

        var obj = {
            "email": email,
            "symbol": symbol,
            "type": type
        };

        const headers = {
            'Content-Type': 'application/json'
        };

        axios.post(API_URL + '/user/deleteSettingSymbols', JSON.stringify(obj), { headers }).then(response => {

            //console.log(response) 

            var output = response.data;

            if (output.result == "success") {

                SetapiSuccess(output.message);

                LoadSettingsTradingSymbol();

            }
            else {
                SetapiError(output.message);
            }

        });

    }

    function LoadSettingsTradingSymbol() {

        var authUser = localStorage.getItem("authUser");
        if (authUser) { authUser = atob(authUser); }
        var email = JSON.parse(authUser).email;

        var obj = {
            "email": email
        };

        const headers = {
            'Content-Type': 'application/json'
        };

        axios.post(API_URL + '/user/getSettingSymbols', JSON.stringify(obj), { headers }).then(response => {

            //console.log(response)

            var output = response.data;

            if (output.result == "success") {

                SetSettingsTradeSymbolsData(output.data);

            }
            else {
                SetapiError(output.message);
            }

        });

    }

    function addSettings(key, value) {

        var authUser = localStorage.getItem("authUser");
        if (authUser) { authUser = atob(authUser); }
        var email = JSON.parse(authUser).email;

        var obj = {
            "email": email,
            "key": key,
            "value": value
        };

        const headers = {
            'Content-Type': 'application/json'
        };

        axios.post(API_URL + '/user/updateUserSettings', JSON.stringify(obj), { headers }).then(response => {

            //console.log(response) 

            var output = response.data;

            if (output.result == "success") {

                SetapiSuccess(output.message);

                LoadSettings();

            }
            else {
                SetapiError(output.message);
            }

        });

    }

    function LoadSettings() {
        var authUser = localStorage.getItem("authUser");
        if (authUser) { authUser = atob(authUser); }
        var email = JSON.parse(authUser).email;

        var obj = {
            "email": email
        };

        const headers = {
            'Content-Type': 'application/json'
        };

        axios.post(API_URL + '/user/getAuthenticationData', JSON.stringify(obj), { headers }).then(response => {

            //console.log(response) 

            var output = response.data;

            if (output.result == "success") {

                var result = {
                    "Multiplier": output.data.Multiplier || 1,
                    "CopyAllTrades": output.data.CopyAllTrades || true,
                    "CopySelectedSymbols": output.data.CopySelectedSymbols || false,
                    "CopyReverse": output.data.CopyReverse || false,
                    "BridgeCopyFuturesTrades": output.data.BridgeCopyFuturesTrades || false,
                    "BridgeCopyCashTrades": output.data.BridgeCopyCashTrades || false,
                    "Bridge": output.data.Bridge || true,
                    "OptionBridge": output.data.OptionBridge || true,
                    "CopyTradeBook": output.data.CopyTradeBook || true,
                    "twoFactor": output.data.twoFactor || false,
                }

                //alert(JSON.stringify(result));

                SetMultiplier(result.Multiplier);
                SetCopyAllTrades((result.CopyAllTrades == "true") ? true : false);
                SetCopySelectedSymbols((result.CopySelectedSymbols == "true") ? true : false);
                SetCopyReverse((result.CopyReverse == "true") ? true : false);

                SetBridge((result.Bridge == "true") ? true : false);
                SetBridgeCopyFuturesTrades((result.BridgeCopyFuturesTrades == "true") ? true : false)
                SetBridgeCopyCashTrades((result.BridgeCopyCashTrades == "true") ? true : false)

                SetOptionBridge((result.OptionBridge == "true") ? true : false);

                SetCopyTradeBook((result.CopyTradeBook == "true") ? true : false);

                SettwoFactor((result.twoFactor == "true") ? true : false);

                //console.log("Multiplier="+{Multiplier});

                //console.log("CopyAllTrades="+{CopyAllTrades});

                //console.log("CopySelectedSymbols="+{CopySelectedSymbols});

            }
            else {
                SetapiError(output.message);
            }

        });
    }

    function GetBridgeSubscriptions() {
        var authUser = localStorage.getItem("authUser");
        if (authUser) { authUser = atob(authUser); }
        var email = JSON.parse(authUser).email;

        var obj = {
            "email": email
        };

        const headers = {
            'Content-Type': 'application/json'
        };

        axios.post(API_URL + '/user/getBridgeSubscriptions', JSON.stringify(obj), { headers }).then(response => {

            //console.log(response) 

            var output = response.data;

            if (output.result == "success") {

                var daysLeft = days_between(new Date(), new Date(output.data.expired_at));

                SetBridgeSubscriptionDays(daysLeft);

            }
            else {
                SetapiError("Bridge Subscription Not Activated !!!");
            }

        });
    }

    function days_between(date1, date2) {

        // The number of milliseconds in one day
        const ONE_DAY = 1000 * 60 * 60 * 24;

        // Calculate the difference in milliseconds
        const differenceMs = (date2 - date1);

        // Convert back to days and return
        return Math.round(differenceMs / ONE_DAY);

    }

    function searchSymbols(symbol) {
        if (symbol.length > 2) {

            var obj = {
                "symbol": symbol
            };

            const headers = {
                'Content-Type': 'application/json'
            };

            axios.post(API_URL + '/trading/getSymbols', JSON.stringify(obj), { headers }).then(response => {

                //console.log(response) 

                var output = response.data;

                if (output.result == "success") {
                    SetSeachSymbolsData(output.data);
                }
                else {
                    SetSeachSymbolsData(null);
                }
            });

        }
        else {
            SetSeachSymbolsData(null);
        }
    }

    function CreatePayment() {

       

    }

    function redirectPost(url, payload) {
        var form = document.createElement('form');
        document.body.appendChild(form);
        form.method = 'post';
        form.action = url;

        var input = document.createElement('input');
        input.type = 'hidden';
        input.name = "request";
        input.value = payload;
        form.appendChild(input);

        // for (var name in data) {
        //     var input = document.createElement('input');
        //     input.type = 'hidden';
        //     input.name = name;
        //     input.value = data[name];
        //     form.appendChild(input);
        // }
        form.submit();
    }

    function changePassword() {

        var authUser = localStorage.getItem("authUser");
        if (authUser) { authUser = atob(authUser); }
        var email = JSON.parse(authUser).email;

        var obj = {
            "email": email,
            "password": NewPassword
        };

        const headers = {
            'Content-Type': 'application/json'
        };

        axios.post(API_URL + '/user/changeUserPwd', JSON.stringify(obj), { headers }).then(response => {

            //console.log(response) 

            var output = response.data;

            if (output.result == "success") {

                SetapiSuccess(output.message);

                SetNewPassword("");

                SetConfirmPassword("");

            }
            else {
                SetapiError(output.message);
            }

        });

    }

    const Month_Names = ["-", "JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];


    function changeTwoFactor(event) {

        var authUser = localStorage.getItem("authUser");
        if (authUser) { authUser = atob(authUser); }
        var email = JSON.parse(authUser).email;

        var obj = {
            "email": email,
            "twoFactor": event,
        };

        const headers = {
            'Content-Type': 'application/json'
        };

        axios.post(API_URL + '/user/twoFactor-event', JSON.stringify(obj), { headers }).then(response => {

            //console.log(response) 

            var output = response.data;

            if (output.result == "success") {
                SetapiSuccess(output.message);
            }
            else {
                SetapiError(output.message);
            }
        });
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>

                    {apiSuccess && (
                        <div
                            className="alert alert-success text-center"
                            role="alert">
                            {apiSuccess}
                        </div>
                    )}

                    {apiError && (
                        <div
                            className="alert alert-danger text-center"
                            role="alert">
                            {apiError}
                        </div>
                    )}

                    <Row>

                        <Col lg={6}>


                            <Col lg={12}>
                                <Card>
                                    <div>
                                        <Row>
                                            <Col lg="9" sm="8">
                                                <div className="p-4">
                                                    <h5 className="text-primary">Want to automate your strategy?</h5>
                                                    <p>It will allow you to trade via MT4, Trading view, Amibroker, etc.</p>

                                                    <div className="text-muted">
                                                        <p className="mb-1">
                                                            <i className="mdi mdi-circle-medium align-middle text-primary me-1" />{" "}
                                                            Easy to Use
                                                        </p>
                                                        <p className="mb-1">
                                                            <i className="mdi mdi-circle-medium align-middle text-primary me-1" />{" "}
                                                            Trade with Zero Latency
                                                        </p>
                                                    </div>
                                                    <div className="mt-2" style={{ textAlign: "Left" }}>
                                                        <button type="button" className="btn btn-primary w-md" onClick={() => { CreatePayment() }}>
                                                            Buy Now
                                                        </button>

                                                        <span className="ms-3" style={BridgeSubscriptionDays > 0 ? { "color": "green" } : { "color": "red" }}  >{BridgeSubscriptionDays} Days Left</span>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col lg="3" sm="4" className="align-self-center">
                                                <div>
                                                    <img src={features} alt="" className="img-fluid d-block" />
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Card>
                            </Col>

                            {role == "master" && (
                                <Col lg={12}>
                                    <Card>
                                        <CardBody>
                                            <Row>
                                                <Col md={6}>
                                                    <CardTitle>Copy Tradebook</CardTitle>
                                                </Col>

                                                <Col md={6}>
                                                    <div className="float-end form-check form-switch form-switch-md">
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            id="chk-all-symbols"
                                                            checked={CopyTradeBook}
                                                            onChange={e => { }}
                                                            onClick={e => {
                                                                //alert(!e.target.checked);
                                                                SetCopyTradeBook(!e.target.checked);
                                                                addSettings("CopyTradeBook", (!e.target.checked).toString());
                                                            }}
                                                        />
                                                    </div>
                                                </Col>

                                            </Row>


                                        </CardBody>
                                    </Card>
                                </Col>
                            )}

                            {role == "user" && (
                                <Col lg={12}>
                                    <Card>
                                        <CardBody>
                                            <CardTitle className="mb-2">Copy Trading</CardTitle>


                                            <Row className="mb-2">

                                                <Col className="mt-4" md={4}>
                                                    <div className="form-check form-switch form-switch-md mb-3">
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            id="chk-all-symbols"
                                                            checked={CopyAllTrades}
                                                            onChange={e => { }}
                                                            onClick={e => {
                                                                //alert(!e.target.checked);
                                                                SetCopyAllTrades(!e.target.checked);
                                                                addSettings("CopyAllTrades", (!e.target.checked).toString());
                                                            }}
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="chk-all-symbols">
                                                            Copy All
                                                        </label>
                                                    </div>
                                                </Col>

                                                <Col className="mt-4" md={4}>
                                                    <div className="form-check form-switch form-switch-md mb-3">
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            id="chk-custom-symbols"
                                                            checked={CopySelectedSymbols}
                                                            onChange={e => { }}
                                                            onClick={e => {
                                                                //alert(!e.target.checked);
                                                                SetCopySelectedSymbols(!e.target.checked);
                                                                addSettings("CopySelectedSymbols", (!e.target.checked).toString());
                                                            }}
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="chk-custom-symbols">
                                                            Copy Custom
                                                        </label>
                                                    </div>
                                                </Col>

                                                <Col className="mt-4" md={4}>
                                                    <div className="form-check form-switch form-switch-md mb-3">
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            id="chk-custom-symbols"
                                                            checked={CopyReverse}
                                                            onChange={e => { }}
                                                            onClick={e => {
                                                                //alert(!e.target.checked);
                                                                SetCopyReverse(!e.target.checked);
                                                                addSettings("CopyReverse", (!e.target.checked).toString());
                                                            }}
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="chk-custom-symbols">
                                                            Copy Reverse
                                                        </label>
                                                    </div>
                                                </Col>

                                            </Row>

                                            <Row className="mb-2">

                                                <Col className="mb-2" md={9}>

                                                    <h5 className="font-size-14 mb-3 mt-0">Multiplier</h5>
                                                    <span className="float-left mt-4">1 - 40</span>{" "}
                                                    <Slider
                                                        min={1}
                                                        max={40}
                                                        orientation="horizontal"
                                                        value={Multiplier}
                                                        onChange={value => {
                                                            //console.log(value);
                                                            SetMultiplier(value);
                                                            if (LastMultiplier != value) {
                                                                //console.log(value);
                                                                SetLastMultiplier(value);
                                                                addSettings("Multiplier", value);
                                                            }
                                                        }}
                                                    />

                                                </Col>

                                            </Row>

                                            <Row className="mb-2">

                                                <Form className="row" onSubmit={(e) => {
                                                    e.preventDefault();

                                                    if (parseInt(CopyTradeQty) > 0 && CopyTradeSymbol != "") {
                                                        var input = {
                                                            "symbol": CopyTradeSymbol,
                                                            "qty": parseInt(CopyTradeQty),
                                                            "type": "copytrades"
                                                        }
                                                        //console.log(input);
                                                        AddSymbol(input);
                                                    }
                                                }
                                                }>
                                                    <Col className="mb-2" md={4}>
                                                        <select className="form-select" value={CopyTradeSymbol} required={true} onChange={e => {
                                                            //alert(e.target.value);
                                                            SetCopyTradeSymbol(e.target.value);
                                                        }}>
                                                            <option value="">Select Symbol</option>
                                                            <option value="BANKNIFTY">BANKNIFTY</option>
                                                            <option value="NIFTY">NIFTY</option>
                                                            <option value="ABBOTINDIA">ABBOTINDIA</option>
                                                            <option value="ABCAPITAL">ABCAPITAL</option>
                                                            <option value="ACC">ACC</option>
                                                            <option value="ADANIPORTS">ADANIPORTS</option>
                                                            <option value="ALKEM">ALKEM</option>
                                                            <option value="AMARAJABAT">AMARAJABAT</option>
                                                            <option value="AMBUJACEM">AMBUJACEM</option>
                                                            <option value="APOLLOTYRE">APOLLOTYRE</option>
                                                            <option value="ATUL">ATUL</option>
                                                            <option value="AXISBANK">AXISBANK</option>
                                                            <option value="BAJAJ-AUTO">BAJAJ-AUTO</option>
                                                            <option value="AARTIIND">AARTIIND</option>
                                                            <option value="BALRAMCHIN">BALRAMCHIN</option>
                                                            <option value="BANKBARODA">BANKBARODA</option>
                                                            <option value="BEL">BEL</option>
                                                            <option value="BERGEPAINT">BERGEPAINT</option>
                                                            <option value="BHARATFORG">BHARATFORG</option>
                                                            <option value="BHARTIARTL">BHARTIARTL</option>
                                                            <option value="BHEL">BHEL</option>
                                                            <option value="BIOCON">BIOCON</option>
                                                            <option value="BPCL">BPCL</option>
                                                            <option value="BRITANNIA">BRITANNIA</option>
                                                            <option value="BSOFT">BSOFT</option>
                                                            <option value="CANBK">CANBK</option>
                                                            <option value="CANFINHOME">CANFINHOME</option>
                                                            <option value="COALINDIA">COALINDIA</option>
                                                            <option value="COFORGE">COFORGE</option>
                                                            <option value="CONCOR">CONCOR</option>
                                                            <option value="CROMPTON">CROMPTON</option>
                                                            <option value="CUB">CUB</option>
                                                            <option value="DABUR">DABUR</option>
                                                            <option value="DALBHARAT">DALBHARAT</option>
                                                            <option value="DEEPAKNTR">DEEPAKNTR</option>
                                                            <option value="DELTACORP">DELTACORP</option>
                                                            <option value="DIVISLAB">DIVISLAB</option>
                                                            <option value="DLF">DLF</option>
                                                            <option value="DRREDDY">DRREDDY</option>
                                                            <option value="ESCORTS">ESCORTS</option>
                                                            <option value="FEDERALBNK">FEDERALBNK</option>
                                                            <option value="GAIL">GAIL</option>
                                                            <option value="GMRINFRA">GMRINFRA</option>
                                                            <option value="GNFC">GNFC</option>
                                                            <option value="GODREJCP">GODREJCP</option>
                                                            <option value="GRANULES">GRANULES</option>
                                                            <option value="GRASIM">GRASIM</option>
                                                            <option value="GSPL">GSPL</option>
                                                            <option value="GUJGASLTD">GUJGASLTD</option>
                                                            <option value="HAL">HAL</option>
                                                            <option value="HCLTECH">HCLTECH</option>
                                                            <option value="HDFCAMC">HDFCAMC</option>
                                                            <option value="ASHOKLEY">ASHOKLEY</option>
                                                            <option value="HDFCLIFE">HDFCLIFE</option>
                                                            <option value="HEROMOTOCO">HEROMOTOCO</option>
                                                            <option value="HINDALCO">HINDALCO</option>
                                                            <option value="HINDCOPPER">HINDCOPPER</option>
                                                            <option value="HINDPETRO">HINDPETRO</option>
                                                            <option value="HONAUT">HONAUT</option>
                                                            <option value="ICICIBANK">ICICIBANK</option>
                                                            <option value="ICICIGI">ICICIGI</option>
                                                            <option value="ICICIPRULI">ICICIPRULI</option>
                                                            <option value="IDFC">IDFC</option>
                                                            <option value="IGL">IGL</option>
                                                            <option value="INDHOTEL">INDHOTEL</option>
                                                            <option value="INDIACEM">INDIACEM</option>
                                                            <option value="INFY">INFY</option>
                                                            <option value="INTELLECT">INTELLECT</option>
                                                            <option value="IRCTC">IRCTC</option>
                                                            <option value="JINDALSTEL">JINDALSTEL</option>
                                                            <option value="JUBLFOOD">JUBLFOOD</option>
                                                            <option value="KOTAKBANK">KOTAKBANK</option>
                                                            <option value="LALPATHLAB">LALPATHLAB</option>
                                                            <option value="LAURUSLABS">LAURUSLABS</option>
                                                            <option value="LICHSGFIN">LICHSGFIN</option>
                                                            <option value="LTI">LTI</option>
                                                            <option value="LUPIN">LUPIN</option>
                                                            <option value="MARICO">MARICO</option>
                                                            <option value="MARUTI">MARUTI</option>
                                                            <option value="METROPOLIS">METROPOLIS</option>
                                                            <option value="MFSL">MFSL</option>
                                                            <option value="MGL">MGL</option>
                                                            <option value="MPHASIS">MPHASIS</option>
                                                            <option value="MUTHOOTFIN">MUTHOOTFIN</option>
                                                            <option value="NAM-INDIA">NAM-INDIA</option>
                                                            <option value="NATIONALUM">NATIONALUM</option>
                                                            <option value="NAVINFLUOR">NAVINFLUOR</option>
                                                            <option value="NESTLEIND">NESTLEIND</option>
                                                            <option value="NMDC">NMDC</option>
                                                            <option value="NTPC">NTPC</option>
                                                            <option value="ONGC">ONGC</option>
                                                            <option value="PAGEIND">PAGEIND</option>
                                                            <option value="PETRONET">PETRONET</option>
                                                            <option value="PFC">PFC</option>
                                                            <option value="PIDILITIND">PIDILITIND</option>
                                                            <option value="PIIND">PIIND</option>
                                                            <option value="POWERGRID">POWERGRID</option>
                                                            <option value="RAIN">RAIN</option>
                                                            <option value="RELIANCE">RELIANCE</option>
                                                            <option value="SAIL">SAIL</option>
                                                            <option value="SBICARD">SBICARD</option>
                                                            <option value="SBIN">SBIN</option>
                                                            <option value="SHREECEM">SHREECEM</option>
                                                            <option value="SUNPHARMA">SUNPHARMA</option>
                                                            <option value="SUNTV">SUNTV</option>
                                                            <option value="SYNGENE">SYNGENE</option>
                                                            <option value="TATACHEM">TATACHEM</option>
                                                            <option value="TATACOMM">TATACOMM</option>
                                                            <option value="TATACONSUM">TATACONSUM</option>
                                                            <option value="TATAPOWER">TATAPOWER</option>
                                                            <option value="TATASTEEL">TATASTEEL</option>
                                                            <option value="TECHM">TECHM</option>
                                                            <option value="TORNTPOWER">TORNTPOWER</option>
                                                            <option value="TVSMOTOR">TVSMOTOR</option>
                                                            <option value="VOLTAS">VOLTAS</option>
                                                            <option value="WIPRO">WIPRO</option>
                                                            <option value="ZEEL">ZEEL</option>
                                                            <option value="ZYDUSLIFE">ZYDUSLIFE</option>
                                                            <option value="IPCALAB">IPCALAB</option>
                                                            <option value="PEL">PEL</option>
                                                            <option value="TORNTPHARM">TORNTPHARM</option>
                                                            <option value="ABFRL">ABFRL</option>
                                                            <option value="APOLLOHOSP">APOLLOHOSP</option>
                                                            <option value="ASIANPAINT">ASIANPAINT</option>
                                                            <option value="ASTRAL">ASTRAL</option>
                                                            <option value="AUBANK">AUBANK</option>
                                                            <option value="AUROPHARMA">AUROPHARMA</option>
                                                            <option value="BAJFINANCE">BAJFINANCE</option>
                                                            <option value="BALKRISIND">BALKRISIND</option>
                                                            <option value="BATAINDIA">BATAINDIA</option>
                                                            <option value="COLPAL">COLPAL</option>
                                                            <option value="DIXON">DIXON</option>
                                                            <option value="EICHERMOT">EICHERMOT</option>
                                                            <option value="FSL">FSL</option>
                                                            <option value="GLENMARK">GLENMARK</option>
                                                            <option value="GODREJPROP">GODREJPROP</option>
                                                            <option value="HAVELLS">HAVELLS</option>
                                                            <option value="HDFCBANK">HDFCBANK</option>
                                                            <option value="IDFCFIRSTB">IDFCFIRSTB</option>
                                                            <option value="IEX">IEX</option>
                                                            <option value="INDUSINDBK">INDUSINDBK</option>
                                                            <option value="ITC">ITC</option>
                                                            <option value="JKCEMENT">JKCEMENT</option>
                                                            <option value="L&TFH">L&TFH</option>
                                                            <option value="LTTS">LTTS</option>
                                                            <option value="MCDOWELL-N">MCDOWELL-N</option>
                                                            <option value="MINDTREE">MINDTREE</option>
                                                            <option value="MOTHERSON">MOTHERSON</option>
                                                            <option value="CIPLA">CIPLA</option>
                                                            <option value="NAUKRI">NAUKRI</option>
                                                            <option value="NBCC">NBCC</option>
                                                            <option value="OBEROIRLTY">OBEROIRLTY</option>
                                                            <option value="PERSISTENT">PERSISTENT</option>
                                                            <option value="POLYCAB">POLYCAB</option>
                                                            <option value="RBLBANK">RBLBANK</option>
                                                            <option value="SBILIFE">SBILIFE</option>
                                                            <option value="SIEMENS">SIEMENS</option>
                                                            <option value="TATAMOTORS">TATAMOTORS</option>
                                                            <option value="TITAN">TITAN</option>
                                                            <option value="UBL">UBL</option>
                                                            <option value="ULTRACEMCO">ULTRACEMCO</option>
                                                            <option value="UPL">UPL</option>
                                                            <option value="WHIRLPOOL">WHIRLPOOL</option>
                                                            <option value="M&M">M&M</option>
                                                            <option value="MRF">MRF</option>
                                                            <option value="OFSS">OFSS</option>
                                                            <option value="PVR">PVR</option>
                                                            <option value="BAJAJFINSV">BAJAJFINSV</option>
                                                            <option value="BANDHANBNK">BANDHANBNK</option>
                                                            <option value="ADANIENT">ADANIENT</option>
                                                            <option value="BOSCHLTD">BOSCHLTD</option>
                                                            <option value="CHAMBLFERT">CHAMBLFERT</option>
                                                            <option value="CHOLAFIN">CHOLAFIN</option>
                                                            <option value="CUMMINSIND">CUMMINSIND</option>
                                                            <option value="HINDUNILVR">HINDUNILVR</option>
                                                            <option value="INDIAMART">INDIAMART</option>
                                                            <option value="INDIGO">INDIGO</option>
                                                            <option value="JSWSTEEL">JSWSTEEL</option>
                                                            <option value="M&MFIN">M&MFIN</option>
                                                            <option value="MANAPPURAM">MANAPPURAM</option>
                                                            <option value="INDUSTOWER">INDUSTOWER</option>
                                                            <option value="ABB">ABB</option>
                                                            <option value="PNB">PNB</option>
                                                            <option value="RAMCOCEM">RAMCOCEM</option>
                                                            <option value="RECLTD">RECLTD</option>
                                                            <option value="SRF">SRF</option>
                                                            <option value="TCS">TCS</option>
                                                            <option value="EXIDEIND">EXIDEIND</option>
                                                            <option value="TRENT">TRENT</option>
                                                            <option value="VEDL">VEDL</option>
                                                            <option value="COROMANDEL">COROMANDEL</option>
                                                            <option value="IDEA">IDEA</option>
                                                            <option value="GOLD">GOLD</option>
                                                            <option value="GOLDM">GOLDM</option>
                                                            <option value="GOLDGUINEA">GOLDGUINEA</option>
                                                            <option value="SILVER">SILVER</option>
                                                            <option value="SILVERM">SILVERM</option>
                                                            <option value="SILVERMIC">SILVERMIC</option>
                                                            <option value="COPPER">COPPER</option>
                                                            <option value="CRUDEOIL">CRUDEOIL</option>
                                                            <option value="NICKEL">NICKEL</option>
                                                            <option value="ZINC">ZINC</option>
                                                            <option value="ALUMINUM">ALUMINUM</option>
                                                            <option value="LEAD">LEAD</option>
                                                        </select>
                                                    </Col>

                                                    <Col className="mb-2" md={2}>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            placeholder="Qty"
                                                            onKeyPress={(event) => {
                                                                if (!/[0-9]/.test(event.key)) {
                                                                    event.preventDefault();
                                                                }
                                                            }}
                                                            value={CopyTradeQty}
                                                            onChange={(val) => {
                                                                if (val != "") {
                                                                    SetCopyTradeQty(val.target.value)
                                                                }
                                                            }}
                                                            required={true}
                                                        />
                                                    </Col>

                                                    <Col className="mb-2" md={4}>
                                                        <button type="submit" className="btn btn-primary me-2">
                                                            Add Symbol
                                                        </button>
                                                    </Col>
                                                </Form>
                                            </Row>

                                            {SettingsTradeSymbolsData && SettingsTradeSymbolsData.length > 0 && (
                                                <Row className="mt-4 mb-2">
                                                    <Col md={12}>

                                                        <Table className="table table-striped table-bordered">
                                                            <Thead>
                                                                <Tr>
                                                                    <Th data-priority="1">#</Th>
                                                                    <Th data-priority="2">Symbol</Th>
                                                                    <Th data-priority="3">Qty</Th>
                                                                    <Th data-priority="4">Acton</Th>
                                                                </Tr>
                                                            </Thead>
                                                            <Tbody>

                                                                {SettingsTradeSymbolsData && SettingsTradeSymbolsData.map((data, key) => (

                                                                    data.type == "copytrades" && (

                                                                        <Tr key={key}>
                                                                            <Td>
                                                                                {key + 1}
                                                                            </Td>
                                                                            <Td>
                                                                                {data.symbol}
                                                                            </Td>
                                                                            <Td>
                                                                                {data.qty}
                                                                            </Td>
                                                                            <Td>
                                                                                <button type="button" className="btn btn-sm btn-danger"
                                                                                    onClick={() => { DeleteSymbol(data.symbol, data.type) }}
                                                                                >
                                                                                    <i className="bx bx-trash-alt font-size-14 align-middle"></i>
                                                                                </button>
                                                                            </Td>
                                                                        </Tr>

                                                                    )

                                                                ))}
                                                            </Tbody>
                                                        </Table>

                                                    </Col>
                                                </Row>
                                            )}
                                        </CardBody>
                                    </Card>
                                </Col>
                            )}

                            <Col lg={12}>
                                <Card>
                                    <CardBody>
                                        <CardTitle className="mb-2">Change Password</CardTitle>
                                        <Row className="mb-2">

                                            <Form className="row" onSubmit={(e) => {
                                                e.preventDefault();
                                                if (NewPassword == ConfirmPassword) {
                                                    changePassword();
                                                }
                                                else {
                                                    SetNewPasswordClass("border-danger");
                                                    SetConfirmPasswordClass("border-danger");
                                                }
                                            }}>

                                                <Col className="mb-2" md={6}>
                                                    <div className="form-floating mb-3">
                                                        <input
                                                            className={"form-control " + NewPasswordClass}
                                                            type="text"
                                                            placeholder="New Password"
                                                            value={NewPassword}
                                                            onChange={(val) => {
                                                                if (val != "") {
                                                                    SetNewPassword(val.target.value);
                                                                    if (val.target.value != ConfirmPassword) {
                                                                        SetNewPasswordClass("border-danger");
                                                                        SetConfirmPasswordClass("border-danger");
                                                                    }
                                                                    else {
                                                                        SetNewPasswordClass("border-success");
                                                                        SetConfirmPasswordClass("border-success");
                                                                    }
                                                                }
                                                            }}
                                                            required={true}
                                                        />
                                                        <label htmlFor="floatingSelectGrid">New Password</label>
                                                    </div>
                                                </Col>

                                                <Col className="mb-2" md={6}>
                                                    <div className="form-floating mb-3">
                                                        <input
                                                            className={"form-control " + ConfirmPasswordClass}
                                                            type="text"
                                                            placeholder="Confirm Password"
                                                            value={ConfirmPassword}
                                                            onChange={(val) => {
                                                                if (val != "") {
                                                                    SetConfirmPassword(val.target.value);
                                                                    if (NewPassword != val.target.value) {
                                                                        SetNewPasswordClass("border-danger");
                                                                        SetConfirmPasswordClass("border-danger");
                                                                    }
                                                                    else {
                                                                        SetNewPasswordClass("border-success");
                                                                        SetConfirmPasswordClass("border-success");
                                                                    }
                                                                }
                                                            }}
                                                            required={true}
                                                        />
                                                        <label htmlFor="floatingSelectGrid">Confirm Password</label>
                                                    </div>
                                                </Col>

                                                <Col className="mb-2" md={12} style={{ "paddingTop": "10px" }}>
                                                    <button type="submit" className="btn btn-primary float-end">
                                                        Change Password
                                                    </button>
                                                </Col>

                                            </Form>

                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>

                        </Col>

                        <Col lg={6}>

                            <Col lg={12}>
                                <Card>
                                    <CardBody>

                                        <Row className="mb-2">
                                            <Col md={6}>
                                                <CardTitle className="mb-2">Two Factor Authentication</CardTitle>
                                            </Col>

                                            <Col md={6}>
                                                <div className="float-end form-check form-switch form-switch-md mb-3">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        id="chk-twoFactor"
                                                        checked={twoFactor}
                                                        onChange={e => { }}
                                                        onClick={e => {
                                                            SettwoFactor(!e.target.checked);
                                                            changeTwoFactor((!e.target.checked).toString());
                                                        }}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="chk-all-symbols">
                                                        ON
                                                    </label>
                                                </div>
                                            </Col>

                                        </Row>

                                    </CardBody>
                                </Card>
                            </Col>

                            <Col lg={12}>
                                <Card>
                                    <CardBody>

                                        <Row>
                                            <Col md={6}>
                                                <CardTitle className="mb-2">Bridge Tool</CardTitle>
                                            </Col>

                                            <Col md={6}>
                                                <div className="float-end form-check form-switch form-switch-md mb-3">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        id="chk-all-symbols"
                                                        checked={Bridge}
                                                        onChange={e => { }}
                                                        onClick={e => {
                                                            //alert(!e.target.checked);
                                                            SetBridge(!e.target.checked);
                                                            addSettings("Bridge", (!e.target.checked).toString());
                                                        }}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="chk-all-symbols">
                                                        Bridge
                                                    </label>
                                                </div>
                                            </Col>

                                        </Row>

                                        <Row className="mb-2">

                                            <Col className="mt-4" md={4}>
                                                <div className="form-check form-switch form-switch-md mb-3">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        id="chk-all-symbols"
                                                        checked={BridgeCopyCashTrades}
                                                        onChange={e => { }}
                                                        onClick={e => {
                                                            //alert(!e.target.checked);
                                                            SetBridgeCopyCashTrades(!e.target.checked);
                                                            addSettings("BridgeCopyCashTrades", (!e.target.checked).toString());
                                                        }}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="chk-all-symbols">
                                                        Cash
                                                    </label>
                                                </div>
                                            </Col>

                                            <Col className="mt-4" md={6}>
                                                <div className="form-check form-switch form-switch-md mb-3">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        id="chk-custom-symbols"
                                                        checked={BridgeCopyFuturesTrades}
                                                        onChange={e => { }}
                                                        onClick={e => {
                                                            //alert(!e.target.checked);
                                                            SetBridgeCopyFuturesTrades(!e.target.checked);
                                                            addSettings("BridgeCopyFuturesTrades", (!e.target.checked).toString());
                                                        }}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="chk-custom-symbols">
                                                        Futures
                                                    </label>
                                                </div>
                                            </Col>

                                        </Row>

                                        <Row className="mb-2">

                                            <Form className="row" onSubmit={(e) => {
                                                e.preventDefault();

                                                if (parseInt(BridgeCopyTradeQty) > 0 && BridgeCopyTradeSymbol != "" && BridgeCopyTradeExchange != "" && BridgeCopyTradeToken != "" && BridgeCopyTradeBrokerSymbol != "") {
                                                    var input = {
                                                        "symbol": BridgeCopyTradeSymbol,
                                                        "trading_symbol": BridgeCopyTradeBrokerSymbol,
                                                        "qty": parseInt(BridgeCopyTradeQty),
                                                        "exchange": BridgeCopyTradeExchange,
                                                        "token": BridgeCopyTradeToken,
                                                        "type": "bridge"
                                                    }
                                                    //console.log(input);
                                                    AddSymbol(input);
                                                }
                                            }
                                            }>
                                                <Col className="mb-2" md={6}>
                                                    <div className="position-relative">
                                                        <Input
                                                            onChange={(e) => { SetBridgeCopyTradeSymbol(e.target.value); searchSymbols(e.target.value); }}
                                                            id="search-symbol"
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Search..."
                                                            value={BridgeCopyTradeSymbol}
                                                        />
                                                    </div>
                                                    <div className="position-relative">
                                                        <ul style={{ backgroundColor: "white", zIndex: 1 }} className="position-absolute list-unstyled chat-list" id="recent-list">
                                                            <PerfectScrollbar style={{ height: "410px" }}>

                                                                {map(SeachSymbolsData, symbol => (

                                                                    <li
                                                                        key={symbol.code} className="active mb-2" onClick={() => {

                                                                            SetBridgeCopyTradeSymbol(symbol.symbol);
                                                                            SetBridgeCopyTradeExchange(symbol.exchange);
                                                                            SetBridgeCopyTradeToken(symbol.code);
                                                                            SetBridgeCopyTradeBrokerSymbol(symbol.trading_symbol);
                                                                            SetSeachSymbolsData(null);

                                                                        }}
                                                                    >
                                                                        <Link
                                                                            to="#"
                                                                        >
                                                                            <div style={{ "position": "relative" }}>
                                                                                <div className="d-flex">
                                                                                    <div className="align-self-center me-3">
                                                                                        <img
                                                                                            src={images["marketwatch"]}
                                                                                            className="rounded-circle avatar-xs"
                                                                                            alt=""
                                                                                        />
                                                                                    </div>

                                                                                    <div className="flex-grow-1 overflow-hidden">
                                                                                        <h5 className="text-truncate font-size-14 mb-1">
                                                                                            {symbol.trading_symbol}
                                                                                        </h5>
                                                                                        <p className="text-truncate mb-0">
                                                                                            {symbol.symbol}
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </Link>
                                                                    </li>
                                                                ))}
                                                            </PerfectScrollbar>
                                                        </ul>
                                                    </div>
                                                </Col>
                                                <Col className="mb-2" md={2}>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        placeholder="Qty"
                                                        onKeyPress={(event) => {
                                                            if (!/[0-9]/.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                        }}
                                                        value={BridgeCopyTradeQty}
                                                        onChange={(val) => {
                                                            if (val != "") {
                                                                SetBridgeCopyTradeQty(val.target.value)
                                                            }
                                                        }}
                                                        required={true}
                                                    />
                                                </Col>
                                                <Col className="mb-2" md={4}>
                                                    <button type="submit" className="btn btn-primary me-2">
                                                        Add Symbol
                                                    </button>
                                                </Col>
                                            </Form>
                                        </Row>

                                        {SettingsTradeSymbolsData && SettingsTradeSymbolsData.length > 0 && (
                                            <Row className="mt-4 mb-2">
                                                <Col md={12}>

                                                    <Table className="table table-striped table-bordered">
                                                        <Thead>
                                                            <Tr>
                                                                <Th data-priority="1">#</Th>
                                                                <Th data-priority="2">Symbol</Th>
                                                                <Th data-priority="3">Qty</Th>
                                                                <Th data-priority="4">Acton</Th>
                                                            </Tr>
                                                        </Thead>
                                                        <Tbody>

                                                            {SettingsTradeSymbolsData && SettingsTradeSymbolsData.map((data, key) => (

                                                                data.type == "bridge" && (

                                                                    <Tr key={key}>
                                                                        <Td>
                                                                            {key + 1}
                                                                        </Td>
                                                                        <Td>
                                                                            {data.symbol}
                                                                        </Td>
                                                                        <Td>
                                                                            {data.qty}
                                                                        </Td>
                                                                        <Td>
                                                                            <button type="button" className="btn btn-sm btn-danger"
                                                                                onClick={() => { DeleteSymbol(data.symbol, data.type) }}
                                                                            >
                                                                                <i className="bx bx-trash-alt font-size-14 align-middle"></i>
                                                                            </button>
                                                                        </Td>
                                                                    </Tr>

                                                                )

                                                            ))}
                                                        </Tbody>
                                                    </Table>

                                                </Col>
                                            </Row>
                                        )}
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col lg={12}>
                                <Card>
                                    <CardBody>
                                        <Row>
                                            <Col md={6}>
                                                <CardTitle className="mb-2">Option Trading Settings</CardTitle>
                                            </Col>

                                            <Col md={6}>
                                                <div className="float-end form-check form-switch form-switch-md mb-3">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        id="chk-all-symbols"
                                                        checked={OptionBridge}
                                                        onChange={e => { }}
                                                        onClick={e => {
                                                            //alert(!e.target.checked);
                                                            SetOptionBridge(!e.target.checked);
                                                            addSettings("OptionBridge", (!e.target.checked).toString());
                                                        }}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="chk-all-symbols">
                                                        Option Bridge
                                                    </label>
                                                </div>
                                            </Col>

                                        </Row>


                                        <Row className="mb-2">

                                            <Form className="row" onSubmit={(e) => {
                                                e.preventDefault();

                                                if (parseInt(OptionBridgeCopyTradeQty) > 0 && OptionBridgeMonth > 0 && OptionBridgeYear > 0) {
                                                    var input = {
                                                        "symbol": OptionBridgeCopyTradeSymbol,
                                                        "day": OptionBridgeDay,
                                                        "month_value": parseInt(OptionBridgeMonth),
                                                        "month_name": Month_Names[parseInt(OptionBridgeMonth)],
                                                        "year": parseInt(OptionBridgeYear),
                                                        "qty": parseInt(OptionBridgeCopyTradeQty),
                                                        "type": "optionbridge"
                                                    }
                                                    //console.log(input);
                                                    AddSymbol(input);
                                                }
                                            }
                                            }>
                                                <Col className="mb-2" md={6}>
                                                    <div className="form-floating mb-3">
                                                        <select value={OptionBridgeCopyTradeSymbol} name="broker" className="form-select" onChange={(val) => SetOptionBridgeCopyTradeSymbol(val.target.value)}>
                                                            <option value="">SELECT</option>
                                                            <option value="BANKNIFTY">BANKNIFTY</option>
                                                            <option value="NIFTY">NIFTY</option>
                                                            <option value="ABBOTINDIA">ABBOTINDIA</option>
                                                            <option value="ABCAPITAL">ABCAPITAL</option>
                                                            <option value="ACC">ACC</option>
                                                            <option value="ADANIPORTS">ADANIPORTS</option>
                                                            <option value="ALKEM">ALKEM</option>
                                                            <option value="AMARAJABAT">AMARAJABAT</option>
                                                            <option value="AMBUJACEM">AMBUJACEM</option>
                                                            <option value="APOLLOTYRE">APOLLOTYRE</option>
                                                            <option value="ATUL">ATUL</option>
                                                            <option value="AXISBANK">AXISBANK</option>
                                                            <option value="BAJAJ-AUTO">BAJAJ-AUTO</option>
                                                            <option value="AARTIIND">AARTIIND</option>
                                                            <option value="BALRAMCHIN">BALRAMCHIN</option>
                                                            <option value="BANKBARODA">BANKBARODA</option>
                                                            <option value="BEL">BEL</option>
                                                            <option value="BERGEPAINT">BERGEPAINT</option>
                                                            <option value="BHARATFORG">BHARATFORG</option>
                                                            <option value="BHARTIARTL">BHARTIARTL</option>
                                                            <option value="BHEL">BHEL</option>
                                                            <option value="BIOCON">BIOCON</option>
                                                            <option value="BPCL">BPCL</option>
                                                            <option value="BRITANNIA">BRITANNIA</option>
                                                            <option value="BSOFT">BSOFT</option>
                                                            <option value="CANBK">CANBK</option>
                                                            <option value="CANFINHOME">CANFINHOME</option>
                                                            <option value="COALINDIA">COALINDIA</option>
                                                            <option value="COFORGE">COFORGE</option>
                                                            <option value="CONCOR">CONCOR</option>
                                                            <option value="CROMPTON">CROMPTON</option>
                                                            <option value="CUB">CUB</option>
                                                            <option value="DABUR">DABUR</option>
                                                            <option value="DALBHARAT">DALBHARAT</option>
                                                            <option value="DEEPAKNTR">DEEPAKNTR</option>
                                                            <option value="DELTACORP">DELTACORP</option>
                                                            <option value="DIVISLAB">DIVISLAB</option>
                                                            <option value="DLF">DLF</option>
                                                            <option value="DRREDDY">DRREDDY</option>
                                                            <option value="ESCORTS">ESCORTS</option>
                                                            <option value="FEDERALBNK">FEDERALBNK</option>
                                                            <option value="GAIL">GAIL</option>
                                                            <option value="GMRINFRA">GMRINFRA</option>
                                                            <option value="GNFC">GNFC</option>
                                                            <option value="GODREJCP">GODREJCP</option>
                                                            <option value="GRANULES">GRANULES</option>
                                                            <option value="GRASIM">GRASIM</option>
                                                            <option value="GSPL">GSPL</option>
                                                            <option value="GUJGASLTD">GUJGASLTD</option>
                                                            <option value="HAL">HAL</option>
                                                            <option value="HCLTECH">HCLTECH</option>
                                                            <option value="HDFCAMC">HDFCAMC</option>
                                                            <option value="ASHOKLEY">ASHOKLEY</option>
                                                            <option value="HDFCLIFE">HDFCLIFE</option>
                                                            <option value="HEROMOTOCO">HEROMOTOCO</option>
                                                            <option value="HINDALCO">HINDALCO</option>
                                                            <option value="HINDCOPPER">HINDCOPPER</option>
                                                            <option value="HINDPETRO">HINDPETRO</option>
                                                            <option value="HONAUT">HONAUT</option>
                                                            <option value="ICICIBANK">ICICIBANK</option>
                                                            <option value="ICICIGI">ICICIGI</option>
                                                            <option value="ICICIPRULI">ICICIPRULI</option>
                                                            <option value="IDFC">IDFC</option>
                                                            <option value="IGL">IGL</option>
                                                            <option value="INDHOTEL">INDHOTEL</option>
                                                            <option value="INDIACEM">INDIACEM</option>
                                                            <option value="INFY">INFY</option>
                                                            <option value="INTELLECT">INTELLECT</option>
                                                            <option value="IRCTC">IRCTC</option>
                                                            <option value="JINDALSTEL">JINDALSTEL</option>
                                                            <option value="JUBLFOOD">JUBLFOOD</option>
                                                            <option value="KOTAKBANK">KOTAKBANK</option>
                                                            <option value="LALPATHLAB">LALPATHLAB</option>
                                                            <option value="LAURUSLABS">LAURUSLABS</option>
                                                            <option value="LICHSGFIN">LICHSGFIN</option>
                                                            <option value="LTI">LTI</option>
                                                            <option value="LUPIN">LUPIN</option>
                                                            <option value="MARICO">MARICO</option>
                                                            <option value="MARUTI">MARUTI</option>
                                                            <option value="METROPOLIS">METROPOLIS</option>
                                                            <option value="MFSL">MFSL</option>
                                                            <option value="MGL">MGL</option>
                                                            <option value="MPHASIS">MPHASIS</option>
                                                            <option value="MUTHOOTFIN">MUTHOOTFIN</option>
                                                            <option value="NAM-INDIA">NAM-INDIA</option>
                                                            <option value="NATIONALUM">NATIONALUM</option>
                                                            <option value="NAVINFLUOR">NAVINFLUOR</option>
                                                            <option value="NESTLEIND">NESTLEIND</option>
                                                            <option value="NMDC">NMDC</option>
                                                            <option value="NTPC">NTPC</option>
                                                            <option value="ONGC">ONGC</option>
                                                            <option value="PAGEIND">PAGEIND</option>
                                                            <option value="PETRONET">PETRONET</option>
                                                            <option value="PFC">PFC</option>
                                                            <option value="PIDILITIND">PIDILITIND</option>
                                                            <option value="PIIND">PIIND</option>
                                                            <option value="POWERGRID">POWERGRID</option>
                                                            <option value="RAIN">RAIN</option>
                                                            <option value="RELIANCE">RELIANCE</option>
                                                            <option value="SAIL">SAIL</option>
                                                            <option value="SBICARD">SBICARD</option>
                                                            <option value="SBIN">SBIN</option>
                                                            <option value="SHREECEM">SHREECEM</option>
                                                            <option value="SUNPHARMA">SUNPHARMA</option>
                                                            <option value="SUNTV">SUNTV</option>
                                                            <option value="SYNGENE">SYNGENE</option>
                                                            <option value="TATACHEM">TATACHEM</option>
                                                            <option value="TATACOMM">TATACOMM</option>
                                                            <option value="TATACONSUM">TATACONSUM</option>
                                                            <option value="TATAPOWER">TATAPOWER</option>
                                                            <option value="TATASTEEL">TATASTEEL</option>
                                                            <option value="TECHM">TECHM</option>
                                                            <option value="TORNTPOWER">TORNTPOWER</option>
                                                            <option value="TVSMOTOR">TVSMOTOR</option>
                                                            <option value="VOLTAS">VOLTAS</option>
                                                            <option value="WIPRO">WIPRO</option>
                                                            <option value="ZEEL">ZEEL</option>
                                                            <option value="ZYDUSLIFE">ZYDUSLIFE</option>
                                                            <option value="IPCALAB">IPCALAB</option>
                                                            <option value="PEL">PEL</option>
                                                            <option value="TORNTPHARM">TORNTPHARM</option>
                                                            <option value="ABFRL">ABFRL</option>
                                                            <option value="APOLLOHOSP">APOLLOHOSP</option>
                                                            <option value="ASIANPAINT">ASIANPAINT</option>
                                                            <option value="ASTRAL">ASTRAL</option>
                                                            <option value="AUBANK">AUBANK</option>
                                                            <option value="AUROPHARMA">AUROPHARMA</option>
                                                            <option value="BAJFINANCE">BAJFINANCE</option>
                                                            <option value="BALKRISIND">BALKRISIND</option>
                                                            <option value="BATAINDIA">BATAINDIA</option>
                                                            <option value="COLPAL">COLPAL</option>
                                                            <option value="DIXON">DIXON</option>
                                                            <option value="EICHERMOT">EICHERMOT</option>
                                                            <option value="FSL">FSL</option>
                                                            <option value="GLENMARK">GLENMARK</option>
                                                            <option value="GODREJPROP">GODREJPROP</option>
                                                            <option value="HAVELLS">HAVELLS</option>
                                                            <option value="HDFCBANK">HDFCBANK</option>
                                                            <option value="IDFCFIRSTB">IDFCFIRSTB</option>
                                                            <option value="IEX">IEX</option>
                                                            <option value="INDUSINDBK">INDUSINDBK</option>
                                                            <option value="ITC">ITC</option>
                                                            <option value="JKCEMENT">JKCEMENT</option>
                                                            <option value="L&TFH">L&TFH</option>
                                                            <option value="LTTS">LTTS</option>
                                                            <option value="MCDOWELL-N">MCDOWELL-N</option>
                                                            <option value="MINDTREE">MINDTREE</option>
                                                            <option value="MOTHERSON">MOTHERSON</option>
                                                            <option value="CIPLA">CIPLA</option>
                                                            <option value="NAUKRI">NAUKRI</option>
                                                            <option value="NBCC">NBCC</option>
                                                            <option value="OBEROIRLTY">OBEROIRLTY</option>
                                                            <option value="PERSISTENT">PERSISTENT</option>
                                                            <option value="POLYCAB">POLYCAB</option>
                                                            <option value="RBLBANK">RBLBANK</option>
                                                            <option value="SBILIFE">SBILIFE</option>
                                                            <option value="SIEMENS">SIEMENS</option>
                                                            <option value="TATAMOTORS">TATAMOTORS</option>
                                                            <option value="TITAN">TITAN</option>
                                                            <option value="UBL">UBL</option>
                                                            <option value="ULTRACEMCO">ULTRACEMCO</option>
                                                            <option value="UPL">UPL</option>
                                                            <option value="WHIRLPOOL">WHIRLPOOL</option>
                                                            <option value="M&M">M&M</option>
                                                            <option value="MRF">MRF</option>
                                                            <option value="OFSS">OFSS</option>
                                                            <option value="PVR">PVR</option>
                                                            <option value="BAJAJFINSV">BAJAJFINSV</option>
                                                            <option value="BANDHANBNK">BANDHANBNK</option>
                                                            <option value="ADANIENT">ADANIENT</option>
                                                            <option value="BOSCHLTD">BOSCHLTD</option>
                                                            <option value="CHAMBLFERT">CHAMBLFERT</option>
                                                            <option value="CHOLAFIN">CHOLAFIN</option>
                                                            <option value="CUMMINSIND">CUMMINSIND</option>
                                                            <option value="HINDUNILVR">HINDUNILVR</option>
                                                            <option value="INDIAMART">INDIAMART</option>
                                                            <option value="INDIGO">INDIGO</option>
                                                            <option value="JSWSTEEL">JSWSTEEL</option>
                                                            <option value="M&MFIN">M&MFIN</option>
                                                            <option value="MANAPPURAM">MANAPPURAM</option>
                                                            <option value="INDUSTOWER">INDUSTOWER</option>
                                                            <option value="ABB">ABB</option>
                                                            <option value="PNB">PNB</option>
                                                            <option value="RAMCOCEM">RAMCOCEM</option>
                                                            <option value="RECLTD">RECLTD</option>
                                                            <option value="SRF">SRF</option>
                                                            <option value="TCS">TCS</option>
                                                            <option value="EXIDEIND">EXIDEIND</option>
                                                            <option value="TRENT">TRENT</option>
                                                            <option value="VEDL">VEDL</option>
                                                            <option value="COROMANDEL">COROMANDEL</option>
                                                            <option value="IDEA">IDEA</option>
                                                            <option value="GOLD">GOLD</option>
                                                            <option value="GOLDM">GOLDM</option>
                                                            <option value="GOLDGUINEA">GOLDGUINEA</option>
                                                            <option value="SILVER">SILVER</option>
                                                            <option value="SILVERM">SILVERM</option>
                                                            <option value="SILVERMIC">SILVERMIC</option>
                                                            <option value="COPPER">COPPER</option>
                                                            <option value="CRUDEOIL">CRUDEOIL</option>
                                                            <option value="NICKEL">NICKEL</option>
                                                            <option value="ZINC">ZINC</option>
                                                            <option value="ALUMINUM">ALUMINUM</option>
                                                            <option value="LEAD">LEAD</option>
                                                        </select>
                                                        <label htmlFor="floatingSelectGrid">Symbol</label>
                                                    </div>
                                                </Col>
                                                <Col className="mb-2" md={6}>
                                                    <div className="form-floating mb-3">
                                                        <select value={OptionBridgeDay} name="broker" className="form-select" onChange={(val) => SetOptionBridgeDay(val.target.value)}>
                                                            <option value="0">SELECT</option>
                                                            <option value="01" >
                                                                01
                                                            </option>
                                                            <option value="02">
                                                                02
                                                            </option>
                                                            <option value="03">
                                                                03
                                                            </option>
                                                            <option value="04">
                                                                04
                                                            </option>
                                                            <option value="05">
                                                                05
                                                            </option>
                                                            <option value="06">
                                                                06
                                                            </option>
                                                            <option value="07">
                                                                07
                                                            </option>
                                                            <option value="08">
                                                                08
                                                            </option>
                                                            <option value="09">
                                                                09
                                                            </option>
                                                            <option value="10">
                                                                10
                                                            </option>
                                                            <option value="11">
                                                                11
                                                            </option>
                                                            <option value="12">
                                                                12
                                                            </option>
                                                            <option value="13">
                                                                13
                                                            </option>
                                                            <option value="14">
                                                                14
                                                            </option>
                                                            <option value="15">
                                                                15
                                                            </option>
                                                            <option value="16">
                                                                16
                                                            </option>
                                                            <option value="17">
                                                                17
                                                            </option>
                                                            <option value="18">
                                                                18
                                                            </option>
                                                            <option value="19">
                                                                19
                                                            </option>
                                                            <option value="20">
                                                                20
                                                            </option>
                                                            <option value="21">
                                                                21
                                                            </option>
                                                            <option value="22">
                                                                22
                                                            </option>
                                                            <option value="23">
                                                                23
                                                            </option>
                                                            <option value="24">
                                                                24
                                                            </option>
                                                            <option value="25">
                                                                25
                                                            </option>
                                                            <option value="26">
                                                                26
                                                            </option>
                                                            <option value="27">
                                                                27
                                                            </option>
                                                            <option value="28">
                                                                28
                                                            </option>
                                                            <option value="29">
                                                                29
                                                            </option>
                                                            <option value="30">
                                                                30
                                                            </option>
                                                            <option value="31">
                                                                31
                                                            </option>
                                                        </select>
                                                        <label htmlFor="floatingSelectGrid">Date</label>
                                                    </div>
                                                </Col>
                                                <Col className="mb-2" md={3}>
                                                    <div className="form-floating mb-3">
                                                        <select value={OptionBridgeMonth} name="broker" className="form-select" onChange={(val) => SetOptionBridgeMonth(val.target.value)}>
                                                            <option value="">SELECT</option>
                                                            <option data-month-value="1" data-month-name="JAN" value="1" >
                                                                JAN
                                                            </option>
                                                            <option data-month-value="2" data-month-name="FEB" value="2">
                                                                FEB
                                                            </option>
                                                            <option data-month-value="3" data-month-name="MAR" value="3">
                                                                MAR
                                                            </option>
                                                            <option data-month-value="4" data-month-name="APR" value="4">
                                                                APR
                                                            </option>
                                                            <option data-month-value="5" data-month-name="MAY" value="5">
                                                                MAY
                                                            </option>
                                                            <option data-month-value="6" data-month-name="JUN" value="6">
                                                                JUN
                                                            </option>
                                                            <option data-month-value="7" data-month-name="JUL" value="7">
                                                                JUL
                                                            </option>
                                                            <option data-month-value="8" data-month-name="AUG" value="8">
                                                                AUG
                                                            </option>
                                                            <option data-month-value="9" data-month-name="SEP" value="9">
                                                                SEP
                                                            </option>
                                                            <option data-month-value="10" data-month-name="OCT" value="10">
                                                                OCT
                                                            </option>
                                                            <option data-month-value="11" data-month-name="NOV" value="11">
                                                                NOV
                                                            </option>
                                                            <option data-month-value="12" data-month-name="DEC" value="12">
                                                                DEC
                                                            </option>
                                                        </select>
                                                        <label htmlFor="floatingSelectGrid">Month</label>
                                                    </div>
                                                </Col>
                                                <Col className="mb-2" md={3}>
                                                    <div className="form-floating mb-3">
                                                        <select value={OptionBridgeYear} name="broker" className="form-select" onChange={(val) => SetOptionBridgeYear(val.target.value)}>
                                                            <option value="">SELECT</option>
                                                            <option value="23">
                                                                23
                                                            </option>
                                                            <option value="24">
                                                                24
                                                            </option>
                                                            <option value="25">
                                                                25
                                                            </option>
                                                            <option value="26">
                                                                26
                                                            </option>
                                                            <option value="27">
                                                                27
                                                            </option>
                                                            <option value="28">
                                                                28
                                                            </option>
                                                            <option value="29">
                                                                29
                                                            </option>
                                                            <option value="30">
                                                                30
                                                            </option>
                                                            <option value="31">
                                                                31
                                                            </option>
                                                            <option value="32">
                                                                32
                                                            </option>
                                                        </select>
                                                        <label htmlFor="floatingSelectGrid">Year</label>
                                                    </div>
                                                </Col>
                                                <Col className="mb-2" md={3}>
                                                    <div className="form-floating mb-3">
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            placeholder="Qty"
                                                            onKeyPress={(event) => {
                                                                if (!/[0-9]/.test(event.key)) {
                                                                    event.preventDefault();
                                                                }
                                                            }}
                                                            value={OptionBridgeCopyTradeQty}
                                                            onChange={(val) => {
                                                                if (val != "") {
                                                                    SetOptionBridgeCopyTradeQty(val.target.value)
                                                                }
                                                            }}
                                                            required={true}
                                                        />
                                                        <label htmlFor="floatingSelectGrid">Qty</label>
                                                    </div>
                                                </Col>
                                                <Col className="mb-2" md={3} style={{ "paddingTop": "10px" }}>
                                                    <button type="submit" className="btn btn-primary me-2">
                                                        Add Symbol
                                                    </button>
                                                </Col>
                                            </Form>
                                        </Row>

                                        {SettingsTradeSymbolsData && SettingsTradeSymbolsData.length > 0 && (
                                            <Row className="mt-4 mb-2">
                                                <Col md={12}>

                                                    <Table className="table table-striped table-bordered">
                                                        <Thead>
                                                            <Tr>
                                                                <Th data-priority="1">#</Th>
                                                                <Th data-priority="2">Symbol</Th>
                                                                <Th data-priority="3">Qty</Th>
                                                                <Th data-priority="4">Acton</Th>
                                                            </Tr>
                                                        </Thead>
                                                        <Tbody>

                                                            {SettingsTradeSymbolsData && SettingsTradeSymbolsData.map((data, key) => (

                                                                data.type == "optionbridge" && (

                                                                    <Tr key={key}>
                                                                        <Td>
                                                                            {key + 1}
                                                                        </Td>
                                                                        <Td>
                                                                            {data.symbol + " " + ((data.day == 0) ? "" : data.day) + " " + data.month_name + " " + data.year}
                                                                        </Td>
                                                                        <Td>
                                                                            {data.qty}
                                                                        </Td>
                                                                        <Td>
                                                                            <button type="button" className="btn btn-sm btn-danger"
                                                                                onClick={() => { DeleteSymbol(data.symbol, data.type) }}
                                                                            >
                                                                                <i className="bx bx-trash-alt font-size-14 align-middle"></i>
                                                                            </button>
                                                                        </Td>
                                                                    </Tr>
                                                                )
                                                            ))}
                                                        </Tbody>
                                                    </Table>

                                                </Col>
                                            </Row>
                                        )}
                                    </CardBody>
                                </Card>
                            </Col>

                        </Col>

                    </Row>

                </Container>
                {/* container-fluid */}
            </div>
        </React.Fragment>
    );

}

export default Settings
