import React, { useEffect, useState, useRef } from "react";

//redux
import { useSelector, useDispatch } from "react-redux";

import { Container, Row, Col, Card, CardBody, CardTitle, CardSubtitle } from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

// actions
import { getAuthenticationData, squareoffPositions, resetApiEvent, squareoffPositionsSuccessful, squareoffPositionsFailed } from "../../../store/actions";

import axios from "axios";

import { API_URL } from "helpers/api_helper";

import { socket_io, AddToMarketDataSocketInstruments } from "../../../common/sockets"

const Positions = props => {

  //meta title
  document.title = "Positions | Algolingo ";

  const dispatch = useDispatch();

  const { authenticationData, apiSuccess, apiError, apiEvent } = useSelector(state => ({
    authenticationData: state.Authentication.authenticationData,
    apiSuccess: state.Trading.apiSuccess,
    apiError: state.Trading.apiError,
    apiEvent: state.Trading.apiEvent,
  }));


  const [positionsData, setpositionsData] = useState(null);

  const [socketInstruments, SetsocketInstruments] = useState(null)

  const [TickData, SetTickData] = useState(null);

  function squareOffPositionCall(data) {

    var broker = authenticationData.defaultBroker;

    if (broker == "alice") {

      var exchSeg = data.Exchangeseg;
      var pCode = data.Pcode;
      var netQty = (data.Bqty > 0) ? data.Bqty : data.Sqty;
      var tockenNo = data.Token;
      var symbol = data.Tsym;

      var obj = {
        "email": authenticationData.email,
        "broker": broker,
        "credentials": {},
        "tradeProperties": {
        },
        "tradeObject": {
          "exchSeg": exchSeg,
          "pCode": pCode,
          "netQty": netQty,
          "tockenNo": tockenNo,
          "symbol": symbol
        },
        "response": {

        }
      };

      dispatch(squareoffPositions(obj));
    }
    else if (broker == "zerodha") {

      var obj = {
        "email": authenticationData.email,
        "broker": broker,
        "credentials": {},
        "tradeProperties": {
        },
        "tradeObject": {
          exchange: data.exchange,
          order_type: "MARKET",
          tradingsymbol: data.tradingsymbol,
          instrument_token: data.instrument_token,
          quantity: (data.buy_quantity > 0) ? data.buy_quantity : data.sell_quantity,
          disclosed_quantity: 0,
          price: 0,
          transaction_type: (data.buy_quantity > 0) ? "SELL" : "BUY",
          trigger_price: 0,
          validity: "DAY",
          product: data.product,
          tag: "AB-SQUARE",
          source: "web"
        },
        "response": {

        }
      };

      dispatch(squareoffPositions(obj));
    }
    else if (broker == "fyers") {

      var obj = {
        "email": authenticationData.email,
        "broker": broker,
        "credentials": {},
        "tradeProperties": {
        },
        "tradeObject": data,
        "response": {

        }
      };

      dispatch(squareoffPositions(obj));
    }
  }

  useEffect(() => {
    if (apiSuccess != null) {
      setTimeout(() => dispatch(squareoffPositionsSuccessful(null)), 2000)
    }
  }, [apiSuccess]);

  useEffect(() => {
    if (apiError != null) {
      setTimeout(() => dispatch(squareoffPositionsFailed(null)), 2000)
    }
  }, [apiError]);

  useEffect(() => {
    if (apiEvent == "RELOAD") {
      dispatch(resetApiEvent());
    }
  }, [apiEvent]);

  useEffect(() => {
    setTimeout(() => { dispatch(getAuthenticationData());getPositionsData }, 100);
  }, []);

  useEffect(() => {
    if (authenticationData) {
      setInterval(getPositionsData, 3000);
    }
  }, [authenticationData]);

  function getPositionsData() {

    if (document.title.indexOf("Positions") == -1) {
      return;
    }

    var authUser = localStorage.getItem("authUser");
    if (authUser) { authUser = atob(authUser); }
    var email = JSON.parse(authUser).email;

    var obj = {
      "email": email
    };

    //console.log("page=" + no);

    const headers = {
      'Content-Type': 'application/json'
    };

    axios.post(API_URL + '/trading/getPositions', JSON.stringify(obj), { headers }).then(response => {

      //console.log(response) 

      var output = response.data;

      if (output.result == "success") {
        setpositionsData(output.data);

        if (output.data) {
          var instruments = { "a": "subscribe", "v": [], "m": "marketdata" };
          var data = output.data;

          if (authenticationData && authenticationData.defaultBroker == "angel") {

            for (var i = 0; i < data.length; i++) {
              var exchange_code = parseInt(getExchangeCode(data[i].exchange).code);
              var instrument_token = parseInt(data[i].symboltoken);
              instruments.v.push([exchange_code, instrument_token]);
            }
            SetsocketInstruments(instruments);

          }

        }
      }
      else {
        setpositionsData(null);
      }
    });
  }

  function getExchangeCode(exchange) {
    var result = {}
    result.exchange = exchange;
    if (exchange == "NSE") {
      result.code = 1;
      result.multiplier = 100;
    }
    else if (exchange == "NFO") {
      result.code = 2;
      result.multiplier = 100;
    }
    else if (exchange == "CDS") {
      result.code = 3;
      result.multiplier = 10000000;
    }
    else if (exchange == "MCX") {
      result.code = 4;
      result.multiplier = 100;
    }
    else if (exchange == "BSE") {
      result.code = 6;
      result.multiplier = 100;
    }
    else if (exchange == "BFO") {
      result.code = 7;
      result.multiplier = 100;
    }

    return result;

  }

  useEffect(() => {

    if (socketInstruments != null) {

      if (socketInstruments.v.length > 0) {
        //console.log("socketInstruments=" + JSON.stringify(socketInstruments));
        AddToMarketDataSocketInstruments(socketInstruments.v);
      }

      socket_io.on("socket-marketdata", (result) => {

        //console.log("data=" + JSON.stringify(result));

        var tickdata_record = localStorage.getItem("positions_tickdata_record");
        if (tickdata_record) {

          tickdata_record = JSON.parse(tickdata_record);

          var index = tickdata_record.findIndex(function (e) { return e.instrumentToken == result.instrumentToken });

          //console.log("index=" + index);

          if (index == -1) {
            tickdata_record.push(result);
            tickdata_record = JSON.stringify(tickdata_record);
            localStorage.setItem("positions_tickdata_record", tickdata_record);
          }
          else {
            tickdata_record[index] = result;
            tickdata_record = JSON.stringify(tickdata_record);
            localStorage.setItem("positions_tickdata_record", tickdata_record);
          }

        }
        else {
          var input = [];
          input.push(result)
          input = JSON.stringify(input);
          localStorage.setItem("positions_tickdata_record", input);
        }

        SetTickData(result);

      });

    }

  }, [socketInstruments]);

  useEffect(() => {
    if (TickData != null) {
      var tickdata_record = localStorage.getItem("positions_tickdata_record");
      if (tickdata_record) {
        tickdata_record = JSON.parse(tickdata_record);
        for (var i = 0; i < tickdata_record.length; i++) {
          fillInstrumentsTickData(tickdata_record[i]);
        }
      }
    }
  }, [TickData]);

  function fillInstrumentsTickData(data) {

    //console.log("data.instrumentToken=" + data.instrumentToken);

    data.lastTradedPrice = data.lastTradedPrice / 100;
    data.openPrice = data.openPrice / 100;
    data.highPrice = data.highPrice / 100;
    data.lowPrice = data.lowPrice / 100;
    data.closePrice = data.closePrice / 100;

    if (positionsData) {

      if (authenticationData && authenticationData.defaultBroker == "angel") {

        const newPositionsData = [...positionsData]; // make a copy of the array

        for (var i = 0; i < newPositionsData.length; i++) {

          //console.log("newPositionsData[i].symbol_code=" + newPositionsData[i].symbol_code + ",data.instrumentToken=" + data.instrumentToken);

          if (parseInt(newPositionsData[i].symboltoken) == data.instrumentToken) {

            newPositionsData[i].pastprice = newPositionsData[i].live_price || 0;
            newPositionsData[i].live_price = data.lastTradedPrice;

            if (parseFloat(newPositionsData[i].live_price) > parseFloat(newPositionsData[i].pastprice)) {
              newPositionsData[i].trend = "UP";
            }
            else if (parseFloat(newPositionsData[i].live_price) < parseFloat(newPositionsData[i].pastprice)) {
              newPositionsData[i].trend = "DOWN";
            }

            var netqty = parseInt(newPositionsData[i].netqty);

            var buyqty = parseInt(newPositionsData[i].buyqty);

            var sellqty = parseInt(newPositionsData[i].sellqty);

            var ltp = parseFloat(data.lastTradedPrice);

            var buyavgprice = parseFloat(newPositionsData[i].buyavgprice);

            var sellavgprice = parseFloat(newPositionsData[i].sellavgprice);

            if (netqty > 0) {

              newPositionsData[i].realizedProfit = 0;

              if (buyavgprice > 0) {

                newPositionsData[i].unrealizedProfit = (ltp - buyavgprice) * netqty;

              }
              else if (sellavgprice > 0) {

                newPositionsData[i].unrealizedProfit = (sellavgprice - ltp) * netqty;

              }
            }
            else {

              newPositionsData[i].unrealizedProfit = 0;

              if (buyqty > 0) {
                newPositionsData[i].realizedProfit = (sellavgprice - buyavgprice) * buyqty;
              }
              else {
                newPositionsData[i].realizedProfit = (buyavgprice - sellavgprice) * sellqty;
              }

            }

          }
        }

        setpositionsData(newPositionsData);

      }

    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Dashboards" breadcrumbItem="Positions" />


          {apiSuccess && (
            <div
              className="alert alert-success text-center"
              role="alert">
              {apiSuccess}
            </div>
          )}

          {apiError && (
            <div
              className="alert alert-danger text-center"
              role="alert">
              {apiError}
            </div>
          )}

          <Row>
            <Col>
              <Card>
                <CardBody>

                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                    >
                      <Table
                        id="tech-companies-1"
                        className="table table-striped table-bordered"
                      >
                        <Thead>
                          <Tr>
                            <Th data-priority="1">Exchange</Th>
                            <Th data-priority="2">Type</Th>
                            <Th data-priority="3">Instrument</Th>
                            <Th data-priority="4">Product</Th>
                            <Th data-priority="5">Qty</Th>
                            <Th data-priority="6">Avg Price</Th>
                            <Th data-priority="7">Unrealised PNL</Th>
                            <Th data-priority="7">Realised PNL</Th>
                            <Th data-priority="7">Action</Th>
                          </Tr>
                        </Thead>
                        <Tbody>

                          {positionsData && authenticationData && authenticationData.defaultBroker == "alice" &&

                            positionsData.map((data, key) => (
                              //console.log(JSON.stringify(data)),
                              <Tr key={key}>
                                <Td>{data.Exchange}</Td>
                                <Td><span className={data.netbuyqty > 0 ? "text-success" : "text-danger"}>{(data.netbuyqty > 0) ? "BUY" : "SELL"}</span> </Td>
                                <Td>{data.Tsym}</Td>
                                <Td>{data.Pcode}</Td>
                                <Td>{(data.Bqty > 0) ? data.Bqty : data.Sqty}</Td>
                                <Td>{(data.Bqty > 0) ? data.Buyavgprc : data.Sellavgprc}</Td>
                                <Td><span className={parseFloat(data.unrealisedprofitloss) > 0 ? "text-success" : "text-danger"}>{data.unrealisedprofitloss}</span> </Td>
                                <Td><span className={parseFloat(data.realisedprofitloss) > 0 ? "text-success" : "text-danger"}>{data.realisedprofitloss}</span> </Td>
                                <Td>
                                  <button
                                    type="button"
                                    className="btn btn-info me-2" onClick={() => { squareOffPositionCall(data) }}
                                  >
                                    Squareoff
                                  </button>
                                </Td>
                              </Tr>
                            ))}

                          {positionsData && authenticationData && authenticationData.defaultBroker == "zerodha" &&

                            positionsData.map((data, key) => (
                              //console.log(JSON.stringify(data)),
                              <Tr key={key}>
                                <Td>{data.exchange}</Td>
                                <Td><span className={data.buy_quantity > 0 ? "text-success" : "text-danger"}>{(data.buy_quantity > 0) ? "BUY" : "SELL"}</span> </Td>
                                <Td>{data.tradingsymbol}</Td>
                                <Td>{data.product}</Td>
                                <Td>{(data.buy_quantity > 0) ? data.buy_quantity : data.sell_quantity}</Td>
                                <Td>{(data.buy_quantity > 0) ? data.buy_price : data.sell_price}</Td>
                                <Td><span className={parseFloat(data.unrealised) > 0 ? "text-success" : "text-danger"}>{data.unrealised.toFixed(2)}</span> </Td>
                                <Td><span className={parseFloat(data.realised) > 0 ? "text-success" : "text-danger"}>{data.realised.toFixed(2)}</span> </Td>
                                <Td>
                                  <button
                                    type="button"
                                    className="btn btn-info me-2" onClick={() => { squareOffPositionCall(data) }}
                                  >
                                    Squareoff
                                  </button>
                                </Td>
                              </Tr>
                            ))}

                          {positionsData && authenticationData && authenticationData.defaultBroker == "angel" &&

                            positionsData.map((data, key) => (
                              //console.log(JSON.stringify(data)),
                              <Tr key={key}>
                                <Td>{data.exchange}</Td>
                                <Td><span className={parseInt(data.buyqty) > 0 ? "text-success" : "text-danger"}>{(parseInt(data.buyqty) > 0) ? "BUY" : "SELL"}</span> </Td>
                                <Td>{data.tradingsymbol}</Td>
                                <Td>{data.producttype}</Td>
                                <Td>{(parseInt(data.buyqty) > 0) ? data.buyqty : data.sellqty}</Td>
                                <Td>{(parseInt(data.buyqty) > 0) ? data.buyavgprice : data.sellavgprice}</Td>
                                <Td><span className={(data.unrealizedProfit && parseFloat(data.unrealizedProfit) > 0) ? "text-success" : "text-danger"}>{data.unrealizedProfit ? data.unrealizedProfit.toFixed(2) : 0}</span> </Td>
                                <Td><span className={(data.realizedProfit && parseFloat(data.realizedProfit) > 0) ? "text-success" : "text-danger"}>{data.realizedProfit ? data.realizedProfit.toFixed(2) : 0}</span> </Td>
                                <Td>
                                  {/* <button
                                      type="button"
                                      className="btn btn-info me-2" onClick={() => { squareOffPositionCall(data.Exchangeseg, data.Pcode, (data.Bqty > 0) ? data.Bqty : data.Sqty, data.Token, data.Tsym) }}
                                    >
                                      Squareoff
                                    </button> */}
                                </Td>
                              </Tr>
                            ))}

                          {positionsData && authenticationData && (authenticationData.defaultBroker == "basan" || authenticationData.defaultBroker == "sas" || authenticationData.defaultBroker == "mastertrust") &&

                            positionsData.map((data, key) => (
                              //console.log(JSON.stringify(data)),
                              <Tr key={key}>
                                <Td>{data.exchange}</Td>
                                <Td><span className={parseInt(data.buy_quantity) > 0 ? "text-success" : "text-danger"}>{(parseInt(data.buy_quantity) > 0) ? "BUY" : "SELL"}</span> </Td>
                                <Td>{data.trading_symbol}</Td>
                                <Td>{data.product}</Td>
                                <Td>{(parseInt(data.buy_quantity) > 0) ? data.buy_quantity : data.sell_quantity}</Td>
                                <Td>{(parseInt(data.buy_quantity) > 0) ? data.average_buy_price : data.average_sell_price}</Td>
                                <Td>-</Td>
                                <Td>-</Td>
                                <Td>
                                  {/* <button
                                      type="button"
                                      className="btn btn-info me-2" onClick={() => { squareOffPositionCall(data.Exchangeseg, data.Pcode, (data.Bqty > 0) ? data.Bqty : data.Sqty, data.Token, data.Tsym) }}
                                    >
                                      Squareoff
                                    </button> */}
                                </Td>
                              </Tr>
                            ))}

                          {positionsData && authenticationData && authenticationData.defaultBroker == "dhan" &&

                            positionsData.map((data, key) => (
                              //console.log(JSON.stringify(data)),
                              <Tr key={key}>
                                <Td>{data.exchangeSegment}</Td>
                                <Td><span className={parseInt(data.buyQty) > 0 ? "text-success" : "text-danger"}>{(parseInt(data.buyQty) > 0) ? "BUY" : "SELL"}</span> </Td>
                                <Td>{data.tradingSymbol}</Td>
                                <Td>{data.productType}</Td>
                                <Td>{(parseInt(data.buyQty) > 0) ? data.buyQty : data.sellQty}</Td>
                                <Td>{(parseInt(data.buyQty) > 0) ? data.buyAvg : data.sellAvg}</Td>
                                <Td><span className={parseFloat(data.unrealizedProfit) > 0 ? "text-success" : "text-danger"}>{data.unrealizedProfit}</span> </Td>
                                <Td><span className={parseFloat(data.realizedProfit) > 0 ? "text-success" : "text-danger"}>{data.realizedProfit}</span> </Td>
                                <Td>
                                  {/* <button
                                      type="button"
                                      className="btn btn-info me-2" onClick={() => { squareOffPositionCall(data.Exchangeseg, data.Pcode, (data.Bqty > 0) ? data.Bqty : data.Sqty, data.Token, data.Tsym) }}
                                    >
                                      Squareoff
                                    </button> */}
                                </Td>
                              </Tr>
                            ))}

                          {positionsData && authenticationData && authenticationData.defaultBroker == "finvasia" &&

                            positionsData.map((data, key) => (
                              //console.log(JSON.stringify(data)),
                              <Tr key={key}>
                                <Td>{data.exch}</Td>
                                <Td><span className={parseInt(data.openbuyqty) > 0 ? "text-success" : "text-danger"}>{(parseInt(data.openbuyqty) > 0) ? "BUY" : "SELL"}</span> </Td>
                                <Td>{data.tsym}</Td>
                                <Td>{data.prd}</Td>
                                <Td>{(parseInt(data.openbuyqty) > 0) ? data.openbuyqty : data.opensellqty}</Td>
                                <Td>{(parseInt(data.openbuyqty) > 0) ? data.openbuyavgprc : data.opensellavgprc}</Td>
                                <Td><span className={parseFloat(data.urmtom) > 0 ? "text-success" : "text-danger"}>{data.urmtom}</span> </Td>
                                <Td><span className={parseFloat(data.rpnl) > 0 ? "text-success" : "text-danger"}>{data.rpnl}</span> </Td>
                                <Td>
                                  {/* <button
                                    type="button"
                                    className="btn btn-info me-2" onClick={() => { squareOffPositionCall(data.Exchangeseg, data.Pcode, (data.Bqty > 0) ? data.Bqty : data.Sqty, data.Token, data.Tsym) }}
                                  >
                                    Squareoff
                                  </button> */}
                                </Td>
                              </Tr>
                            ))}

                          {positionsData && authenticationData && authenticationData.defaultBroker == "fyers" &&

                            positionsData.map((data, key) => (
                              //console.log(JSON.stringify(data)),
                              <Tr key={key}>
                                <Td>{data.symbol.split(":")[0]}</Td>
                                <Td><span className={parseInt(data.buyQty) > 0 ? "text-success" : "text-danger"}>{(parseInt(data.buyQty) > 0) ? "BUY" : "SELL"}</span> </Td>
                                <Td>{data.symbol.split(":")[1]}</Td>
                                <Td>{data.productType}</Td>
                                <Td>{(parseInt(data.buyQty) > 0) ? data.buyQty : data.sellQty}</Td>
                                <Td>{(parseInt(data.buyQty) > 0) ? data.buyAvg : data.sellAvg}</Td>
                                <Td><span className={parseFloat(data.unrealized_profit) > 0 ? "text-success" : "text-danger"}>{data.unrealized_profit}</span> </Td>
                                <Td><span className={parseFloat(data.realized_profit) > 0 ? "text-success" : "text-danger"}>{data.realized_profit}</span> </Td>
                                <Td>
                                  <button
                                    type="button"
                                    className="btn btn-info me-2" onClick={() => { squareOffPositionCall(data) }}
                                  >
                                    Squareoff
                                  </button>
                                </Td>
                              </Tr>
                            ))}

                          {positionsData && authenticationData && (authenticationData.defaultBroker == "iifl" || authenticationData.defaultBroker == "agarwal" || data.credentials.brokerId == "bigul" || authenticationData.defaultBroker == "nirmal" || authenticationData.defaultBroker == "anand" || authenticationData.defaultBroker == "composite" || authenticationData.defaultBroker == "wisdom" || authenticationData.defaultBroker == "smc" || authenticationData.defaultBroker == "sscorp") &&

                            positionsData.map((data, key) => (
                              //console.log(JSON.stringify(data)),
                              <Tr key={key}>
                                <Td>{data.ExchangeSegment}</Td>
                                <Td><span className={parseInt(data.OpenBuyQuantity) > 0 ? "text-success" : "text-danger"}>{(parseInt(data.OpenBuyQuantity) > 0) ? "BUY" : "SELL"}</span> </Td>
                                <Td>{data.TradingSymbol}</Td>
                                <Td>{data.ProductType}</Td>
                                <Td>{(parseInt(data.OpenBuyQuantity) > 0) ? data.OpenBuyQuantity : data.OpenSellQuantity}</Td>
                                <Td>{(parseInt(data.OpenBuyQuantity) > 0) ? data.BuyAveragePrice : data.SellAveragePrice}</Td>
                                <Td><span className={parseFloat(data.UnrealizedMTM) > 0 ? "text-success" : "text-danger"}>{data.UnrealizedMTM}</span> </Td>
                                <Td><span className={parseFloat(data.RealizedMTM) > 0 ? "text-success" : "text-danger"}>{data.RealizedMTM}</span> </Td>
                                <Td>
                                  <button
                                    type="button"
                                    className="btn btn-info me-2"
                                  >
                                    Squareoff
                                  </button>
                                </Td>
                              </Tr>
                            ))}


                          {positionsData && authenticationData && authenticationData.defaultBroker == "goodwill" &&

                            positionsData.map((data, key) => (
                              //console.log(JSON.stringify(data)),
                              <Tr key={key}>
                                <Td>{data.exchange}</Td>
                                <Td><span className={parseInt(data.bqty) > 0 ? "text-success" : "text-danger"}>{(parseInt(data.bqty) > 0) ? "BUY" : "SELL"}</span> </Td>
                                <Td>{data.tsym}</Td>
                                <Td>{data.product}</Td>
                                <Td>{(parseInt(data.bqty) > 0) ? data.bqty : data.sqty}</Td>
                                <Td>{(parseInt(data.bqty) > 0) ? data.buyavgprc : data.sellavgprc}</Td>
                                <Td><span className={parseFloat(data.unrealisedprofitloss) > 0 ? "text-success" : "text-danger"}>{data.unrealisedprofitloss}</span> </Td>
                                <Td><span className={parseFloat(data.realisedprofitloss) > 0 ? "text-success" : "text-danger"}>{data.realisedprofitloss}</span> </Td>
                                <Td>
                                  <button
                                    type="button"
                                    className="btn btn-info me-2"
                                  >
                                    Squareoff
                                  </button>
                                </Td>
                              </Tr>
                            ))}

                          {positionsData && authenticationData && authenticationData.defaultBroker == "icici" &&

                            positionsData.map((data, key) => (
                              //console.log(JSON.stringify(data)),
                              <Tr key={key}>
                                <Td>{data.exchange_code}</Td>
                                <Td><span className={data.action.toUpperCase() == "BUY" ? "text-success" : "text-danger"}>{data.action.toUpperCase()}</span></Td>
                                <Td>{data.stock_code}</Td>
                                <Td>{data.product_type}</Td>
                                <Td>{data.quantity}</Td>
                                <Td>{data.price}</Td>
                                <Td><span>0</span> </Td>
                                <Td><span>0</span> </Td>
                                <Td>
                                  <button
                                    type="button"
                                    className="btn btn-info me-2" onClick={() => { }}
                                  >
                                    Squareoff
                                  </button>
                                </Td>
                              </Tr>
                            ))}

                          {positionsData && authenticationData && authenticationData.defaultBroker == "neo" &&

                            positionsData.map((data, key) => (
                              //console.log(JSON.stringify(data)),
                              <Tr key={key}>
                                <Td>{data.exSeg.toUpperCase()}</Td>
                                <Td><span className={data.flBuyQty != "0" ? "text-success" : "text-danger"}>{data.flBuyQty != "0" ? "BUY" : "SELL"}</span></Td>
                                <Td>{data.trdSym}</Td>
                                <Td>{data.prod}</Td>
                                <Td>{data.flBuyQty != "0" ? data.flBuyQty : data.flSellQty}</Td>
                                <Td>{data.flBuyQty != "0" ? data.buyAmt : data.sellAmt}</Td>
                                <Td><span>0</span> </Td>
                                <Td><span>0</span> </Td>
                                <Td>
                                  -
                                </Td>
                              </Tr>
                            ))}

                        </Tbody>
                      </Table>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

        </Container>
      </div>
    </React.Fragment>
  )
}

export default Positions
